import {
  IonContent,
  IonInput,
  IonButton,
  IonLabel,
  IonImg,
  IonSpinner,
} from "@ionic/react";
import React, { useState } from "react";
import { menuController } from "@ionic/core";
import loginlogo from "../images/logo-blue.png";
import "./login.css";
import { withRouter, Link } from "react-router-dom";
import { ClearData } from "../redux/actions/commonAction";
import { connect } from "react-redux";
import { verifyEmail ,loginAfterVerify} from "../redux/actions/authAction";
import { bindActionCreators } from "redux";
import queryString from "query-string";
//   import { useLocation } from 'react-router-dom'
const Login = (props) => {
  const [formData, setFormValue] = useState({ code: "", email:"" });
  const [errorData, setErrorData] = useState({ code: [] ,email:""});
  // const params = useLocation();

  React.useEffect(() => {
    const values =  queryString.parse(props.location.search)
    menuController.enable(false);
    setFormValue(values)
  }, []);



  const handleChange = (formField, event) => {
    let test = formData;
    test[formField] = event.target.value;
    setFormValue(test);
  };

  const validateForm = () => {
    let isFormValid = true;
    let testError = { code: [] };
    if (formData.code.trim().length === 0) {
      isFormValid = false;
      testError.code = ["Please enter your code!"];
    }
    setErrorData(testError);
    return isFormValid;
  };

  async function onSubmitForm() {
    if (validateForm()) {
    const resp  =  await props.verifyEmail(formData);
    if(resp &&  resp.data) {
   await props.loginAfterVerify(formData,props)
        // props.history.push("/login")
    }
    }
  }

  return (
    <IonContent>
      <div className="login">
        
        <div className="login-inner">
        <div className="login-logo">
          <IonImg src={loginlogo} />
        </div>
          <div className="login-head">
            <h2>Verify Your Email</h2>
            {props?.errorMsg ? (
              <div className="errormsg">
                <p>{props?.errorMsg?.data?.error}</p>
              </div>
            ) : props?.successMsg ? (
              <div className="successmsg">
                <p>{props.successMsg}</p>
              </div>
            ) : null}
          </div>
          <div className="login-body">
            <form>
              <div className="form-group">
                <IonLabel>Email</IonLabel>
                <IonInput
                  // type="text"
                  type="text"
                  name="email"
                  value={formData.email}
                  className="form-control"
                  onIonChange={(event) => {
                    handleChange("email", event);
                  }}
                ></IonInput>
                <p> {errorData?.email && errorData.email[0]}</p>
              </div>
              <div className="form-group">
                <IonLabel>Verification Code</IonLabel>
                <IonInput
                  // type="text"
                  type="text"
                  value={formData.code}
                  name="code"
                  className="form-control"
                  onIonChange={(event) => {
                    handleChange("code", event);
                  }}
                ></IonInput>
                <p> {errorData?.code && errorData.code[0]}</p>
              </div>

              <div className="button-login">
                <IonButton
                  className="gradient-btn-blue"
                  color="none"
                  onClick={(event) => {
                    onSubmitForm(event);
                  }}
                >
                  Verify
                  {(() => {
                    if (props.loading) {
                      return (
                        <IonSpinner
                          name="crescent"
                          className="spinner-loader"
                        />
                      );
                    }
                  })()}
                </IonButton>
              </div>

              <div className="haveaccount">
                <p>
                  Don't have an account? <Link to={"/signup"}>Signup</Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </IonContent>
  );
};

const mapStateToProps = (state) => {
  return {
    userToken: state.authReducer.userToken,
    errorMsg: state.commonReducer.errorMsg,
    errorData: state.commonReducer.errorData,
    
    successMsg: state.commonReducer.successMsg,
    loading: state.commonReducer.loading,
    
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ verifyEmail,loginAfterVerify }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
