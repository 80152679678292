export const initialState = {
    zoomPayload: {},
    playPayload:{},
    isEventAdded:false,
    upcomingList:[],
    eventList:[],
    
}


 const zoomReducer = (state = initialState, action) => {
     
    switch (action.type) {
            case "GET_ZOOM_LINK":
            return {
                ...state,
                zoomPayload: action.payload
            };
            case "GET_PLAY_LINK":
                return {
                    ...state,
                    playPayload: action.payload
                };
            case "ADDEVENT":
                return{
                    ...state,
                    isEventAdded:action.payload.data.isRegistered
                }

            case "GETEVENT":
            return{
                ...state,
                eventList:action.payload.data
            }
            case "GET_UPCOMING_EVENTS":
            return{
                ...state,
                upcomingList:action.payload.data
            }
        default:
            return state;
    }
}

export default zoomReducer;