
export const handleError = (error) => async (dispatch) => {
  // dispatch({ type: "LOADING_FAILURE" });
  if (error?.response) {
    if (error.response.status === 403) {
      localStorage.removeItem("user")
      localStorage.removeItem("userData")
      window.location.reload();
    } else if (error.response.data?.errorData) {
      dispatch({
        type: "ERROR",
        payload: { errorMsg: error.response.data },
      });
      // toast.error(error.response.data.error);
    } else {
      dispatch({
        type: "ERROR",
        payload: { errorMsg: error.response.data },
      });
      // toast.error(error.response.data.error);
    }
  } else {
    dispatch({
      type: "ERROR",
      payload: { errorMsg: error.message },
    });
    // toast.error(error.message)
  }

  setTimeout(() => {
    dispatch({
      type: "ERROR",
      payload: { errorMsg: "" },
    });
  }, 3000);
};

export const handleSuccess = (data) => async (dispatch) => {
  dispatch({
    type: "SUCCESS",
    payload: { successMsg: data?.message },
  });
  setTimeout(() => {
    dispatch({
      type: "SUCCESS",
      payload: { successMsg: "" },
    });
  }, 3000);
};

export const ClearData = () => async (dispatch) => {
  dispatch({
    type: "CLEARDATA",
    payload: {},
  });
};

export const changeMode = (data) => async (dispatch) => {
  dispatch({ type: "DARK MODE", payload: data });
};






