import {
  IonIcon,
  IonInput,
  IonButton,
  IonLabel,
  IonModal,
  IonSelect,
  IonSpinner,
  IonSelectOption,
} from "@ionic/react";
import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import { connect } from "react-redux";
import { addUserAction, getUserDetails } from "../../redux/actions/users";
import { ClearData } from "../../redux/actions/commonAction";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { close } from "ionicons/icons";
import { cleanUpData } from "../../redux/actions/prsAction";
import { useDispatch } from "react-redux";
import Teams from "../contacts/Teams";
//   import "./addUserModel.css";

const ViewReportModel = (props) => {
  const dispatch = useDispatch();
  const [reportData, setReportData] = useState([]);

  useEffect(() => {
    formatReportingData();
  }, [props.checkPlayerReportingData]);

  async function formatReportingData() {
    let id,
      answer,
      question_id,
      player_id,
      couch_question_id,
      created_ts,
      question,
      category,
      date_diff,
      player_name;
    var finalArr = await Object.values(
      props?.checkPlayerReportingData?.reduce(
        (
          result,
          {
            id,
            answer,
            question_id,
            player_id,
            couch_question_id,
            created_ts,
            question,
            category,
            date_diff,
            player_name,
          }
        ) => {
          // Create new group
          if (!result[category])
            result[category] = {
              category,
              newData: [],
            };
          // Append to group
          result[category].newData.push({
            id,
            answer,
            question_id,
            player_id,
            couch_question_id,
            created_ts,
            question,
            date_diff,
            player_name,
          });
          return result;
        },
        {}
      )
    );

    setReportData(finalArr);
  }

  return (
    <IonModal
      isOpen={props.showModal}
      cssClass="player-report-popup"
      swipeToClose={true}
      backdropDismiss={false}
      onDidDismiss={() => {
        //  props.cleanUpData();
        dispatch(cleanUpData());
        props.setShowModal(false);
      }}
      onWillPresent={() => {
        // dispatch( cleanUpData)
        //   getOption();
        //   props.ClearData();
      }}
    >
      <div className="adduser-popup">
        <div className="popup-header">
          <h5>Player Report</h5>
          <div className="errormsg">
            <p>{props?.errorMsg}</p>
          </div>
          <IonButton onClick={() => props.setShowModal(false)}>
            <IonIcon icon={close} />
          </IonButton>
        </div>

        <div className="popup-mid">
          
            {reportData?.map((data, i) => {
              return (
                <div class="popup-question">
                  <div className="popup-question-heading">
                    <h3>
                      {data.category === 1
                        ? "Wellness"
                        : data.category === 2
                        ? "Tennis"
                        : data.category === 3 ? "Goals" : data.category === 4 ? "Tennis" :data.category === 5 ?"Health":data.category === 6? "Team":null}
                    </h3>
                  </div>
                  {data.newData.map((newobj, index) => {
                    return (
                      <div className="popup-question-body">
                        <div className="popup-question-body-detail">
                          <div className="popup-question-body-qus">
                            <p>
                              <span>Q</span> {newobj.question}
                            </p>
                          </div>
                          <div className="popup-question-body-ans">
                            <p>
                              <span>A</span> {newobj.answer}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
            </div>
              );
            })}
          
        </div>
      </div>
    </IonModal>
  );
};

const mapStateToProps = (state) => {
  return {
    checkPlayerReportingData: state.prsReducer.checkPlayerReportingData,
  };
};

export default withRouter(connect(mapStateToProps)(ViewReportModel));
