import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { connect } from "react-redux";
import {
  getStripeAction,
  changeUserPaymentStatus,
  savePaymentResponse,
} from "../../redux/actions/stripeAction";
import { checkPayment } from "../../redux/actions/authAction";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import CardSection from "./cardSection";
import {
  IonButton,
  IonSpinner
} from "@ionic/react";
import axios from "axios";
import { useHistory } from "react-router-dom";


const URL = process.env.REACT_APP_API_URL;

function CheckoutForm(props) {
  const history = useHistory();

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    setIsLoading(true);
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const data = await getStripeAction(props.product.id, props?.authData?.email) ?? null;
    const clientSecret = data !== undefined && data !== null ? data.clientSecret : null;

    if (clientSecret !== undefined && clientSecret !== null) {
      const cardElement = elements.getElement(CardElement);

      await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: props.authData.fullname,
            },
          },
        })
        .then(async function (result) {
          if (result.error === undefined) {
            var intervalId = window.setInterval(async function () {
              let user;
              const userData = JSON.parse(localStorage.getItem("userData"));
              const res = await axios({
                method: "get",
                url: `${URL}/api/v1/user/${userData.id}`,
                headers: {
                  authorization: localStorage.getItem("user"),
                }
              });
              user = res.data.data.data
              if (user !== null &&
                user !== undefined &&
                user.stripe_customer_id !== null &&
                user.stripe_customer_id !== undefined &&
                user.stripe_customer_id.length > 0) {
                clearInterval(intervalId);
                setIsLoading(false);
                props.callback();
                // props.checkPayment({ isPayment: 1 });
                // const authUserData = this.props.authData;
                // await (authUserData.is_payment = 1);
                // localStorage.setItem("userData", JSON.stringify(authUserData));
                // this.props.history.push("/page/coach-dashboard");
                // history.push("/page/coach-dashboard");
                // const form = document.createElement('form');
                // form.method = "POST";
                // form.action = window.location.href;
                // document.body.appendChild(form);
                // form.submit();
                // window.location.href = window.location.href;
                // window.location.reload();
              }
            }, 2500);
          } else {
            setIsLoading(false);
            setError(result.error.message)
          }
        });
    } else {
      setIsLoading(false);
      setError('There was an error making the purchase')
    }

  };

  return (
    <>
      <div className="stripe">
        <div className="stripe-card">
          <div className="stripe-card-head">
            <h2>Card Details</h2>
            {error ? <div className="errormsg">
              <p>{error}</p>
            </div> : null}

          </div>

          <div className="stripe-card-body">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                {/* <input class="form-control" typs="text" placeholder="Username" value="kuch bhu" readOnly></input> */}
                <label>Username:</label>
                <p>{props?.authData?.fullname}</p>
              </div>
              <div className="form-group">
                <label>Email Address:</label>
                <p>{props?.authData?.email}</p>
              </div>
              <div className="form-group">
                <label>Team Name:</label>
                <p>{props?.authData?.team_name}</p>
              </div>
              <div className="form-group">
                <label>Payment Amount:</label>
                <p>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }
                ).format(props.product.unit_amount_decimal / 100)}</p>
              </div>

              <CardSection />
              <div className="stripe-card-btn">
                <IonButton
                  className="gradient-btn-blue"
                  color="none"
                  disabled={!props.stripe}
                  type="submit"
                >
                  Let’s Go!
                  {(() => {
                    if (isLoading) {
                      return (
                        <IonSpinner
                          name="crescent"
                          className="spinner-loader"
                        />
                      );
                    }
                  })()}
                </IonButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
  // }
}

const mapStateToProps = (state) => {
  return {
    clientSecret: state.stripeReducer.clientSecret,
    errorMsg: state.commonReducer.errorMsg,
    successMsg: state.commonReducer.successMsg,
    authData: state.authReducer.authData,
    loading: state.commonReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getStripeAction, checkPayment, changeUserPaymentStatus, savePaymentResponse },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CheckoutForm)
);
