import {
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonImg,
  IonInput,
  IonButton,
  IonLabel,
  IonModal,
  IonAlert,
  IonDatetime,
  IonSpinner
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import DashCalander from "../../../images/dash-calendar.svg";
import { getUserDetails } from "../../../redux/actions/users";
import "./coachDashboardList.css";
import { deleteEvent, editEvent,getUpcomingEvents } from "../../../redux/actions/zoomAction";

import { eyeOutline, trashOutline, createOutline, close } from "ionicons/icons";
import { useDispatch, connect } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router";

const CoachDashboardList = (props) => {
  const dispatch = useDispatch();
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [upcomingEventList, setEventList] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState(0);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [formData, setFormValue] = useState({
    name: "",
    description: "",
    startDate: "",
    endDate: "",
    venue: "",
  });
  const [errorData, setErrorData] = useState({
    name: [],
    description: [],
    startDate: [],
    endDate: [],
    venue: [],
  });
  useEffect(() => {
    dispatch(getUpcomingEvents());
    // dispatch(getUserDetails({limit: 5 ,offset: 0 , role: 1 , userRole : 0}));    
  }, []);

  useEffect(() => {
    setEventList(props.upcomingList);
  }, [props.upcomingList]);

  function formatTime(date, value) {
    if (value === "only_date") {
      return moment(new Date(date), "ddd DD-MMM-YYYY, hh:mm A").format(
        "DD-MMM-YYYY"
      );
    } else if (value === "only_time") {
      return moment(new Date(date), "ddd DD-MMM-YYYY, hh:mm A").format(
        "hh:mm A"
      );
    }
  }

  const formatDateTime = (time) => {
    return moment(time, "ddd DD-MMM-YYYY, hh:mm A").format(
      "DD-MMM-YYYY hh:mm A"
    );
  };

  const handleChange = (formField, event) => {
    let test = formData;
    test[formField] = event.target.value;
    setFormValue(test);
  };

  const deleteSelected= async() =>{
    await dispatch(await deleteEvent(selectedEventId));
    await dispatch( await getUpcomingEvents());
    // await props.deleteEvent(selectedEventId)
    setShowAlert(false)

    // let date= selectedEvent.start
    // await props.getEventList(new Date(date));
    setTimeout(()=>{
      dispatch({
        type: "CLEARDATA",
        payload: {},
      });
    },3000)
  }

  const validateForm = () => {
    let isFormValid = true;
    let testError = {
      name: [],
      description: [],
      startDate: [],
      endDate: [],
      venue: [],
    };
    if (formData.name.trim().length === 0) {
      isFormValid = false;
      testError.name = ["Name cannot be empty!"];
    }
    if (
      formData.name.trim().length > 0 &&
      (formData.name.trim().length < 6 || formData.name.trim().length > 50)
    ) {
      isFormValid = false;
      testError.name = ["name length must be between 6 to 50 characters"];
    }
    if (formData.description.trim().length === 0) {
      isFormValid = false;
      testError.description = ["description cannot be empty!"];
    }
    if (
      formData.description.trim().length > 0 &&
      (formData.description.trim().length < 6 ||
        formData.description.trim().length > 255)
    ) {
      isFormValid = false;
      testError.description = [
        "description length must be between 6 to 255 characters",
      ];
    }
    if (formData.startDate.trim().length === 0) {
      isFormValid = false;
      testError.startDate = ["start Date cannot be empty!"];
    }
    if (formData.endDate.trim().length === 0) {
      isFormValid = false;
      testError.endDate = ["end Date cannot be empty!"];
    }
    if (formData.venue.trim().length === 0) {
      isFormValid = false;
      testError.venue = ["Venue cannot be empty!"];
    }
    if (formData.venue.trim().length > 0 && formData.venue.trim().length > 50) {
      isFormValid = false;
      testError.venue = ["venue must be must be between 6 to 50 characters"];
    }
    let m =formData.endDate;
    let b=moment.duration(moment(m).diff(moment(formData.startDate)));
    var hours = parseInt(b.asHours());
    if(moment(formData.endDate).diff(formData.startDate,"days") < 0 ) {
      isFormValid=false
      testError.endDate=["End Date cannot be less than start date"]
    }
    if(hours <= 0 ) {
      isFormValid=false
      testError.endDate=["End Date cannot be less than start date"]
    }

    setErrorData(testError);
    return isFormValid;
  };
  const editOnClick = async () => {
    if (validateForm()) {
      // await props.editEvent(selectedEventId, formData)
      await dispatch(await editEvent(selectedEventId, formData));
      await setShowEditModal(false);
      await dispatch( await getUpcomingEvents());
      // let date= selectedEvent.start
      // await props.getEventList(new Date(date));
      setTimeout(() => {
        dispatch({
          type: "CLEARDATA",
          payload: {},
        });
      }, 3000);
    }
  };
  const onClickEditIcon = async (id) => {
    await setSelectedEventId(id);
    let test = await formData;
    await upcomingEventList
      .filter((data) => data.id === id)
      .map((filteredData) => {
        test = {
          name: filteredData?.name,
          description: filteredData?.description,
          startDate: filteredData?.startDate,
          endDate: filteredData?.endDate,
          venue: filteredData?.venue,
        };
      });
    await setFormValue(test);
    setShowEditModal(true);
  };

  async function handleOnViewEvent(id) {
    await setSelectedEventId(id);
    setShowDetailModal(true);
  }

  return (
    <IonCard class="coach-dashboard-card">
      <IonCardHeader class="dashboard-card-head">
        <h3>UPCOMING EVENTS</h3>
        <a href="javascript:void(0)" title="Upcoming Events">
          <div class="dashboard-card-icon" onClick={(e)=>{props.history.push("/page/calendar")}}>
            <div class="dashboard-card-icon-inner">
              <IonImg src={DashCalander} />
            </div>
          </div>
        </a>
      </IonCardHeader>
      <IonCardContent class="dashboard-card-body">
        <div class="dash-table">
          <div class="dashtable-responsive">
            <table class="table">
              <thead>
                <tr>
                  <td>
                    <p>Event Name</p>
                  </td>
                  <td>
                    <p>Date</p>
                  </td>
                  <td>
                    <p>Time</p>
                  </td>
                  {props.checkUserRole === 1 ? (
                    <td>
                      <p className="text-center">Action</p>
                    </td>
                  ) : null}
                </tr>
              </thead>
              
                {upcomingEventList.length > 0 ? upcomingEventList?.map((data, i) => {
                  return (
                    <tbody>
                    <tr>
                      <td>
                        <p>{data.name}</p>
                      </td>
                      <td>
                        <p>{formatTime(data?.startDate, "only_date")}</p>
                      </td>
                      <td>
                        <p>{formatTime(data?.startDate, "only_time")}</p>
                      </td>
                      {props.checkUserRole === 1 ? (
                        <td>
                          <div className="actionitem">
                            <a title="View Event" onClick={() => handleOnViewEvent(data.id)}>
                              <IonIcon icon={eyeOutline} />
                            </a>
                            <a title="Update event">
                              <IonIcon
                                onClick={() => {
                                  onClickEditIcon(data.id);
                                }}
                                icon={createOutline}
                              />
                            </a>
                            <a title="Delete event" onClick={()=>{setShowAlert(true); setSelectedEventId(data.id)}}><IonIcon icon={trashOutline} /></a>
                          </div>
                        </td>
                        
                      ) : null}
                    </tr>
                    </tbody>
                  );
                }) : <p style={{padding:10}}>{props?.loading === true ? "Loading..." : "No Upcoming Events."} </p>}
              
            </table>
          </div>
          <IonModal
            isOpen={showDetailModal}
            cssClass="coach-event-modal"
            backdropDismiss={false}
            swipeToClose={true}
            onDidDismiss={() => setShowDetailModal(false)}
          >
            <div class="change-password-popup">
              <div class="popup-header">
                <h5>Event</h5>
                <IonButton onClick={() => setShowDetailModal(false)}>
                  <IonIcon icon={close} />
                </IonButton>
              </div>
              <div className="container-spacing">
                {upcomingEventList
                  .filter((data) => data.id === selectedEventId)
                  .map((filteredData) => (
                    <div>
                      <div className="coach-event-table">
                        <div className="dashtable-responsive">
                          <table className="table">
                            <tbody>
                              <tr>
                                <th><p>Title:</p></th>
                                <td><p>{filteredData?.name}</p></td>
                              </tr>
                              <tr>
                                <th><p>Description:</p></th>
                                <td><p> {filteredData?.description}</p></td>
                              </tr>
                              <tr>
                                <th><p>Venue:</p></th>
                                <td><p>{filteredData?.venue}</p></td>
                              </tr>
                              <tr>
                                <th><p>Start Date:</p></th>
                                <td><p>{" "}
                                {formatDateTime(new Date(filteredData?.startDate))}</p></td>
                              </tr>
                              <tr>
                                <th><p>End Date:</p></th>
                                <td><p>{" "}
                                    {formatDateTime(new Date(filteredData?.endDate))}</p></td>
                              </tr>
                            </tbody>  
                          </table>  
                        </div>
                      </div>

                    </div>
                  ))}
              </div>
            </div>
          </IonModal>

          <IonModal
            isOpen={showEditModal}
            cssClass="coach-eventedit-modal"
            backdropDismiss={false}
            swipeToClose={true}
            onDidDismiss={() => setShowEditModal(false)}
          >
            <div class="change-password-popup">
              <div class="popup-header">
                <h5>Edit Event</h5>
                <div className="errormsg">
                  <p>{props?.errorMsg}</p>
                </div>
                <IonButton onClick={() => setShowEditModal(false)}>
                  <IonIcon icon={close} />
                </IonButton>
                {props?.errorMsg ? (
                  <div className="errormsg">
                    <p>{props.errorMsg}</p>
                  </div>
                ) : props?.successMsg ? (
                  <div className="successmsg">
                    <p>{props.successMsg}</p>
                  </div>
                ) : null}
              </div>
              <form>
                <div className="form-group">
                  <IonLabel>
                    Name<sup>*</sup>
                  </IonLabel>
                  <div className="password-input">
                    <IonInput
                      type="text"
                      name="name"
                      value={formData.name}
                      onIonChange={(event) => {
                        handleChange("name", event);
                      }}
                    ></IonInput>
                    <p class="errormsg">
                      {" "}
                      {errorData?.name && errorData.name[0]}
                    </p>
                  </div>
                </div>

                <div className="form-group">
                  <IonLabel>
                    Description<sup>*</sup>
                  </IonLabel>
                  <div className="password-input">
                    <IonInput
                      type="text"
                      name="description"
                      value={formData.description}
                      onIonChange={(event) => {
                        handleChange("description", event);
                      }}
                    ></IonInput>
                    {errorData.description[0] ? (
                      <p class="errormsg">{errorData.description[0]}</p>
                    ) : null}
                  </div>
                </div>

                <div className="form-group">
                  <IonLabel>
                    Venue<sup>*</sup>
                  </IonLabel>
                  <div className="password-input">
                    <IonInput
                      type="text"
                      name="venue"
                      value={formData.venue}
                      onIonChange={(event) => {
                        handleChange("venue", event);
                      }}
                    ></IonInput>
                    {errorData.venue[0] ? (
                      <p class="errormsg">{errorData.venue[0]}</p>
                    ) : null}
                  </div>
                </div>

                <div className="form-group">
                  <IonLabel>
                    Start Date<sup>*</sup>
                  </IonLabel>
                  <div className="password-input">
                    <IonDatetime
                      displayFormat="DD-MM-YYYY HH:mm"
                      pickerFormat="DD MMMM YYYY HH:mm"
                      placeholder="Select Start Date"
                      value={formData.startDate}
                      onIonChange={(event) => {
                        handleChange("startDate", event);
                      }}
                    ></IonDatetime>
                    {errorData.startDate[0] ? (
                      <p class="errormsg">{errorData.startDate[0]}</p>
                    ) : null}
                  </div>
                </div>

                <div className="form-group">
                  <IonLabel>
                    End Date<sup>*</sup>
                  </IonLabel>
                  <div className="password-input">
                    <IonDatetime
                      displayFormat="DD-MM-YYYY HH:mm"
                      pickerFormat="DD MMMM YYYY HH:mm"
                      placeholder="Select End Date"
                      value={formData.endDate}
                      onIonChange={(event) => {
                        handleChange("endDate", event);
                      }}
                    ></IonDatetime>
                    {errorData.endDate[0] ? (
                      <p class="errormsg">{errorData.endDate[0]}</p>
                    ) : null}
                  </div>
                </div>

                <div class="form-group-center">
                  <IonButton
                    onClick={(event) => {
                      editOnClick();
                    }}
                  >
                    Edit Event {(() => {
                    if (props.loading) {
                      return (
                        <IonSpinner
                          name="crescent"
                          className="spinner-loader"
                        />
                      );
                    }
                  })()}
                  </IonButton>
                </div>
              </form>
            </div>
          </IonModal>

          <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          cssClass='my-custom-class'
          header={'Alert'}
          message={'Do you really want to delete this event?'}
          buttons={[
            {
              text: 'No',
              role: 'cancel',
              cssClass: 'secondary',
              handler: blah => {
              }
            },
            {
              text: 'Yes',
              handler: () => {
                deleteSelected()
              }
            }
          ]}
        />
        </div>
      </IonCardContent>
    </IonCard>
  );
};

const mapStateToProps = (state) => {
  return {
    upcomingList: state.zoomReducer.upcomingList,
    checkUserRole: state.authReducer.checkUserRole,
    loading: state.commonReducer.loading,
    
  };
};

export default withRouter(connect(mapStateToProps)(CoachDashboardList)) ;
