import { IonIcon, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonList, IonItem, IonInput, IonButton, IonLabel, IonGrid, IonRow, IonCol, IonCard, IonCardHeader, IonCardContent, IonImg} from '@ionic/react';
import React , {useEffect} from 'react';
import {Link, withRouter} from "react-router-dom";
//import Target from "../../../images/user-list.png";
import Target from "../../../images/team.svg";
import { getPlayerListing } from "../../../redux/actions/users";
import { useDispatch, connect } from "react-redux";

const PlayerList = ( props ) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPlayerListing({ limit: 5, offset: 0, role: 3, userRole: 1 }));
  }, []);

  return (
    <IonCard class="coach-dashboard-card">
      <IonCardHeader class="dashboard-card-head">
        <h3>Athletes</h3>
        <a href="javascript:void(0)" title="PLAYER/ATHLETES LIST">
          <div class="dashboard-card-icon">
            <div class="dashboard-card-icon-inner" onClick={(e)=>{props.history.push("/page/player-listing")}}>
              <IonImg src={Target} />
            </div>
          </div>
        </a>
      </IonCardHeader>
      <IonCardContent class="dashboard-card-body">
        <div class="dash-table">
          <div class="dashtable-responsive">
            <table class="table">
              <thead>
                <tr>
                  <td><p>Player Name</p></td>
                </tr>
              </thead>
              
              { props?.playerListing?.length > 0 ? props.playerListing?.map((data, i) => {
                  return (
                    <tbody>
                    <tr>
                      <td>
                        <p>
                          <a>{data.fullname}</a>
                        </p>
                      </td>
                    </tr>
                    </tbody>
                  );
                }) : props.loading === true ? <p style={{padding: 10}}>Loading...</p>: <p style={{padding: 10}}>No Player/Athlete Added.</p> }
              
            </table>
          </div>
          {props?.playerListing && props.playerListing.length > 0 ?
        <div class="dash-table-bottom">
        <Link to="/page/player-listing">See More</Link>
        {/* <a href="#">/</a> */}
      </div>: null  
        }
          
        </div>
      </IonCardContent>
    </IonCard>
  );
};

const mapStateToProps = (state) => {
  return {
    playerListing: state.userReducer.playerListing,
    loading: state.commonReducer.loading,
  };
};

export default withRouter(connect(mapStateToProps)(PlayerList)) ;

// export default PlayerList;
