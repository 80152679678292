export const initialState = {
  isQuestionnaire: true,
  darkMode: false,
  playersQuestions: [],
  coachQuestions: [],
  isAnswerSubmitted: false,
  playersAnswersAfterSubmit:[],
  checkPlayerReportingData:[],
  getAllWeeklyReport:[],
  getAllMonthlyReport:[],
  currentGoals:[],
  notificationData:null
};

const prsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "Questionnaire":
      return {
        ...state,
        Questionnaire: action.payload?.data,
      };
    case "Questionnaire_DaysLeft":
      return {
        ...state,
        isQuestionnaire: action.payload?.results,
        coachQuestions: action.payload?.data,
      };
    case "PLAYERS_QUESTIONS":
      return {
        ...state,
        playersQuestions: action.payload?.data,
      };

    case "PLAYERS_ANSWERS_AFTER_SUBMIT":
      return {
        ...state,
        playersAnswersAfterSubmit: action.payload?.data,
        isAnswerSubmitted: action.payload?.results
      };
      case "CHECK_PLAYER_REPORTS":
        return {
          ...state,
          checkPlayerReportingData: action.payload?.data
        };
        case "GET_ALL_WEEKLY_REPORT":
        return {
          ...state,
          getAllWeeklyReport: [...state.getAllWeeklyReport, ...action.payload?.data] 
          // getAllPlayerReport: action.payload?.data
        };
        case "GET_ALL_MONTHLY_REPORT":
          return {
            ...state,
            getAllMonthlyReport: [...state.getAllMonthlyReport, ...action.payload?.data] 
            // getAllPlayerReport: action.payload?.data
          };
        case "GET_PLAYERS_CURRENT_GOALS":
          return {
            ...state,
            currentGoals: action.payload?.data
          };
        
    // case "PLAYERS_ANS_STATUS":
    //   return {
    //     ...state,
    //     isAnswerSubmitted: action.payload?.data,
    //   };
    case "Clear_Reporting_System":
      return {
        ...state,
        playersQuestions: [],
        Questionnaire: null,
        isQuestionnaire: null,
        playersAnswersAfterSubmit: [],
        getAllWeeklyReport:[],
  getAllMonthlyReport:[],
      };

      case "SET_NOTIFICATION":
      return {
        ...state,
        notificationData:action.payload.data
      };

    default:
      return state;
  }
};

export default prsReducer;
