import React, { useState, useEffect, useRef } from "react";
import {
  IonIcon,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonImg,
  IonModal,
  IonButton,
  useIonViewWillEnter,
  HTMLIonInfiniteScrollElement,
} from "@ionic/react";
import ViewReportModel from "./viewReportModel";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import {
  checkPlayerReporting,
  cleanUpData,
  clearReportingSystem,
  getAllMatchUsers,
} from "../../redux/actions/prsAction";

import { bindActionCreators } from "redux";
import { IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/react";
import {
  eyeOutline,
  trashOutline,
  eyeOffOutline,
  close,
  contractOutline,
} from "ionicons/icons";
import "./playerReportingSystem.css";

const WeeklyReport = (props) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [showVerifyModel, setShowVerifyModel] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [userId, setUserId] = useState(0);
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false);
  const limit = 10;
  const [limitOffset, setLimitOffset] = useState({
    limit,
    offset: 0,
    role: 2,
  });

  const ionInfiniteScrollRef = useRef();

  useEffect(() => {
    // fetchData();
    return () => {
      props.clearReportingSystem();
    };
  }, []);

  useEffect(() => {
    props.clearReportingSystem();
    fetchData();

  }, []);

  // useIonViewWillEnter(async () => {
  //   await fetchData();
  // },[]);

  async function getCurrentDate(separator = "-") {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${year}${separator}${
      month < 10 ? `0${month}` : `${month}`
    }${separator}${date < 10 ? `0${date}` : `${date}`}`;
  }

  async function fetchData() {
    
    
    const todaysDate = await getCurrentDate();
    props.getAllMatchUsers(todaysDate, "match_report", limitOffset);
    setLimitOffset({
      limit,
      offset: limitOffset.offset + limit,
    });

    //setDisableInfiniteScroll(false);
    if (props?.getAllMonthlyReport?.length > 0) {
      if (props.getAllMonthlyReport.length >= limit) {
        setDisableInfiniteScroll(false);
      } else {
        setDisableInfiniteScroll(true);
      }
    } else {
      setDisableInfiniteScroll(false);
    }
  }

  const loadMoreItems = async ($event) => {
    await fetchData();
    ionInfiniteScrollRef.current.disabled = true;
  };
  async function onClickView(player_id) {
    setShowModal(true);
    const todaysDate = await getCurrentDate();
    await props.checkPlayerReporting(todaysDate, "match_report", player_id);
  }

  return (<div>
      <div class="user-inner-body">
        <IonCard class="user-dashboard-card">
          <IonCardHeader class="user-card-head">
            <h3>Match Report</h3>
          </IonCardHeader>

          <IonCardContent class="dashboard-card-body">
            <div class="dash-table">
              <div class="dashtable-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <td>
                        <p>Name</p>
                      </td>
                      <td>
                        <p>Status</p>
                      </td>
                      <td>
                        <p className="text-center">Action</p>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {props?.getAllMonthlyReport?.length > 0 ? (
                      props.getAllMonthlyReport?.map((data, i) => {
                        return (
                          <tr>
                            <td>
                              <p>{data.email}</p>
                            </td>
                            <td>
                              <p>
                                {data.report_status === 1
                                  ? "Submitted"
                                  : "Not Submitted"}
                              </p>
                            </td>
                            <td>
                              <p className="actionitem">
                                {data.report_status === 1 ? (
                                  <a onClick={() => onClickView(data.id)}>
                                    <IonIcon icon={eyeOutline} />
                                  </a>
                                ) : (
                                  <a>
                                    <IonIcon icon={eyeOffOutline} />
                                  </a>
                                )}
                              </p>
                            </td>
                          </tr>
                        );
                      })
                    ) : props.loading === true ? null : (
                      <p style={{padding:10}}>No Match Reports submitted!</p>
                    )}
                    {props.loading === true ? <p style={{padding:10}}>Loading...</p> : null}
                    <IonInfiniteScroll
                      ref={ionInfiniteScrollRef}
                      threshold="25%"
                      position="bottom"
                      disabled={disableInfiniteScroll}
                      onIonInfinite={loadMoreItems}
                    >
                      <IonInfiniteScrollContent
                        loadingSpinner="bubbles"
                        loadingText="Loading data..."
                      ></IonInfiniteScrollContent>
                    </IonInfiniteScroll>
                  </tbody>
                </table>
              </div>
            </div>
          </IonCardContent>
        </IonCard>
        <ViewReportModel showModal={showModal} setShowModal={setShowModal} ></ViewReportModel>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    getAllMonthlyReport: state.prsReducer.getAllMonthlyReport,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { checkPlayerReporting, cleanUpData, getAllMatchUsers ,clearReportingSystem},
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WeeklyReport);
