import React from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonImg,
  IonTextarea,
  IonItem,
  IonSelect,
  options,
  IonSelectOption
} from '@ionic/react';
import QuestionWhite from "../../images/question-white.png";
import QuestionBlue from "../../images/question-blue.png";
import { IonReactRouter } from '@ionic/react-router';
import { apps, flash, send } from 'ionicons/icons';

const Questionairre = (props) => {
  React.useEffect(()=>{
})
  return (
    <div className="reporting-mid-tab">
      <IonGrid>
        <IonRow>
          <IonCol size="12" size-md="3">
            <div className="reporting-mid-left">
              <a className="nav-link active" href="javascript:;">
                <IonImg className="question-icona" src={QuestionBlue}/>
                <IonImg className="question-iconb" src={QuestionWhite}/>
                Wellness</a>
              <a class="nav-link" href="javascript:;">
                <IonImg className="question-icona" src={QuestionBlue}/>
                <IonImg className="question-iconb" src={QuestionWhite}/>
                Tennis</a>
                <a class="nav-link" href="javascript:;">
                <IonImg className="question-icona" src={QuestionBlue}/>
                <IonImg className="question-iconb" src={QuestionWhite}/>
                Goals</a>
            </div>
          </IonCol>
          <IonCol size="12" size-md="9">
          <div className="reporting-mid-right">
            <div className="tab-content">
              <div className="tab-pane active" id="wellness">
                
                <div className="reporting-tabbox">
                  <div className="reporting-tabbox-head">
                    <h3>Pick your prefered questions</h3>
                    <p>(Pick your preferred questions)</p>
                  </div>
                  <div className="reporting-tabbox-mid">

                  </div>
                </div>

              </div>
            </div>
          </div>
          </IonCol>
        </IonRow>
      </IonGrid>
      
    </div>
  );
};

export default withRouter(Questionairre);
