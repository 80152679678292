import {
    IonAlert,
    IonIcon,
    IonButton,
  } from "@ionic/react";
  import React,{useState} from "react";
  import { menuController } from "@ionic/core";
  import "./login.css";
  import { withRouter } from "react-router-dom";
  import { connect } from "react-redux";
  import { facebookLogin } from "../redux/actions/authAction";
  import { bindActionCreators } from "redux";
  import { logoFacebook } from "ionicons/icons";
  const FacebookApi = process.env.REACT_APP_FACEBOOK_APP_ID;
  
  const FacebookLogin = (props) => {
    const [showAlert,setShowAlert]= useState(false)
    React.useEffect(() => {
      menuController.enable(false);
     setTimeout(()=>{
        loadFbLoginApi()
     },1000)
      
    },[]);
  
    async function loadFbLoginApi() {

        window.fbAsyncInit = function() {
            window.FB.init({
                appId      : FacebookApi,
                cookie     : true,  // enable cookies to allow the server to access
                // the session
                xfbml      : true,  // parse social plugins on this page
                version    : 'v2.5' // use version 2.1
            });
            window.FB.AppEvents.logPageView(); 
        };
    
          // Load the SDK asynchronously
        (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "//connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }
    function testAPI() {
        window.FB.api('/me', 'get', {fields:'id,email,first_name'}, function(response) {
        if(response){
          if(response.error){

          }
          else if(response.email){
            let body= {fullName: response.first_name, email: response?.email,role:1, timeZone:Intl.DateTimeFormat().resolvedOptions().timeZone}
          props.facebookLogin(body)
          }else{
            setShowAlert(true)
          }
          
        
        }else{
          
        }
        
        });
      }
  
      function  statusChangeCallback(response) {
        if (response.status === 'connected') {
          testAPI();
        } else if (response.status === 'not_authorized') {
        } else {
        }
      }
  
      function checkLoginState() {
        window.FB.getLoginStatus(function(response) {
          statusChangeCallback(response);
        }.bind(this));
      }
  
      function handleFBLogin() {
          window.FB.login(()=>{
            window.FB.getLoginStatus(function(response) {
              statusChangeCallback(response);
            }.bind(this));
          }, {
            scope: 'email', 
            return_scopes: true
        });
          }
    return (
             <div>
                <div className="icon-btn-facebook">
                  <IonButton
                    color="none"
                    type="button"
                    onClick={(event)=>{handleFBLogin()}}
                  >
                     <IonIcon icon={logoFacebook} />
                  </IonButton>
                </div>
                <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          cssClass='my-custom-class'
          header={'Login Alert'}
          message={'We are unable to fetch your details from facebook due to privacy settings of your account. Kindly use Signup feature of Sportal!'}
          buttons={[
            {
              text: 'Ok',
              handler: () => {
              }
            }
          ]}
        />
                </div>
    );
  };
  
  const mapStateToProps = (state) => {
    return {
      loading:state.commonReducer.loading
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ facebookLogin }, dispatch);
  };
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FacebookLogin));
  