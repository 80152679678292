import {
  IonInput,
  IonButton,
  IonIcon,
  IonImg,
  IonSpinner,
  IonRefresherContent,
  IonModal,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLabel,
} from "@ionic/react";
import React, { useEffect, useState, useRef } from "react";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PubNub from "pubnub";
import useInput from "../../../Utils/useInput";
import Picker from "emoji-picker-react";
import * as Pick from "react-giphy-component";
import { useDispatch } from "react-redux";
import { FileTransfer } from "@ionic-native/file-transfer";
import { File } from "@ionic-native/file";
import { getPlatforms } from "@ionic/react";
import { FileOpener } from "@ionic-native/file-opener";
import { saveLastSeen } from "../../../redux/actions/chatAction";
import {
  sendUserNotification,
  deleteChatGroup,
  setGroupArray,
  getGroupmembers,
} from "../../../redux/actions/chatAction";
import moment from "moment";
import {
  informationOutline,
  ellipsisHorizontalOutline,
  happyOutline,
  attachOutline,
  sendOutline,
  documentOutline,
  close,
  downloadOutline,
  trashOutline,
} from "ionicons/icons";
import Client from "../../../images/client-dummy.png";
import ContactModal from "../contactModal";
import { Plugins, KeyboardInfo } from '@capacitor/core';

const { Keyboard } = Plugins;

const ChatConversation = (props) => {
  const dispatch = useDispatch();
  const ionInfiniteScrollRef = useRef();
  const [channel, setChannel] = useState("");
  const [showKeyBoard, setShowKeyboard]= useState(false)
  const [messages, setMessages] = useState([]);
  const [username, setUser] = useState(props.authData?.email);
  const [startTime, setStartTime] = useState(null);
  const tempMessage = useInput();
  const [showModal, setShowModal] = useState(false);
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [groupModalData, setGroupModalData] = useState([]);
  const [setGif, openGif] = useState(false);
  const [setEmoji, openEmoji] = useState(false);
  const [loader, showLoader] = useState(false);
  const [messageLoadingStarted, setMessageLoading] = useState(false);
  const [showGif, setShowGif] = useState(false);
  const [selectedGif, setSelectedGif] = useState("");
  const [showContact, setShowContact] = useState(false);
  const [isErr,ShowErr]= useState(false)
  const [msgError,setMsgError]= useState("You are not allowed to send message in this group")
  const successCallback = handleClickOutside.bind(this);
  const gifRef = useRef();
  const emojiRef = useRef();
  const messagesEndRef = useRef(null);
  let cleartime;
  let showData = 0;
  let allowScroll = true;
  let clearLoadMore;
  let webb = getPlatforms().some(
    (r) => ["desktop", "mobileweb"].indexOf(r) >= 0
  );
  const onEmojiClick = (event, emojiObject) => {
    tempMessage.onEmoji(emojiObject.emoji);
  };
  const pubnub_config = {
    publishKey: "pub-c-bcbfc2d8-736e-4a83-9ef1-daf51ae2203e",
    subscribeKey: "sub-c-758c3636-4438-11eb-9ec5-221b84410db5",
    logVerbosity: true,
    ssl: true,
    uuid: username,
    authKey: username,
  };
  const input = document.getElementById('chatMessage');
  if(webb === false){
    Keyboard.addListener('keyboardWillShow', (info) => {
      console.log('keyboard will show', info.keyboardHeight);
      setShowKeyboard(true)
      // input.style.setProperty(
      //   'transform',
      //   `translate3d(0, ${info.keyboardHeight}px, 0)`
      // );
    });

    Keyboard.addListener('keyboardWillHide', (info) => {
      console.log('keyboard hide', info.keyboardHeight);
      setShowKeyboard(false)
      // input.style.setProperty(
      //   'transform',
      //   `translate3d(0, ${info.keyboardHeight}px, 0)`
      // );
    });
  }
 

  useEffect(() => {
    const pubnub = new PubNub(pubnub_config);
    
    document.addEventListener("mousedown", successCallback);

    return function cleanup() {
      dispatch({ type: "CHANGE_CHAT_ROOM", payload: null });
      pubnub.unsubscribeAll();
      setMessages([]);
      setChannel("");
      document.addEventListener("mousedown", successCallback);
    };
  }, []);

  const scrollItem = () => {
    var myDiv = document.getElementById("hey1");
    if (myDiv) {
      if (cleartime) {
        clearTimeout(cleartime);
      }
      if (clearLoadMore) {
        clearTimeout(clearLoadMore);
      }

      myDiv.scrollTop = myDiv.scrollHeight + myDiv.scrollHeight * 0.8;
      allowScroll = false;
      cleartime = setTimeout(() => {
        if (clearLoadMore) {
          clearTimeout(clearLoadMore);
        }
        myDiv.scrollTop = myDiv.scrollHeight + myDiv.scrollTop;
        allowScroll = true;
      }, 1000);
    }
  };

  const scrollAfterLoadMore = () => {
    var myDiv = document.getElementById("hey1");
    if (myDiv) {
      myDiv.scrollTop = myDiv.clientHeight * 0.8;
    }
    allowScroll = true;
  };

  useEffect(() => {
    if (
      props.allGroupMembers.length > 0 &&
      props.currentChatRoom.type === "group"
    ) {
      setGroupModalData(props.allGroupMembers);
      makeArray(messages).then((data) => setMessages(data));
    }
  }, [props.allGroupMembers]);

  const makeArray = async (msgs) => {
    let dummyArray = [];
    let userArray = props.allGroupMembers;
    if (userArray.length > 0 && props.currentChatRoom.type === "group") {
      for (let item of msgs) {
        let result = await userArray.find((obj) => {
          return obj.user === item.uuid;
        });
        let obj = item;
        obj["userName"] = result?.fullname;
        obj["image"] = result?.imagename;
        dummyArray.push(obj);
      }
      return dummyArray;
    } else {
      return msgs;
    }
  };
  useEffect(() => {
    let user = localStorage.getItem("userData");
    user = JSON.parse(user);
    setUser(user.email);
    let listener;
    tempMessage.setValue("");
    const pubnub = new PubNub(pubnub_config);

    if (props.currentChatRoom.channel) {
      // setUser(props.authData.email);
      setChannel(props.currentChatRoom.channel);
      listener = {
        status: function (statusEvent) {
          if (statusEvent.category === "PNConnectedCategory") {
          }
        },
        message: function (msg) {
          if (msg.message.text) {
            let newMessages = [];
            if (props.currentChatRoom.channel === msg.channel) {
              newMessages.push({
                uuid: msg.message.uuid,
                text: msg.message.text,
                type: msg.message.type
                  ? msg.message.type
                  : msg.message.file
                  ? "file"
                  : null,
                file: msg.message.file ? msg.message.file.name : null,
                time: msg.timetoken,
                gifText:
                  msg.message.type === "gif" ? msg.message?.gifMsg : null,
              });
              makeArray(newMessages).then((data) => {
                setMessages((messages) => messages.concat(data));
                scrollItem();
              });
            }
          }
        },
        file: function (msg) {
          showLoader(false);
          let newMessages = [];
          if (props.currentChatRoom.channel === msg.channel) {
            newMessages.push({
              uuid: msg.userMetadata.uuid,
              text: msg.file.name,
              type: "file",
              file: msg.file,
              time: msg.timetoken,
            });
            makeArray(newMessages).then((data) => {
              setMessages((messages) => messages.concat(data));
              scrollItem();
            });
          }
        },
      };
      pubnub.addListener(listener);
      //Subscribes to the channel in our state
      pubnub.subscribe({
        channels: [props.currentChatRoom.channel],
      });
      props.getAll();
      setMessageLoading(true);
      pubnub.history(
        {
          channel: props.currentChatRoom.channel,
          count: 10, // 100 is the default
          stringifiedTimeToken: true,
          includeMeta: true, // false is the default
        },
        function (status, response) {
          setMessageLoading(false);
          if (status.error === false) {
            setStartTime(response);
            let newMessages = [];
            for (let i = 0; i < response?.messages?.length; i++) {
              newMessages.push({
                uuid: response.messages[i].entry.uuid
                  ? response.messages[i].entry.uuid
                  : response.messages[i].meta.uuid
                  ? response.messages[i].meta.uuid
                  : null,
                text: response.messages[i].entry.text,
                type: response.messages[i].entry.type
                  ? response.messages[i].entry.type
                  : response.messages[i].entry.file
                  ? "file"
                  : null,
                file: response.messages[i].entry.file
                  ? response.messages[i].entry.file
                  : null,
                time: response.messages[i].timetoken,
                gifText:
                  response.messages[i].entry.type === "gif"
                    ? response.messages[i].entry?.gifMsg
                    : null,
              });
            }
            makeArray(newMessages).then((data) => {
              setMessages((messages) => messages.concat(data));
              scrollItem();
            });
            // setMessages((messages) => messages.concat(newMessages));
          }
        }
      );
    }
    return function cleanup() {
      pubnub.removeListener(listener);
      saveChannelLastSeen();
      setMessages([]);
      setChannel("");
    };
  }, [props.currentChatRoom]);

  const saveChannelLastSeen = async () => {
    let obj = {
      type: props.currentChatRoom.type,
      channel: props.currentChatRoom.channel,
      time: new Date(),
    };
    await props.saveLastSeen(obj, username);
  };

  function handleKeyDown(event) {
    if (event.key === "Enter") {
      publishMessage();
    }
  }

  function handleClickOutside(event) {
    if (gifRef && gifRef.current && !gifRef.current.contains(event.target)) {
      openGif(false);
    }
    if (
      emojiRef &&
      emojiRef.current &&
      !emojiRef.current.contains(event.target)
    ) {
      openEmoji(false);
    }
  }

  // load more data on scroll
  const loadMore = (event) => {
    const pubnub = new PubNub(pubnub_config);
    pubnub.history(
      {
        channel: props.currentChatRoom.channel,
        count: 5, // 100 is the default
        start: startTime.startTimeToken,
        stringifiedTimeToken: true,
        includeMeta: true, // false is the default
      },
      function (status, response) {
        if (status.error === false) {
          if (response?.messages?.length > 0) {
            setStartTime(response);
            let newMessages = [];
            for (let i = 0; i < response?.messages?.length; i++) {
              newMessages.push({
                uuid: response.messages[i]?.entry?.uuid
                  ? response.messages[i].entry.uuid
                  : response.messages[i].meta?.uuid
                  ? response.messages[i].meta.uuid
                  : null,
                text: response.messages[i].entry.text,
                type: response.messages[i].entry.type
                  ? response.messages[i].entry.type
                  : response.messages[i].entry.file
                  ? "file"
                  : null,
                file: response.messages[i].entry.file
                  ? response.messages[i].entry.file
                  : null,
                time: response.messages[i].timetoken,
                gifText:
                  response.messages[i].entry.type === "gif"
                    ? response.messages[i].entry?.gifMsg
                    : null,
              });
            }
            let newMessagesArray = messages;

            makeArray(newMessages).then((data) => {
              // setMessages(data);
              setMessages((messages) => data.concat(newMessagesArray));
            });

            scrollAfterLoadMore();
            ionInfiniteScrollRef.current.disabled = true;
          } else {
            allowScroll = false;
            ionInfiniteScrollRef.current.disabled = true;
          }

          // event.target.complete();
          // setDisableInfiniteScroll(false)
          // setMessages((messages) => newMessages.concat(messages));
        }
        // event.detail.complete();
      }
    );

    // ionInfiniteScrollRef.current.disabled = false;
  };

  const convertTime = (timestamp) => {
    let unixTimeStamp = timestamp / 10000000;
    let message = new Date(Math.ceil(unixTimeStamp * 1000));
    let finalDate;
    let today = new Date();
    let diff = moment(today, "DD.MM.YYYY")
      .startOf("day")
      .diff(moment(message, "DD.MM.YYYY").startOf("day"), "days");
    if (diff === 0) {
      finalDate = moment(message).format("hh:mm A");
    } else if (diff <= 7) {
      finalDate = moment(message).format("dddd");
    } else {
      finalDate = moment(message).format("dd/MM/YYYY");
    }
    return finalDate;
  };

  //Publishing messages via PubNub
  function publishMessage() {
    if (tempMessage.value.trim() !== "") {
      let messageObject = {
        text: tempMessage.value,
        uuid: username,
        type: "msg",
      };

      const pubnub = new PubNub(pubnub_config);
      pubnub
        .publish({
          message: messageObject,
          channel: props.currentChatRoom.channel,
        })
        .then(async (status,data) => {
          // ShowErr(true)
          await scrollItem();
          const typeId = props.currentChatRoom.image.type === "private" ? 1 : 2;
          let body = {
            typeId,
            userEmail:
              typeId === 1 ? props.currentChatRoom.email : props.authData.email,
            msg: tempMessage.value,
            groupId: typeId === 2 ? props.currentChatRoom.groupId : null,
            itemData: props.currentChatRoom,
          };
          if (typeId === 1) {
            body.name = props.authData.fullname;
          }
          await props.sendUserNotification(body);
        },(err)=>{
          ShowErr(true)
          if(err?.category === "PNAccessDeniedCategory"){
            ShowErr(true)
            // setTimeout(() => {
            //   ShowErr(false)
            // }, 3000);
          }
        })
      tempMessage.setValue("");
    }
  }
  const closeModal = () => {
    setShowContact(false);
    props.getAll();
    props.getGroupmembers(props.currentChatRoom.groupId);
  };

  function sendGif(gif) {
    if (gif?.original?.url) {
      setSelectedGif(gif.original.url);
      setShowGif(true);
    }
  }

  const sendGifMessage = () => {
    let messageObjects = {
      text: selectedGif,
      uuid: username,
      type: "gif",
      gifMsg: tempMessage.value,
    };

    const pubnub = new PubNub(pubnub_config);
    pubnub
      .publish({
        message: messageObjects,
        channel: props.currentChatRoom.channel,
      })
      .then((data) => {
        scrollItem();
        openGif(false);
        setShowGif(false);
        setSelectedGif("");
      },(err)=>{
        if(err?.category === "PNAccessDeniedCategory"){
          ShowErr(true)
          // setTimeout(() => {
          //   ShowErr(false)
          // }, 3000);
        }
      });
    tempMessage.setValue("");
  };

  const sendFile = async (event) => {
    // alert("file");
    // const file = event.detail.value;
    const pubnub = new PubNub(pubnub_config);
    const file = event.target.files[0];
    if (file) {
      showLoader(true);
      const result = await pubnub.sendFile(
        {
          channel: props.currentChatRoom.channel,
          file: file,
          meta: { uuid: username, type: "file" },
        },
        (result, status) => {
        }
      );
      // const result1 = await pubnub.publishFile({
      //   channel: props.currentChatRoom.channel,
      //   fileId: result.id,
      //   fileName: 'cat_picture.jpg',
      //   // message: { field: 'value' },
      // });
      // console.log(result1,"publish")
      if (result.id) {
        // setTimeout(()=>{
        //   pubnub.history(
        //     {
        //       channel: props.currentChatRoom.channel,
        //       count: 5, // 100 is the default
        //       stringifiedTimeToken: true,
        //       includeMeta: true, // false is the default
        //     },
        //     function (status, response) {
        //       if (status.error === false) {
        //         showLoader(false)
        //         let newMessages = [];
        //         for (let i = 0; i < response?.messages?.length; i++) {
        //           newMessages.push({
        //             uuid: response.messages[i].entry.uuid
        //               ? response.messages[i].entry.uuid
        //               : response.messages[i].meta.uuid
        //               ? response.messages[i].meta.uuid
        //               : null,
        //             text: response.messages[i].entry.text,
        //             type: response.messages[i].entry.type
        //               ? response.messages[i].entry.type
        //               : response.messages[i].entry.file
        //               ? "file"
        //               : null,
        //             file: response.messages[i].entry.file
        //               ? response.messages[i].entry.file
        //               : null,
        //             time: response.messages[i].timetoken,
        //           });
        //         }
        //         makeArray(newMessages).then((data) => {
        //           setMessages(data);
        //           // props.getAll();
        //           scrollItem()
        //         });
        //         // setMessages(newMessages);
        //       }
        //     }
        //   );
        // },1000)
      }
    }
  };

  const downloadFile = async (fileData) => {
    const pubnub = new PubNub(pubnub_config);
    const file = await pubnub.downloadFile({
      channel: channel,
      id: fileData.id,
      name: fileData.name,
    });
    let fileContent = await file.toBlob();
    var reader = new FileReader();
    reader.readAsDataURL(fileContent);
    let blob;
    reader.onload = (event) => {
      blob = event.currentTarget.result;
    };

    let webb = getPlatforms().some(
      (r) => ["desktop", "mobileweb"].indexOf(r) >= 0
    );
    if (!webb) {
      const fileTransfer = FileTransfer.create();
      fileTransfer.download(blob, File.dataDirectory + fileData.name).then(
        (entry) => {
          FileOpener.showOpenWithDialog(entry.toURL(), "application/pdf")
            .then(() => console.log("File is opened"))
            .catch((e) => console.log("Error opening file", e));
        },
        (error) => {
          // handle error
        }
      );
    } else {
      const url = window.URL.createObjectURL(new Blob([fileContent]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileData.name); //or any other extension
      document.body.appendChild(link);
      link.click();
    }
  };

  const deleteMsg = async (time, index, item) => {
    const pubnub = new PubNub(pubnub_config);
    if (item.type !== "file") {
      pubnub.deleteMessages(
        {
          channel: channel,
          start: time - 1,
          end: time,
        },
        (result) => {
          if (result.error === false) {
            messages.splice(index, 1);
            setMessages([...messages]);
          }
        }
      );
    } else {
      const result = await pubnub.deleteFile({
        channel: channel,
        id: item.file.id,
        name: item.file.name,
      });
      if (result.status === 200) {
        pubnub.deleteMessages(
          {
            channel: channel,
            start: time - 1,
            end: time,
          },
          (result) => {
            if (result.error === false) {
              messages.splice(index, 1);
              setMessages([...messages]);
            }
          }
        );
      }
    }
  };
  const setValue = (timestamp) => {
    let unixTimeStamp = timestamp / 10000000;
    let message = new Date(Math.ceil(unixTimeStamp * 1000));
    let today = new Date();
    let diff = moment(today, "DD.MM.YYYY")
      .startOf("day")
      .diff(moment(message, "DD.MM.YYYY").startOf("day"), "days");
    if (diff === 0) {
      if (showData === 1) {
        showData = 2;
      }
      if (showData === 0) {
        showData = 1;
      }
    }
  };
  const showUser = (item) => {
    if (item.type === "private") {
      setShowModal(true);
      setModalData(props.currentChatRoom);
    } else {
      setShowGroupModal(true);
      // setGroupModalData(props.allGroupMembers);
    }
  };

  const changeModalData = (data) => {
    let obj = {
      receiver: data.fullname,
      image: { path: data.imagename },
      email: data.user,
    };
    setModalData(obj);
    setShowGroupModal(false);
    setShowModal(true);
  };

  const handleScroll = (e) => {
    let element = e.target;
    if (clearLoadMore) {
      clearTimeout(clearLoadMore);
    }
    if (
      element.scrollTop <=
        (2 * (element.clientHeight + element.scrollHeight)) / 100 &&
      allowScroll
    ) {
      // do something at end of scroll

      clearLoadMore = setTimeout(() => {
        allowScroll = false;
        loadMore();
      }, 1000);
    }
  };

  const deleteChatGroup = async (id, e) => {
    e.preventDefault();
    let response = await props.deleteChatGroup(id);
    if (response.data.isDeleted) {
      dispatch({ type: "CHANGE_CHAT_ROOM", payload: null });
      setShowGroupModal(false);
      props.getAll();
    }
  };

  const editGroup = (e) => {
    e.preventDefault();
    let arr = [];
    for (let item of groupModalData) {
      if (item.user !== props.authData.email) {
        arr.push(item.user);
      }
    }
    
    // const pubnub = new PubNub(pubnub_config);
    // setTimeout(() => {
    //   pubnub.objects.getChannelMembers({
    //     channel: props.currentChatRoom.channel
    // },(res)=>{
    // });
    // }, 2000);
    dispatch({ type: "CREATE_GROUP_ARRAY", payload: arr });
    props.setGroupArray(arr);
    setShowContact(true);
    setShowGroupModal(false);
  };

  return (
    <div>
      {/* <div className="container">
      <div>
        {messages.map((props.currentChatRoom, key) => (
          <div key={key}>
            {props.currentChatRoom.type === "msg"? <div>{props.currentChatRoom?.text}</div> : props.currentChatRoom.type === "gif" ? <img src={props.currentChatRoom.text}/> : props.currentChatRoom.type === "file" ? <button onClick={(e)=>{downloadFile(props.currentChatRoom.file)}}>{props.currentChatRoom?.file?.name}</button> : null}
            {convertTime(props.currentChatRoom.time)}<button onClick={(e)=>{deleteMsg(props.currentChatRoom.time,key)}}>Delete</button>
          </div>
        ))}
      </div>
      <IonInput
        onKeyDown={handleKeyDown}
        placeholder={channel}
        onIonChange={tempMessage.onChange}
        value={tempMessage.value}
      ></IonInput>
      <IonButton
        onClick={(e) => {
          sendGif();
        }}
      >
        Send
      </IonButton>
      {chosenEmoji ? (
          <span>You chose: {chosenEmoji.emoji}</span>
        ) : (
          <span>No emoji Chosen</span>
        )}
        <IonButton ><input type="file" onChange={(e)=>{sendFile(e)}}/>Send File</IonButton>
        <Picker onEmojiClick={onEmojiClick} />

        <Pick apiKey="2TeAMMF4iW13Hk9OHBxplnesY116uPMA" onSelected={(result) => sendGif(result)} />
    </div> */}

      {/* sportTalks-chat */}
    
      
      <div className="sportTalks-chat">
        <div className="sportTalks-chattop">
        
          <div className="sportTalks-chattop-inner">
            <div className="sportTalks-chattop-left">
              <div class="sportTalks-chattop-userimg">
                {props.currentChatRoom.image.type === "private" ? (
                  <IonImg
                    src={
                      props.currentChatRoom.image.path
                        ? props.currentChatRoom.image.path
                        : Client
                    }
                    alt=""
                  />
                ) : (
                  <p>{props.currentChatRoom.image.path}</p>
                )}
              </div>
              <div className="sportTalks-chattop-userdetail">
                <h3>{props.currentChatRoom.receiver}</h3>
              </div>
            </div>
            <div
              className="sportTalks-chattop-right"
              onClick={(e) => showUser(props.currentChatRoom)}
            >
              <div className="sportTalks-chattop-info">
                <a>
                  <IonIcon icon={informationOutline} />
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* onIonRefresh={loadMore} */}
        {/* sportTalks-chatbody */}
       
        {messages.length > 0 ? (
          <div>
            <div
              className="sportTalks-chatbody"
              id="hey1"
              ref={messagesEndRef}
              onScroll={handleScroll}
            >
              {messages.map((item, key) => (
                <div>
                  {setValue(item?.time)}
                  {showData === 1 ? (
                    <div className="dayseparation">
                      <span>Today</span>
                    </div>
                  ) : null}
                  
                  {item?.uuid !== username ? (
                    <div className="leftchat">
                      <div className="leftchat-img">
                        {item.image || item.image === null ? (
                          <IonImg
                            src={item.image === null ? Client : item.image}
                            alt=""
                          />
                        ) : (
                          <IonImg
                            src={
                              props.currentChatRoom.image.path && item?.image
                                ? props.currentChatRoom.image.path
                                : Client
                            }
                            alt=""
                          />
                        )}
                      </div>

                      <div className="leftchat-content">
                        <span class="group-username">
                          {item?.userName ? item.userName : null}
                        </span>
                        <div className="leftchat-content-detail">
                          {item?.type === "msg" ? (
                            <p>{item?.text}</p>
                          ) : item.type === "gif" ? (
                            <div className="receive-img">
                              <IonImg src={item.text} /> <p>{item?.gifText}</p>
                            </div>
                          ) : item?.type === "file" ? (
                            <div className="dowmload-chat-file">
                              <IonButton
                                onClick={(e) => {
                                  downloadFile(item.file);
                                }}
                              >
                                {item?.file?.name}
                                <IonIcon icon={downloadOutline} />
                              </IonButton>
                            </div>
                          ) : null}

                          {/* <a
                            className="chatedit"
                            onClick={(e) => {
                              deleteMsg(item.time, key, item);
                            }}
                          >
                            <IonIcon icon={trashOutline} />
                          </a> */}
                        </div>
                        <div className="lastchat">
                          <span>{convertTime(item?.time)}</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="rightchat">
                      <div className="rightchat-content">
                        <span class="group-username">
                          {item?.userName ? item.userName : null}
                        </span>
                        <div className="rightchat-content-detail">
                          {/* <a
                            className="chatedit"
                            onClick={(e) => {
                              deleteMsg(item.time, key, item);
                            }}
                          >
                            <IonIcon icon={trashOutline} />
                          </a> */}

                          {item.type === "msg" ? (
                            <p>{item?.text}</p>
                          ) : item.type === "gif" ? (
                            <div className="send-img">
                              <IonImg src={item.text} /> <p>{item?.gifText}</p>
                            </div>
                          ) : item.type === "file" ? (
                            <div className="dowmload-chat-file">
                              <IonButton
                                onClick={(e) => {
                                  downloadFile(item.file);
                                }}
                              >
                                {item?.file?.name}
                                <IonIcon icon={downloadOutline} />
                              </IonButton>
                            </div>
                          ) : null}
                        </div>
                        <div className="lastchat">
                          <span>{convertTime(item.time)}</span>
                        </div>
                      </div>
                      <div className="rightchat-img">
                        <IonImg
                          src={
                            props.authData.imagename
                              ? props.authData.imagename
                              : Client
                          }
                          alt=""
                        />
                      </div>
                    </div>
                  )}
                </div>
              ))}
               {isErr ? <div className="dayseparation">
                <span>{msgError}</span>
              </div> :null}
            </div>
          </div>
        ) : messageLoadingStarted === false && messages.length === 0 ? (
          <div className="sportTalks-chatbody">
          <div className="nomessages-text">
            <p>No messages sent yet! </p>
          </div>
          {isErr ? <div className="nomessages-text">
                <p>{msgError}</p>
              </div> :null}
          </div>
        ) : messageLoadingStarted === true && messages.length === 0 ? (
          <div className="sportTalks-chatbody">
          <div className="nomessages-text">
            <p>Loading! </p>
          </div>
          </div>
        ) : null}
        
        {/* sportTalks-chatbottom */}
        <div className={showKeyBoard ? "sportTalks-chatbottom keyboardController" : "sportTalks-chatbottom" } >
       
          <div className="sportTalks-chatinput">
            <IonInput
            id="chatMessage"
              type="text"
              placeholder="Type a message"
              onKeyDown={handleKeyDown}
              onIonChange={tempMessage.onChange}
              value={tempMessage.value}
            ></IonInput>
          </div>

          <div className="sportTalks-bottomright">
            {webb ?  (
             <a
             href="javascript:;"
             className="imojiicon"
             onClick={(e) => {
               openEmoji(true);
             }}
             ref={emojiRef}
           >
             <IonIcon icon={happyOutline} />
             {setEmoji ? <Picker onEmojiClick={onEmojiClick} /> : null}
           </a>
            ) : null }
            
            {(() => {
              if (loader) {
                return (
                  <IonSpinner name="crescent" className="spinner-loader" />
                );
              }
            })()}
            {webb ? (
              <a href="javascript:;" className="documenticon">
                <input
                  className="fileinput"
                  type="file"
                  // accept="file"
                  onChange={(e) => {
                    // setTimeout(() => {
                    sendFile(e);
                    // }, 500);
                  }}
                ></input>
                <IonIcon icon={attachOutline} />
              </a>
            ) : null}

            <a
              href="javascript:;"
              className="imojiicon"
              onClick={(e) => openGif(true)}
              ref={gifRef}
            >
              {/* <IonIcon icon={documentOutline} /> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18.76"
                height="18.779"
                viewBox="0 0 20.76 20.779"
              >
                <g id="GIF-ICON" transform="translate(-31.5)">
                  <path
                    id="Path_853"
                    data-name="Path 853"
                    d="M50.945,7.432h-.562V5.025c0-.015,0-.03,0-.045a.365.365,0,0,0-.111-.264L45.749.14s0,0,0,0a.4.4,0,0,0-.094-.068.111.111,0,0,0-.03-.015.433.433,0,0,0-.089-.034c-.009,0-.017,0-.026-.008A.443.443,0,0,0,45.4,0H34.3a.873.873,0,0,0-.919.815V7.432h-.562A1.248,1.248,0,0,0,31.5,8.6v6.055a1.248,1.248,0,0,0,1.315,1.166h.562v4.146a.873.873,0,0,0,.919.815H49.464a.873.873,0,0,0,.919-.815V15.818h.562a1.248,1.248,0,0,0,1.315-1.166V8.6A1.245,1.245,0,0,0,50.945,7.432ZM49.464,19.745H34.3V15.818H49.464v3.927ZM38.407,13.694a2.274,2.274,0,0,0,.766-.091V12.332h-1.06V11.257h2.558v3.207a8.615,8.615,0,0,1-2.29.343,4.142,4.142,0,0,1-2.792-.822,2.77,2.77,0,0,1-.966-2.211c.009-2.022,1.668-3.176,3.92-3.176a5.276,5.276,0,0,1,1.9.3L40.118,10a4.273,4.273,0,0,0-1.6-.26c-1.294,0-2.273.649-2.273,1.969A1.95,1.95,0,0,0,38.407,13.694Zm3.477,1.049V8.658h1.558v6.085Zm6.924-3.565V12.3H46.344v2.445H44.787V8.658h4.2V9.786H46.344v1.392Zm.655-3.746H34.3V.815H44.944V4.983a.434.434,0,0,0,.46.407h4.06V7.432Z"
                    transform="translate(0)"
                  />
                </g>
              </svg>

              {setGif ? (
                <Pick
                  apiKey="2TeAMMF4iW13Hk9OHBxplnesY116uPMA"
                  onSelected={(result) => sendGif(result)}
                />
              ) : null}
            </a>

            {/* <a href="javascript:;" className="massagesend-btn" onClick={(e)=>openGif(true)} ref={gifRef}>
              Send Gif  <IonIcon icon={sendOutline} />
              {setGif ?  <Pick apiKey="2TeAMMF4iW13Hk9OHBxplnesY116uPMA" onSelected={(result) => sendGif(result)} /> : null}
             
            </a> */}

            <a
              href="javascript:;"
              className="massagesend-btn"
              onClick={(e) => publishMessage()}
            >
              Send <IonIcon icon={sendOutline} />
            </a>
          </div>
        </div>

        {/* chat userdetail modal */}

        <IonModal
          isOpen={showModal}
          cssClass="chat-userdetail-model"
          swipeToClose={true}
          onDidDismiss={() => setShowModal(false)}
        >
          <div className="model-content">
            <div className="model-header">
              <div className="userimg-perent">
                <div className="modal-closer">
                  <IonButton onClick={() => setShowModal(false)}>
                    {" "}
                    <IonIcon icon={close} />
                  </IonButton>
                </div>
              </div>
              <div className="userimg">
                <IonImg
                  src={modalData?.image?.path ? modalData.image.path : Client}
                  alt=""
                />
              </div>
              {/* {console.log("MODEL",modalData)} */}
              <div className="user-detail">
                <h2>{modalData.receiver}</h2>
                <p>{modalData.email}</p>
                {/* <p>{modalData?.home_address}</p> */}
              </div>
            </div>
          </div>

          {/* <IonImg src={
                          username === props.currentChatRoom.sender_email
                            ? props.currentChatRoom.receiver_image
                              ? props.currentChatRoom.receiver_image
                              : Client
                            : props.currentChatRoom.sender_image
                            ? props.currentChatRoom.sender_image
                            : Client
                        } alt="" /> */}
        </IonModal>

        <IonModal
          isOpen={showGroupModal}
          cssClass="chat-groupdetail-model theme-modal"
          swipeToClose={true}
          onDidDismiss={() => setShowGroupModal(false)}
        >
          {/* <IonImg src={
                          username === props.currentChatRoom.sender_email
                            ? props.currentChatRoom.receiver_image
                              ? props.currentChatRoom.receiver_image
                              : Client
                            : props.currentChatRoom.sender_image
                            ? props.currentChatRoom.sender_image
                            : Client
                        } alt="" /> */}
          <div className="model-content">
            <div className="model-header">
              <div className="userimg-perent">
                <div className="modal-closer">
                  <IonButton onClick={() => setShowGroupModal(false)}>
                    <IonIcon icon={close} />
                  </IonButton>
                </div>
              </div>
              <div className="userimg">
                <p>{props.currentChatRoom.image.path}</p>
              </div>
              <div className="user-detail">
                <h2>{props.currentChatRoom.receiver}</h2>
                {/* <p>Created by tej</p> */}
                <IonButton
                  className="theme-gradient-btn"
                  onClick={(e) =>
                    deleteChatGroup(props.currentChatRoom.groupId, e)
                  }
                >
                  Delete Group{" "}
                  {(() => {
                    if (props.loading) {
                      return (
                        <IonSpinner
                          name="crescent"
                          className="spinner-loader"
                        />
                      );
                    }
                  })()}
                </IonButton>
                <IonButton
                  className="theme-gradient-btn"
                  onClick={(e) => editGroup(e)}
                >
                  Edit Group
                </IonButton>
              </div>
            </div>

            <div className="model-body">
              <div className="model-body-heading">
                {/* <h2>2 Members</h2> */}
              </div>
              {groupModalData.map((item, key) => {
                return (
                  <a className="sportTalks-list-item">
                    <div className="sportTalks-list-inner">
                      <div className="sportTalks-list-left">
                        <div class="sportTalks-userimg">
                          <IonImg
                            src={item.imagename ? item.imagename : Client}
                            alt=""
                          />
                        </div>
                        <div className="sportTalks-userdetail">
                          <h3>{item.fullname}</h3>
                          <p>{item.user}</p>
                        </div>
                      </div>

                      <div className="sportTalks-list-right">
                        <div
                          className="sportTalks-time"
                          onClick={() => {
                            changeModalData(item);
                          }}
                        >
                          <p>View</p>
                        </div>
                      </div>
                    </div>
                  </a>
                );
              })}
            </div>
          </div>
        </IonModal>

        {/* gif modal */}
        <IonModal
          isOpen={showGif}
          cssClass="theme-modal gif-modal"
          swipeToClose={true}
          onDidDismiss={() => setShowGif(false)}
        >
          <div className="modal-header">
            <div className="modal-heading">
              <h2>Send GIF</h2>
            </div>
            <div className="modal-closer">
              <IonButton onClick={() => setShowGif(false)}>
                {" "}
                <IonIcon icon={close} />
              </IonButton>
            </div>
          </div>
          <div className="modal-body">
            <div className="gitmodal-img">
              <IonImg src={selectedGif}></IonImg>
            </div>
            <div className="form-group">
              <IonLabel>Type Message</IonLabel>
              <IonInput
                onIonChange={tempMessage.onChange}
                value={tempMessage.value}
              ></IonInput>
            </div>
          </div>
          <div className="modal-footer">
            <IonButton onClick={sendGifMessage}>
              {" "}
              Send <IonIcon icon={sendOutline} />
            </IonButton>
          </div>
        </IonModal>
        {/* gif modal end */}

        <IonModal
          isOpen={showContact}
          cssClass="contact-page-modal"
          onDidDismiss={(e) => {
            dispatch({ type: "CREATE_GROUP_ARRAY", payload: [] });
          }}
        >
          <div className="modal-header">
            <div className="modal-closer">
              <IonButton onClick={() => setShowContact(false)}>
                {" "}
                <IonIcon icon={close} />
              </IonButton>
            </div>
          </div>
          <ContactModal isChat={true} isClose={closeModal} isClick={true} groupMembers={groupModalData} />
        </IonModal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.authReducer.isLoggedIn,
    errorMsg: state.commonReducer.errorMsg,
    errorData: state.commonReducer.errorData,
    successMsg: state.commonReducer.successMsg,
    loading: state.commonReducer.loading,
    authData: state.authReducer.authData,
    chatRoom: state.chatReducer.chatRoom,
    currentChatRoom: state.chatReducer.currentChatRoom,
    allGroupMembers: state.chatReducer.allGroupMembers,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      saveLastSeen,
      sendUserNotification,
      deleteChatGroup,
      setGroupArray,
      getGroupmembers,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChatConversation)
);
