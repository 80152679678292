import { IonContent, IonButton } from "@ionic/react";
import React from "react";
import { menuController } from "@ionic/core";
import "./login.css";
import { withRouter, Link } from "react-router-dom";
import { ClearData } from "../redux/actions/commonAction";
import { connect } from "react-redux";
import { loginAction, isUser, googleLogin } from "../redux/actions/authAction";
import { bindActionCreators } from "redux";

const GoogleLogin = (props) => {
  let auth2;
  React.useEffect(() => {
    menuController.enable(false);
    
    setTimeout(() => {
      loadFunction();
      
    }, 1000);
  }, []);

  const loadFunction = () => {
    const successCallback = onSuccess.bind(this);
    const errorCallback = oneError.bind(this);
    if(window.gapi){
      window.gapi.load("auth2", () => {
        auth2 = window.gapi.auth2.init({
          client_id: process.env.REACT_APP_GOOGLEAPPID,
        });
  
        // this.auth2.attachClickHandler(document.querySelector('#loginButton'), {}, this.onLoginSuccessful.bind(this))
  
        auth2.then(() => {
          // this.setState({
          //   isSignedIn: this.auth2.isSignedIn.get(),
          // });
        });
      });
      window.onbeforeunload = function(e){
        window.gapi.auth2.getAuthInstance().signOut();
      };
      window.gapi.load("signin2", function () {
        // Method 3: render a sign in button
        // using this method will show Signed In if the user is already signed in
        var opts = {
          // width: 200,
          // height: 50,
          client_id: process.env.REACT_APP_GOOGLEAPPID,
          onsuccess: successCallback,
          onfailure: errorCallback,
        };
        window.gapi.signin2.render("loginButton", opts);
      });
    }
   
  };

  const getGoogleIdToken = async (event)=>{
    for (const [key, value] of Object.entries(event)) {
     
      if(Object.keys(value).includes('id_token') === true){
        return value.id_token;
      }
      
    }
    
  }
  const onSuccess = async(event) => {
    let body = { idToken: await  getGoogleIdToken(event), role: 1, timeZone:Intl.DateTimeFormat().resolvedOptions().timeZone };
    props.googleLogin(body);
  };

  const oneError = (event) => {
  };
  return (
    <div class="icon-btn-google">
      <IonButton color="none" id="loginButton"></IonButton>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ googleLogin }, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GoogleLogin)
);
