import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonInput,
  IonButton,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonImg,
} from "@ionic/react";
import React, { useEffect } from "react";
import UpcomingEvents from "./upcomingEvents/upcomingEvents";
import CurrentGoals from "./currentGoals/currentGoal";
import Weather from "./weather/weather";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { isUser } from "../../redux/actions/authAction";
import { ClearData } from "../../redux/actions/commonAction";
import "./dashboard.css";
import { menuController } from "@ionic/core";
const Dashboard = (props) => {
  useEffect(() => {
    menuController.enable(true);
    props.ClearData();
  }, []);
  return (
    <IonContent>
      <div class="dash-inner-body">
        <div class="dash-heading">
          <h2>Welcome, {props?.authData?.fullname}!</h2>
          <p>Dashboard</p>
        </div>
        <IonGrid>
          <IonRow>
            <IonCol size="12" size-md="9">
              <UpcomingEvents></UpcomingEvents>
              <CurrentGoals></CurrentGoals>
            </IonCol>
            <IonCol size="12" size-md="3">
              <Weather latitude= {props?.authData?.latitude} longitude={props?.authData?.longitude}></Weather>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </IonContent>
  );
};

const mapStateToProps = (state) => {
  return {
    authData: state.authReducer.authData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ isUser, ClearData }, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
