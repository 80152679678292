export const initialState = {
   contactList: [],
   addContactData: {}
}


 const contactReducer = (state = initialState, action) => {
     
    switch (action.type) {
            case "CONTACT_LIST":
                
                if(action.payload?.clear === true){
                    return {
                        ...state,
                        // contactList:[...state.contactList, ...action.payload?.data]
                        contactList:  []
                    };
                } else if(action?.search === true){
                    return {
                        ...state,
                        // contactList:[...state.contactList, ...action.payload?.data]
                        contactList:  action?.payload?.data
                    };
                } else {
                    return {
                        ...state,
                        // contactList:[...state.contactList, ...action.payload?.data]
                        contactList:  [...state.contactList, ...action?.payload?.data]
                    };
                }
            
            case "ADD_CONTACT":
            return {
                ...state,
                addContactData: action.payload
            };
        default:
            return state;
    }
}

export default contactReducer;