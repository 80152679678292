import { useState } from 'react';

function useInput()
{
    
  let [value, setValue] = useState('');
  let onChange = function(event){
    setValue(event.detail.value);
  };
  let onEmoji = function(emoji){
    setValue(value? value.concat(emoji) : emoji);
  };
  return {
    value,
    setValue,
    onChange,
    onEmoji
  };
}

export default useInput;


