import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonCardTitle,
  IonInput,
  IonButton,
  IonCardHeader,
  IonLabel,
  IonSpinner,
} from "@ionic/react";
import { Link } from "react-router-dom";
import {
  updateZoomLink,
  getZoomLink,
  getZoomLinkForAdmin,
} from "../../redux/actions/zoomAction";
import "./zoom.css";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const Zoom = (props) => {
  const [formData, setFormValue] = useState({ zoomLink: "" });
  const [errorData, setErrorData] = useState({ zoomLink: [] });
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (props.checkUserRole === 3 || props.checkUserRole === 2) {
      const id = JSON.parse(localStorage.getItem("userData")).id;
      props.getZoomLink(id);
    } else if (props.checkUserRole === 1) {
      const id = JSON.parse(localStorage.getItem("userData")).id;
      props.getZoomLinkForAdmin(id);
    }
  }, []);

  useEffect(() => {
    if (props?.zoomPayload?.data?.data[0]?.zoom_link) {
      const link = props.zoomPayload.data.data[0].zoom_link;
      setInputValue(link);
    }
  }, [props.zoomPayload.data?.data]);

  const handleChange = (formField, event) => {
    let test = formData;
    test[formField] = event.target.value;
    setInputValue(test[formField]);
    setFormValue(test);
  };

  const validateForm = () => {
    let isFormValid = true;
    let testError = { zoomLink: [] };
    let regex = new RegExp(
      "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
    );

    if (formData.zoomLink.trim().length === 0) {
      isFormValid = false;
      testError.zoomLink = ["This field is required!"];
    }
    if (
      formData.zoomLink.trim().length > 0 &&
      regex.test(formData.zoomLink) === false
    ) {
      isFormValid = false;
      testError.zoomLink = ["Please provide a valid Zoom Link!"];
    }
    setErrorData(testError);
    return isFormValid;
  };

  function openTab(value) {
    window.open(value);
  }

  async function onSubmitForm(e) {
    if (validateForm()) {
      formData.id = await JSON.parse(localStorage.getItem("userData")).id;
      await props.updateZoomLink(formData).then((response) => {
        if (response.success === true) {
          props.getZoomLinkForAdmin(formData.id);
          setToast(true);
          setToastMessage(response.message);
          setTimeout(() => {
            setToast(false);
          }, 3000);
        }
      });
      if (props?.zoomPayload?.data?.data[0]?.zoom_link) {
        const link = props?.zoomPayload?.data?.data[0]?.zoom_link;
        setInputValue(link);
      }
    }
  }

  return (
    <IonContent>
      <div className="zoom">
        <div className="zoom-header">
          <IonCardHeader>
            {props.checkUserRole === 1 ?  <p style={{fontSize:18,width:'100%', textAlign:"center"}} className="inner-page-heading">Share a Zoom link here and your team will see it!</p>:null}
           
            {toast === true ? (
              <div className="success-msg">
                <p>{toastMessage}</p>
              </div>
            ) : null}
          </IonCardHeader>
        </div>

        {props.checkUserRole === 1 ? (
          // admin
          <div className="zoom-content-body">
            <div className="zoom-content-head">
              <h2>Zoom link<sup>*</sup></h2>
            </div>

            <div className="zoom-content-mid">
              <form>
                <div className="form-group">
                  <IonInput
                    type="text"
                    name="zoomLink"
                    value={inputValue}
                    // placeholder={inputValue}
                    // defaultValue={props.zoomPayload?.data?.data[0].zoom_link}
                    className="form-control"
                    onIonChange={(event) => {
                      handleChange("zoomLink", event);
                    }}
                  ></IonInput>
                  <p className="errormsg">
                    {" "}
                    {errorData?.zoomLink && errorData?.zoomLink[0]}
                  </p>
                </div>
                <div className="form-group-center">
                  <IonButton
                    color="none"
                    className="gradient-btn-blue"
                    onClick={(event) => {
                      onSubmitForm(event);
                    }}
                  >
                    Share Link
                    {(() => {
                      if (props.loading) {
                        return (
                          <IonSpinner
                            name="crescent"
                            className="spinner-loader"
                          />
                        );
                      }
                    })()}
                  </IonButton>
                </div>
              </form>
            </div>
          </div>
        ) : (
          //admin end
          // null
          // player
          <div className="zoom-content-body">
            <div className="zoom-content-head">
              <h2>Zoom URL</h2>
            </div>
            <div className="zoom-content-mid">
              <div class="zoom-url">
                <Link
                  onClick={() =>
                    openTab(props.zoomPayload?.data?.data[0].zoom_link)
                  }
                >
                  {props.zoomPayload?.data?.data[0]?.zoom_link}
                </Link>
                {/* <Link to={props.zoomPayload?.data?.data[0].zoom_link} target="_blank">View</Link> */}
                {/* <Link to={props.zoomPayload?.data?.data[0].zoom_link} target="_blank" >
            {props.zoomPayload?.data?.data[0].zoom_link}
            </Link> */}
                {/* <a href={props.zoomPayload?.data?.data[0].zoom_link} target="_blank"> {props.zoomPayload?.data?.data[0].zoom_link}</a> */}
              </div>
            </div>
          </div>
          // player end
        )}
      </div>
    </IonContent>
  );
};

const mapStateToProps = (state) => {
  return {
    checkUserRole: state.authReducer.checkUserRole,
    zoomPayload: state.zoomReducer.zoomPayload,
    loading: state.commonReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { updateZoomLink, getZoomLink, getZoomLinkForAdmin },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Zoom);
