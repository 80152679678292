import React,{useState,useEffect} from 'react';
import {
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonImg,
    IonCheckbox,IonCardContent
  } from "@ionic/react";
  import { IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/react";
  import ContactImg from "../../images/client-dummy.png";
  import { bindActionCreators } from "redux";
  import { withRouter } from "react-router-dom";
  import { connect } from "react-redux";
  import {createGroupArray,getChatRoom} from "../../redux/actions/chatAction"
  import { useLocation } from 'react-router-dom'

const Strength = (props) => {
  const [contactArray,setContactArray]= useState([])
  const location = useLocation();
  useEffect(()=>{
    if(location.pathname === "/page/sportTalks"){
      (async()=>{
        await filterList().then(
          data=>{
            if(props.isChat === true){
              let dummyArray= data
              let checkedArray= props.groupArray
              for(let item of dummyArray){
                item["checked"]=false
                for(let c of checkedArray){
                  if(c === item.email){
                    item.checked = true
                  }
                }
              }
              setContactArray(dummyArray)
            }else{
              setContactArray(data)
            }
          }
        )
      
      })()
     
    }else{
      setContactArray(props.payload)
    }
   
   
  },[props.payload])
  const activateChat= (item)=>{
    if(location.pathname === "/page/sportTalks"){
      getChatRoom(item)
     
    }else{
      props.history.push({pathname:"/page/sportTalks",state:item})
    }
      
  }

  const filterList=async()=>{
    let filteredEvents=[] = await props.payload.filter(function(event){
      return event.is_in_users === 1;
    });
    return filteredEvents
  }
  const selectContact= async(item)=>{
    if(item.is_in_users === 1){
      let new_array = contactArray.map((element) =>
        element.id === item.id
          ? { ...element, ["checked"]: !element.checked }
          : element
      );
      setContactArray(new_array);
      props.createGroupArray(item);
    }
  }
  const getChatRoom = async(item)=>{
    let channelObj={
      sender: props.authData.email,
      receiver: item.email
    }
    let reqBody= {
      sender: props.authData.email,
      receiver: item.email,
      channelObj:Math.random().toString(36).substring(7) + Math.random().toString(36).substring(7),
      channelUser:channelObj
    }
    await props.getChatRoom(reqBody)
    props.closeChat()
  }

    return (  <div>
      <IonCard>
        <IonCardContent class="dashboard-card-body">
          {contactArray && contactArray.length > 0 ? (
            contactArray.map((object, index) => {
              let mailTo = `mailto: ${object.email}`;
              if(location.pathname === "/page/contacts"){
                return (
                  <div class="contact-message-box" key={index} >
                   {(props.isChat=== true && object?.is_in_users === 1) ? <IonCheckbox slot="start" checked={object?.checked ? object.checked :false} onClick={e => selectContact(object)} color="primary"/>   :null} 
                    <IonGrid>
                      <IonRow onClick={() => { props.isChat === true ? 
                        selectContact(object) : console.log("dsa")  }}>
                        <IonCol size="12" size-md="2">
                          <div class="contact-message-profile">
                            <div class="contact-profile-circle">
                              <IonImg
                                src={
                                  object.profileImage
                                    ? `${object.profileImage}`
                                    : ContactImg
                                }
                              />
                            </div>
                            <h6>{object.fullname}</h6>
                            <span>{object?.role_name}</span>
                          </div>
                        </IonCol>
                        <IonCol size="12" size-md="10">
                          <div class="contact-message-details">
                            <div class="contact-message-info">
                              <ion-list lines="none">
                                <ion-item>
                                  <b>Hometown:</b> {object?.hometown ? object?.hometown : "N/A"}
                                </ion-item>
                                <ion-item>
                                  <b>Email Address:</b>{" "}
                                  <a
                                    className="mail-hyperlink"
                                    href={mailTo}
                                  >
                                    {object.email}
                                  </a>
                                </ion-item>
                                <ion-item>
                                  <b>Phone:</b> {object.contactNo ? object.contactNo : "N/A"}
                                </ion-item>
                              </ion-list>
                            </div>
                            {object?.is_in_users === 1 ? (
                              <div class="contact-message-btn" onClick={(e)=>{activateChat(object)}}>
                                <a>Message</a>
                              </div>
                            ) : (
                              <div class="contact-message-btn">
                                <a href={mailTo}>Invite</a>
                              </div>
                            )}
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </div>
                );
              }else if(object.is_in_users === 1){
                return (
                  <div class="contact-message-box" key={index} >
                   {(props.isChat=== true && object?.is_in_users === 1) ? <IonCheckbox slot="start" checked={object?.checked ? object.checked :false} onClick={e => selectContact(object)} color="primary"/>   :null} 
                    <IonGrid>
                      <IonRow onClick={() => { props.isChat === true ? 
                        selectContact(object) : console.log("dsa")  }}>
                        <IonCol size="12" size-md="2">
                          <div class="contact-message-profile">
                            <div class="contact-profile-circle">
                              <IonImg
                                src={
                                  object.profileImage
                                    ? `${object.profileImage}`
                                    : ContactImg
                                }
                              />
                            </div>
                            <h6>{object.fullname}</h6>
                            <span>{object?.role_name}</span>
                          </div>
                        </IonCol>
                        <IonCol size="12" size-md="10">
                          <div class="contact-message-details">
                            <div class="contact-message-info">
                              <ion-list lines="none">
                                <ion-item>
                                  <b>Hometown:</b> {object?.hometown ? object?.hometown : "N/A"}
                                </ion-item>
                                <ion-item>
                                  <b>Email Address:</b>{" "}
                                  <a
                                    className="mail-hyperlink"
                                    href={mailTo}
                                  >
                                    {object.email}
                                  </a>
                                </ion-item>
                                <ion-item>
                                  <b>Phone:</b> {object.contactNo ? object.contactNo : "N/A"}
                                </ion-item>
                              </ion-list>
                            </div>
                            {props.isChat=== true ? <div></div> : object?.is_in_users === 1 ? (
                              <div class="contact-message-btn" onClick={(e)=>{activateChat(object)}}>
                                <a>Message</a>
                              </div>
                            ) : (
                              <div class="contact-message-btn">
                                <a href={mailTo}>Invite</a>
                              </div>
                            )}
                            
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </div>
                );
              }else{
                return null
              }
              
            })
          ) : props.loading === true ? (
            <p style={{padding:10}}>Loading...</p>
          ) : (
            <p style={{padding:10}}>No Contacts Yet</p>
          )}
          <IonInfiniteScroll
            ref={props.ionInfiniteScrollRef}
            threshold="30%"
            position="bottom"
            disabled={props.disableInfiniteScroll}
            onIonInfinite={props.loadMoreItems}
          >
            <IonInfiniteScrollContent
              loadingSpinner="bubbles"
              loadingText="Loading data..."
            ></IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </IonCardContent>
      </IonCard>
    </div>);
}

const mapStateToProps = (state) => {
  return {
    groupArray:state.chatReducer.groupArray,
    authData:state.authReducer.authData
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({createGroupArray,getChatRoom}, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Strength)
);