import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    getStripeAction,
    changeUserPaymentStatus,
    savePaymentResponse,
    getStripeProducts,
} from "../../redux/actions/stripeAction";
import { checkPayment } from "../../redux/actions/authAction";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import {
    IonContent, IonGrid, IonRow, IonCol,
} from "@ionic/react";
import "./subscription.css";
import CheckoutForm from "./elementConsumer";
import Modal from 'react-modal';
import { getPlatforms } from '@ionic/react';

function Subscription(props) {
    const [products, setProducts] = useState([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [selectedProduct, setProduct] = useState(null);
    const [paymentCompleted, setSetPaymentCompleted] = useState(false);
    const [isWeb, setIsWeb] = useState(false);

    useEffect(() => {
        let web = getPlatforms().some(r => ["desktop", "mobileweb"].indexOf(r) >= 0)
        const fetchData = async () => {
            const data = await getStripeProducts();
            if (!web) {
                setIsWeb(false)
            } else {
                setIsWeb(true)
            }
            if (data.length > 0) {
                setProduct(data[0])
            }
            setProducts(data);
        }
        fetchData();
    }, []);

    function closeModal() {
        setIsOpen(false);
    }
    function openModal(product) {
        setProduct(product);
        setIsOpen(true);
    }
    function highlightProduct(product) {
        setProduct(product);

    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: "40%",
            border: "none",
            background: "transparent",
            minWidth: "350px"
        },

    };

    const { stripe } = props;

    return (
        <IonContent className="center-content ion-content">
            <IonGrid style={{ padding: "0px 15%" }}>
                <IonCol>
                    {

                        !isWeb ?
                            <p className="text">You can't subscribe from the app. You must be using a browser.</p> :
                            <>
                                <img className="title-logo" src='/static/media/logo-blue.41890d87.png' alt='Sportal Logo' />
                                {paymentCompleted ?
                                    <p>You are now subscribed.</p> :
                                    <IonRow className="row">
                                        {
                                            products.map((product) => (
                                                <IonCol className={product === selectedProduct ? "column border" : "column no-border"} key={product.id}>
                                                    <button style={{ background: "transparent" }} onClick={() => highlightProduct(product)}>
                                                        <h2 className={product.unit_label === "year" ? "large-text gradient-text" : "large-text invisible-text"}>Most Popular</h2>
                                                        <h2 className="large-text">{!product.name != null && product.name !== undefined ? product.name : 'Empty'}</h2>
                                                        <p className="text subscription-detail">Free 30 day trial</p>
                                                        {product.unit_label === "year" && <p className="text subscription-detail">Billed annually</p>}
                                                        {product.unit_label === "month" && <p className="text subscription-detail">Billed monthly</p>}
                                                        <h2 className="large-text">
                                                            {
                                                                <p>
                                                                    {Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }
                                                                    ).format(product.unit_amount_decimal / 100)}/<span className="small-unit">{product.unit_label}</span>
                                                                </p>
                                                            }
                                                        </h2>
                                                        <div>
                                                            <button className="filled-button" onClick={() => openModal(product)}>
                                                                Select Plan
                                                            </button>
                                                        </div>
                                                    </button>
                                                </IonCol>

                                            ))
                                        }
                                        <Modal
                                            isOpen={modalIsOpen}
                                            onRequestClose={closeModal}
                                            style={customStyles}
                                        >
                                            <CheckoutForm stripe={stripe} product={selectedProduct} callback={async () => {
                                                const authUserData = props.authData;
                                                await props.checkPayment({ isPayment: 1 });
                                                authUserData.is_payment = 1;
                                                localStorage.setItem("userData", JSON.stringify(authUserData));
                                                props.history.push("/page/coach-dashboard");
                                            }}></CheckoutForm>
                                        </Modal>
                                    </IonRow>}

                            </>
                    }
                </IonCol>
            </IonGrid>
        </IonContent >
    );
}

const mapStateToProps = (state) => {
    return {
        clientSecret: state.stripeReducer.clientSecret,
        errorMsg: state.commonReducer.errorMsg,
        successMsg: state.commonReducer.successMsg,
        authData: state.authReducer.authData,
        loading: state.commonReducer.loading,
        checkIsPayment: state.authReducer.checkIsPayment,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { getStripeAction, checkPayment, changeUserPaymentStatus, savePaymentResponse },
        dispatch
    );
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Subscription)
);
