import { IonList, IonItem, IonImg, IonIcon } from "@ionic/react";
import {
  cloudy,
  water,
  partlySunny,
  speedometer,
  navigate,
} from "ionicons/icons";
import React from "react";
import axios from "axios";

const WeatherApi = process.env.REACT_APP_OPEN_WEATHER_API;
const Weather = (props) => {
  const [weather, setWheater] = React.useState([]);
  const [name, setName] = React.useState("");
  const [date, setDate] = React.useState();
  const [time, setTime] = React.useState();
  const [showMessage,setShowMessage]= React.useState(false)
  React.useEffect(() => {
    if(props.latitude && props.longitude){
      setShowMessage(false)
      getWeather(props.latitude,props.longitude)
    }
    else{
      setShowMessage(true)
    }
  }, [props.latitude,props.longitude]);

  //672f4a68567cc021d37b8af8e0f782a1
  const getWeather = (lat, long) => {
    let options = { weekday: "short" };
    axios
      .get(
        `https://api.openweathermap.org/data/2.5/forecast?lat=${lat}&lon=${long}&appid=${WeatherApi}&units=imperial`
      )
      .then((data) => {
        let weatherArray = [];
        weatherArray = data.data.list;
        let date = new Date();
        let newdate = date.toLocaleString('en-us', {  weekday: 'long' }) +" "+  date.toLocaleString("default", { month: "long" }) + "  " + date.getDate()
          
          
        let time = date.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
        });
        setDate(newdate);
        setName(data.data.city.name);
        setTime(time);
        let showArray = [];
        showArray[0] = {
          date: new Date(weatherArray[0].dt * 1000).toLocaleDateString(
            "en-US",
            options
          ),
          temprature: weatherArray[0].main.temp,
          humidity: weatherArray[0].main.humidity,
          windSpeed: weatherArray[0].wind.speed,
          icon: `https://openweathermap.org/img/w/${weatherArray[0].weather[0].icon}.png`,
        };
        showArray[1] = {
          date: new Date(weatherArray[7].dt * 1000).toLocaleDateString(
            "en-US",
            options
          ),
          temprature: weatherArray[7].main.temp,
          humidity: weatherArray[7].main.humidity,
          windSpeed: weatherArray[7].wind.speed,
          icon: `https://openweathermap.org/img/w/${weatherArray[7].weather[0].icon}.png`,
        };
        showArray[2] = {
          date: new Date(weatherArray[16].dt * 1000).toLocaleDateString(
            "en-US",
            options
          ),
          temprature: weatherArray[16].main.temp,
          humidity: weatherArray[16].main.humidity,
          windSpeed: weatherArray[16].wind.speed,
          icon: `https://openweathermap.org/img/w/${weatherArray[16].weather[0].icon}.png`,
        };
        showArray[3] = {
          date: new Date(weatherArray[25].dt * 1000).toLocaleDateString(
            "en-US",
            options
          ),
          temprature: weatherArray[25].main.temp,
          humidity: weatherArray[25].main.humidity,
          windSpeed: weatherArray[25].wind.speed,
          icon: `https://openweathermap.org/img/w/${weatherArray[25].weather[0].icon}.png`,
        };
        showArray[4] = {
          date: new Date(weatherArray[33].dt * 1000).toLocaleDateString(
            "en-US",
            options
          ),
          temprature: weatherArray[33].main.temp,
          humidity: weatherArray[33].main.humidity,
          windSpeed: weatherArray[33].wind.speed,
          icon: `https://openweathermap.org/img/w/${weatherArray[33].weather[0].icon}.png`,
        };
        setWheater(showArray);
      });
  };
  return (
    <div class="weather-box">
      <div class="weather-box-top">
        <div class="weather-box-head">
          <h6>
          {showMessage ? <div>Please set your location under Profile Section</div> : null}
            <IonIcon icon={navigate} /> {name}
          </h6>
          <p>
            {date}
          </p>
          <span>{time}</span>
        </div>
        <div class="weather-box-mid">
          <div class="weather-deg">
            <div class="weather-deg-inner">
              <div class="weather-deg-detail">
                <h4>{weather[0]?.temprature}° F</h4>
                <span>{weather[0]?.windSpeed}</span>
              </div>
            </div>
            <div class="weather-deg-icon">
              <IonImg src={weather[0]?.icon} />
            </div>
          </div>
        </div>
        <div class="weather-box-bottom">
          <div class="weather-box-temp">
            <p>
              <IonIcon icon={water} /> {weather[0]?.humidity}%
            </p>
            <p>
              {/* <IonIcon icon={speedometer} /> {weather[0]?.windSpeed} km/h */}
            </p>
          </div>
        </div>
      </div>
      <div class="weather-box-week">
        <IonList lines="none">
          {weather.map((value, key) => (
            <IonItem>
              <div>
                <h6>{value?.date}</h6>
                <IonImg src={value?.icon} />
                <p>{value?.temprature}° F</p>
              </div>
            </IonItem>
          ))}
        </IonList>
      </div>
    </div>
  );
};

export default Weather;
