import React, { useEffect } from "react";

import {
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonBadge,
  IonPage,
  IonRouterOutlet,
  Tab1,
  Tab2,
  IonContent,
  IonImg,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import { useDispatch } from "react-redux";
import { calendar, personCircle, map, informationCircle } from "ionicons/icons";
import { Redirect, Route, withRouter, Switch, Link,useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { IonReactRouter } from "@ionic/react-router";
import { apps, flash, send } from "ionicons/icons";
import LikeGrey from "../../images/like-grey.png";
import LikeBlue from "../../images/like-blue.png";
import GoalGrey from "../../images/goal-grey.png";
import GoalBlue from "../../images/goal-blue.png";
import QuestionGrey from "../../images/question-grey.png";
import QuestionBlue from "../../images/question-blue.png";
import { bindActionCreators } from "redux";
import { getQuestionById } from "../../redux/actions/prsAction";
import { changeReadStatus } from "../../redux/actions/zoomAction";
import { getNotifications } from "../../redux/actions/users";
import "./playerReportingSystem.css";
import WeeklyReport from "./weeklyReport";
import MatchingReport from "./matchReporting";
import Questionairre from "./questionairre";

const CRS = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if(props.location?.state?.event?.insertId){
      notif()
    }
  }, []);



  const notif= async ()=>{
    await props.changeReadStatus(props?.location?.state?.id)
    await dispatch({ type: "CLEAN_DATA", payload: [] });
    await props.getNotifications({ page:0 });
  }
  return (
    <IonContent>
      <div className="reporting-inner-body">
        <div className="coach-inner-body-top">
          <IonGrid>
            <IonRow>
              <IonCol size="12" size-sm="6">
                <div className="reporting-heading">
                  {/* <h2>Coach Reporting System</h2> */}
                </div>
              </IonCol>
              { props?.checkUserRole === 1 ?
                <IonCol size="12" size-sm="6">
                <div class="reporting-questionnaire">
                  {/* <a href="javascript:;">Questionnaire</a> */}
                  <Link to="/page/questionnaire">Edit Questions</Link>
                </div>
              </IonCol> : null
              }
              
            </IonRow>
          </IonGrid>
        </div>

        <IonTabs>
          <IonTabBar slot="top">
            <IonTabButton tab="weekly" href="/page/coachReportingSystem/weekly">
              <div className="player-top-tab">
                <div className="tabimg">
                  {/* <IonImg className="question-icona" src={LikeGrey} />
                  <IonImg className="question-iconb" src={LikeBlue} /> */}
                  <IonImg className="question-icon-grey" src={LikeGrey} />
                  <IonImg className="question-icon-blue" src={LikeBlue} />
                </div>
                <div className="tablabel">
                  <IonLabel>Weekly Check-IN</IonLabel>
                </div>
              </div>
            </IonTabButton>

            <IonTabButton tab="match" href="/page/coachReportingSystem/match">
              <div className="player-top-tab">
                <div className="tabimg">
                  {/* <IonImg className="question-icona" src={GoalGrey} />
                  <IonImg className="question-iconb" src={GoalBlue} /> */}
                  <IonImg className="question-icon-grey" src={GoalGrey} />
                  <IonImg className="question-icon-blue" src={GoalBlue} />
                </div>
                <div className="tablabel">
                  <IonLabel>Match report</IonLabel>
                </div>
              </div>
            </IonTabButton>
          </IonTabBar>

          <IonRouterOutlet id="main three">
            <Route
              path="/page/coachReportingSystem/:tab(weekly)"
              component={WeeklyReport}
              exact={true}
            />
            <Route
              path="/page/coachReportingSystem"
              component={WeeklyReport}
              exact={true}
            />
            <Route
              path="/page/coachReportingSystem/:tab(match)"
              component={MatchingReport}
            />
          </IonRouterOutlet>
        </IonTabs>
      </div>
    </IonContent>
  );
};



const mapStateToProps = (state) => {
  return {
    notificationData: state.prsReducer.notificationData,
    checkUserRole: state.authReducer.checkUserRole
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getQuestionById,changeReadStatus,getNotifications }, dispatch);
};


export default withRouter(connect(mapStateToProps,mapDispatchToProps)(CRS));
