import React,{useEffect,useState, useRef} from "react";
import {
  IonContent,
  IonGrid,
  IonRow,
  IonCol
} from "@ionic/react";
import ChatList from "./chatList/chatList";
import ChatConversation from "./chatConversation/chatConversation";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {getChatRoom, getAllChatRoom,getAllGroups} from "../../redux/actions/chatAction"
import { useDispatch } from "react-redux";

import CryptoJS from "react-native-crypto-js";
import "./sportTalks.css";

const SportTalks = (props) => {
  const dispatch = useDispatch();
  const messagesEndRef = useRef(null)
  const [username, setUser] = useState(props.authData?.email);
  const [timer,setTimer]=useState(null)
  useEffect(()=>{
    if(props.location?.state?.id && props.authData?.id){
      getChatRoom()
    }
    let user = localStorage.getItem("userData");
    user = JSON.parse(user);
    setUser(user.email)
    getAll(user.email)
    // let interval=  setInterval(function(){
    //     console.log("interval")
    //     getAll(user.email)   
    //   }, 15000)
    
    
    
    return ()=>{
      // clearInterval(interval)
      dispatch({type:"CHANGE_CHAT_ROOM", payload:null})
    }
  },[])

  const all = ()=>{
    getAll(username)
  }

  useEffect(()=>{
    if(props.chatRoom?.id){
       let obj = {
        channel: props.chatRoom.channel,
        receiver:
          props.authData.email === props.chatRoom.sender_email
            ? props.chatRoom.receiver_name
            : props.chatRoom.sender_name,
        image: {
          path:
            props.authData.email === props.chatRoom.sender_email
              ? props.chatRoom.receiver_image
              : props.chatRoom.sender_image,
          type: "private",
        },
        type: "private",
        message: null,
        timetoken: null,
      };
      dispatch({type:"CHANGE_CHAT_ROOM", payload:obj})
    }
    return ()=>{
      // dispatch({type:"CHANGE_CHAT_ROOM", payload:null})
    }
  },[props.chatRoom])

  useEffect(()=>{
    setTimeout(()=>{
      let y = document.getElementById("hey").offsetHeight;
      messagesEndRef.current.scrollToBottom(y);
    },500)
  },[props.currentChatRoom])

  const getAll=async(email)=>{
    await props.getAllChatRoom(email)
    await props.getAllGroups(email)
  }

  const getChatRoom = async()=>{
    let channelObj={
      sender: props.authData.email,
      receiver: props.location.state.email
    }
    let encry= encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(channelObj), 'remembermesecret$#').toString()) 
    //CryptoJS.AES.encrypt(JSON.stringify(loginState), 'remembermesecret$#').toString()
    let reqBody= {
      sender: props.authData.email,
      receiver: props.location.state.email,
      channelObj:Math.random().toString(36).substring(7) + Math.random().toString(36).substring(7),
      channelUser:channelObj
    }
    await props.getChatRoom(reqBody)
    await props.getAllChatRoom(props.authData.email)
    await props.getAllGroups(props.authData.email)
  }
  return (
    <IonContent id="hey"  ref={messagesEndRef} scrollEvents={true}>
      <div className="sportTalks" >
      {/* {props.currentChatRoom ? <ChatConversation></ChatConversation> : null} */}
        {/* <ChatList></ChatList>
        <ChatConversation></ChatConversation> */}
      

    <IonGrid>
      <IonRow>
        
      
        <IonCol size="12" size-md="6">
        {props.errorMsg ? <div className="success-msg">
                <p>{props.errorMsg ? props.errorMsg : props.successMsg}</p>
              </div> : props.successMsg ? <div className="success-msg">
                <p>{props.errorMsg ? props.errorMsg : props.successMsg}</p>
              </div> : null}
          <ChatList getAll={all}></ChatList>
        </IonCol>
        <IonCol size="12" size-md="6">
        {props.currentChatRoom ? <ChatConversation getAll={all}></ChatConversation> : null}
        </IonCol>
      </IonRow>
    </IonGrid>

    </div>

      

    </IonContent>
    
  );
};

const mapStateToProps = (state) => {

  return {
    isLoggedIn: state.authReducer.isLoggedIn,
    errorMsg: state.commonReducer.errorMsg,
    errorData: state.commonReducer.errorData,
    successMsg: state.commonReducer.successMsg,
    loading: state.commonReducer.loading,
    authData: state.authReducer.authData,
    chatRoom:state.chatReducer.chatRoom,
    allChatRoom:state.chatReducer.allChatRoom,
    currentChatRoom: state.chatReducer.currentChatRoom,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getChatRoom, getAllChatRoom,getAllGroups },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SportTalks)
);
