import React, { useState, useEffect } from "react";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonCardTitle,
  IonIcon,
  IonMenuButton,
  IonModal,
  showModal,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonInput,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonImg,
  IonItemGroup,
  IonItemDivider,
  IonLabel,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonAlert,
} from "@ionic/react";
import MyForm from "./myForms";
import Profile from "./profile";
import ProfileImg from "../../images/client-dummy.png";
import "./myProfile.css";

import { pencilSharp, close, trash } from "ionicons/icons";
import { Plugins, CameraResultType, CameraOptions } from "@capacitor/core";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

const { Camera } = Plugins;

const MyProfile = (props) => {
  const { history } = props;

  const [user, setUser] = useState({});
  const [photo, setPhoto] = useState(null);
  const [editProfile, setEditProfile] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  let imageObject;

  React.useEffect(() => {
    let getUser = localStorage.getItem("userData");
    getUser = JSON.parse(getUser);
    setUser(getUser);
    setPhoto({ dataUrl: getUser?.imagename ? getUser.imagename : null });

    defineCustomElements(window);
  }, []);

  async function takePicture() {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.DataUrl
    });

    var imageUrl = image.dataUrl;

    if (imageUrl.toLowerCase().includes("image/png") || imageUrl.toLowerCase().includes("image/jpeg") || imageUrl.toLowerCase().includes("image/jpg")) {
      setPhoto(image);
      imageObject = image;
      setErrorMessage(false);
    } else {
      setErrorMessage(true);
    }
  }

  const clearImage=(image)=>{
    setPhoto(null)
  }
  return (
    <IonContent>
      <div className="container">
        <div class="profile-start">
          <div className="profile-info">
            <IonCardHeader>
              <IonCardTitle className="inner-page-heading">
                {history?.location?.state?.id ? (
                  <span>User Profile</span>
                ) : (
                  <span>My Profile</span>
                )}
              </IonCardTitle>
              {props?.errorMsg ? (
                <div className="profile-error-msg">
                  <p>{props.errorMsg}</p>
                </div>
              ) : props?.successMsg ? (
                <div className="profile-success-msg">
                  <p>{props.successMsg}</p>
                </div>
              ) : null}
            </IonCardHeader>
            <IonCard>
              <IonGrid>
                <IonRow>
                  <IonCol size="12" size-md="5">
                    <div className="dashboard-profile-new">
                      <div class="dashboard-profile-img">
                        {history?.location?.state?.id ? (
                          props.userProfileData?.imagename ? (
                            <IonImg src={props.userProfileData.imagename} />
                          ) : (
                            <IonImg src={ProfileImg} />
                          )
                        ) : photo?.dataUrl ? (
                          <IonImg src={photo.dataUrl} />
                        ) : (
                          <IonImg src={ProfileImg} />
                        )}
                      </div>
{errorMessage === true ? <div className="errormsg">
                        <p>Only Png, Jpeg, Jpg image file format accepted!</p>
                      </div> : null}
                      
                      {editProfile === true ? (
                        history?.location?.state?.id ? null : (
                          <div className="profile-update-profile">
                            <a
                              className="primary-bg-color edit-btn"
                              onClick={() => takePicture()}
                            >
                              <IonIcon icon={pencilSharp} />
                            </a>
                            <a
                              className="primary-bg-color delete-btn"
                              onClick={() => setShowAlert(true)}
                            >
                              <IonIcon icon={trash} />
                            </a>
                          </div>
                        )
                      ) : null}
                    </div>
                  </IonCol>
                  <IonCol size="12" size-md="7">
                    <Profile
                      cancelImage={clearImage}
                      imageUrl={photo}
                      setEditProfile={(value) => setEditProfile(value)}
                    ></Profile>
                  </IonCol>
                  <IonCol size="12" size-md="7">
                    {/* <MyForm></MyForm> */}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCard>
          </div>
        </div>
      </div>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        cssClass="profileAlert"
        header={""}
        message={"Are you sure?"}
        buttons={[
          {
            text: "No",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
            },
          },
          {
            text: "Yes",
            handler: () => {
              setPhoto("");
            },
          },
        ]}
      />
    </IonContent>
  );
};

const mapStateToProps = (state) => {
  return {
    errorMsg: state.commonReducer.errorMsg,
    errorData: state.commonReducer.errorData,
    successMsg: state.commonReducer.successMsg,
    userProfileData: state.userReducer.userProfileData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyProfile)
);
