import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Subscriptions from "./subscriptionConsumer";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  'pk_live_51HHZotB0u99Oo266AM5gSO0pjnzcQvpNNNT0WntkkofbgES3xmYFNZ3sRDA3oOh7iCFsByJH1fJrJ4Y4BqUWMPFw00r2PVjalL'
);
//"pk_live_51HHZotB0u99Oo266AM5gSO0pjnzcQvpNNNT0WntkkofbgES3xmYFNZ3sRDA3oOh7iCFsByJH1fJrJ4Y4BqUWMPFw00r2PVjalL"
//pk_test_51HHZotB0u99Oo266Rz2MZtIrh2LeYVs9uTmM5uc2FpOe8MgcdG8B0BHwzjrwnYPj32R0LMX0pTTYI0kFyaxJygPR006QHswo0L
function Stripe(props) {
  return (
    <Elements stripe={stripePromise}>
      <Subscriptions stripe={stripePromise} />
    </Elements>
  );
}


export default (Stripe);
