import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonList, IonItem, IonInput, IonButton, IonLabel, IonGrid, IonRow, IonCol, IonCard, IonCardHeader, IonCardContent, IonImg } from '@ionic/react';
import React, { useEffect } from 'react';
import Target from "../../../images/dash-target.svg";
import { getPlayerCurrentGoals } from "../../../redux/actions/prsAction";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import moment from "moment";
const CurrentGoals = (props) => {

  const dispatch = useDispatch();
  const [state, setState] = React.useState({})

  async function getCurrentDate(separator = "-") {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`
      }${separator}${date < 10 ? `0${date}` : `${date}`}`;
  }
  async function getSevenDaysBefore() {
    let date = await new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    let sevenDaysBefore = await date.toISOString().split("T")[0];
    return sevenDaysBefore;
  }
  async function componentDidMount() {
    const todaysDate = await getCurrentDate();
    const endDate = await getSevenDaysBefore();
    setState({ endDate, todaysDate })
    await dispatch(await getPlayerCurrentGoals(todaysDate, endDate))
  }

  useEffect(() => {
    componentDidMount()
  }, []);

  return (
    <IonCard class="dashboard-card">
      <IonCardHeader class="dashboard-card-head">
        <h3>CURRENT GOALS</h3>
        <a title="Edit Goals">
          <div class="dashboard-card-icon">
            <div class="dashboard-card-icon-inner">
              <img src={Target} />
            </div>
          </div>
        </a>
      </IonCardHeader>
      <IonCardContent class="dashboard-card-body">
        <div class="dash-table">
          <div class="dashtable-responsive">

            <table class="table">
              <thead>
                <tr>
                  <td>
                    <p>{moment(state.endDate).format(" MMMM Do ,YYYY")} - {moment(state.todaysDate).format("dddd, MMMM Do ,YYYY")}</p>
                  </td>
                </tr>
              </thead>
              <tbody>

                {props?.currentGoals?.length > 0 ?
                  props?.currentGoals?.map((data, i) => {
                    return (<tr className="tableCol">
                      <td><p style={{
                        display: "flex",
                        alignItems: "center"
                      }}><span class="logls-round"></span>{data.answer.length < 100 ? data.answer : data.answer.substring(0, 100) + "....."}</p></td>
                    </tr>)
                  }) : props.loading === true ? <p style={{ padding: 10 }}>Loading...</p> : <p style={{ padding: 10 }}>You don’t have any current goals.
                </p>}

              </tbody>
            </table>


          </div>
        </div>
      </IonCardContent>
    </IonCard>
  );
};


const mapStateToProps = (state) => {
  return {
    currentGoals: state.prsReducer.currentGoals,
  };
};

export default connect(mapStateToProps)(CurrentGoals);