import {
  IonContent,
  IonImg,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon
} from "@ionic/react";
import React, { useRef } from "react";
import { loginAction, isUser, googleLogin, getTeamDetail } from "../redux/actions/authAction";
import { bindActionCreators } from "redux";
import "./landing.scss";
import { withRouter } from "react-router-dom";
import { ClearData } from "../redux/actions/commonAction";
import { connect } from "react-redux";
import { mailOutline, logoTwitter, logoInstagram, logoFacebook } from "ionicons/icons";
import Tennis from "../images/tennis.png";
import MainLogo from "../images/main-logo.png";
import HannahRosenfield from "../images/hannah-rosenfield.png";
import CheckIcon from "../images/check-icon.png";
import Feature from "../images/feature.png";
import Contact from "../images/contact.png";
import Price from "../images/price.png";
import { menuController } from "@ionic/core";
const LandingPage = (props) => {
  const purpose = useRef(null)
  const home = useRef(null)
  const people = useRef(null)
  const product = useRef(null)
  const contact = useRef(null)
  const content = useRef(null)
  React.useEffect(() => {
    menuController.enable(false)
  }, [])
  const executeScroll = (refEle, e) => {
    e.preventDefault()
    refEle.current.scrollIntoView()
    refEle.current.classList.add('active')
  }


  const handleScroll = (event) => {
    onActiveAction("home");
    onActiveAction("purpose");
    onActiveAction("people");
    onActiveAction("product");
    onActiveAction("contact");
  };
  const onActiveAction = (id) => {
    const distanceFromTop =
      window.pageYOffset +
      document.getElementById(id).getBoundingClientRect().top;
    let distance = (distanceFromTop / window.innerHeight) * 100;

    if (distance < 50 && distance > -20) {
      if (document.getElementById(id + "_link") !== null) {
        var elems = document.querySelectorAll(".active");

        [].forEach.call(elems, function (el) {
          el.classList.remove("active");
        });
        document.getElementById(id + "_link").classList.add("active");
      }
    }
    // else if (distance < 50) {
    // document.getElementById(id+'_link').classList.remove('active')
    // }
  };

  return (
    <IonContent className="content-landing" ref={content} scrollEvents={true} onIonScroll={(e) => handleScroll(e)}>
      <div class="background-theme">
        <ul class="social-side-link">
          <a href="https://www.facebook.com/sportaltennis1/">
            <li>
              <span>
                {/* <i class="fab fa-facebook-f"></i> */}
                <IonIcon icon={logoFacebook} />
              </span>
            </li>
          </a>
          <a href="https://twitter.com/SportalTennis1">
            <li>
              <span>
                <i class="fab fa-twitter"></i>
                <IonIcon icon={logoTwitter} />
              </span>
            </li>
          </a>
          <a href="https://www.instagram.com/sportaltennis1/">
            <li>
              <span>
                <i class="fab fa-instagram"></i>
                <IonIcon icon={logoInstagram} />
              </span>
            </li>
          </a>
        </ul>

        {/* Social link ends */}
        {/* Header */}
        <header class="header">
          <div class="container">
            <div class="header-desc">
              <div class="header-ques">
                <h5>Have any questions?</h5>
                <a href="mailto:welcome@sportaltennis.com">
                  <p>
                    <span>
                      {/* <i class="fas fa-envelope"></i> */}
                      <IonIcon icon={mailOutline} />
                    </span>
                    welcome@sportaltennis.com
                  </p>
                </a>
              </div>
              <div class="header-img">
                <a href="#home">
                  {/* <img src="./assets/Images/main-logo.png" alt="Logo" /> */}
                  <IonImg src={MainLogo} alt="Logo" />
                </a>
              </div>
              {props.authData?.fullname ? <button class="blue-btn-base" onClick={(e) => { props.authData.role === 1 ? props.history.push("/page/coach-dashboard") : props.authData.role === 2 ? props.history.push("/page/coach-dashboard") : props.history.push("/page/dashboard") }}>{props.authData.fullname}</button> : <button class="blue-btn-base" onClick={(e) => { props.history.push("/login") }}>Log In/Sign Up</button>}

            </div>
          </div>
        </header>
        {/* Header */}

        <div class="container" >
          <div class="themeMain">
            {/* Sidebar */}
            <div class="sidebar">
              <div class="sidebar-desc" id="menu-center">
                <ul>
                  <li>
                    <a onClick={(e) => executeScroll(home, e)} className="active" id="home_link">
                      <p>Home</p>
                    </a>
                  </li>
                  <li>
                    <a onClick={(e) => executeScroll(purpose, e)} id="purpose_link">
                      <p>Inspiration</p>
                    </a>
                  </li>
                  <li>
                    <a onClick={(e) => executeScroll(people, e)} id="people_link">
                      <p>Features</p>
                    </a>
                  </li>
                  <li>
                    <a onClick={(e) => executeScroll(product, e)} id="product_link">
                      <p>Pricing</p>
                    </a>
                  </li>
                  <li>
                    <a onClick={(e) => executeScroll(contact, e)} id="contact_link">
                      <p>Get in Touch</p>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* Sidebar */}

            <div class="themeMain-wapper">
              <div class="container-fluid">
                {/* sections */}

                <section class="section-first" id="home" ref={home} >
                  <div class="founder-desc">
                    <h2>Step into simplicity.</h2>
                    <p>Reimagine the way you manage your team of athletes.</p>
                    <a onClick={(e) => { props.history.push("/signup") }}><button class="blue-btn">Get Started Now!</button></a>
                  </div>
                  <div class="mobile-background">
                    {/* <img src="./assets/Images/tennis.png" alt="bg" /> */}
                    <IonImg src={Tennis} alt="bg" />
                  </div>
                </section>

                {/* section product */}
                <section class="purposeSection" id="purpose" ref={purpose}>
                  <h2>Inspired by Coaches.</h2>
                  <div>
                    <p>
                      My mom was my very first coach and throughout my childhood I
                      watched her teach and inspire young players to be the best
                      version of themselves.</p>
                    <p>
                      With the help and care of her and some very special coaches
                      I managed to achieve my goal of playing college tennis where
                      I had an amazing experience on the university tennis team. I
                      learned that coaches don’t only teach their respective
                      sport, but they are most importantly mentors, friends,
                      allies, and role models. They instill values and lessons
                      that athletes will carry with them for their entire lives.</p>
                    <p>
                      As I managed the ups and downs of matches and a tough
                      academic schedule, I felt convinced that there was a more
                      efficient way a team could be managed…a platform where the
                      resources that a coach and player need could be streamlined
                      and integrated into a “one-stop shop”, while also enabling
                      coaches to have a holistic insight into their players’ goals,
                      health, and wellness.</p>
                    <p>
                      In my junior year I researched and reached out to some
                      amazing coaches and mentors who kindly gave me their time,
                      feedback, and insight into my vision.</p>
                    <div class="sign-desc">
                      <p>With their inspiration, Sportal Tennis was born.</p>
                      <div class="sign-img">
                        {/* <img src="./assets/Images/hannah-rosenfield.png" /> */}
                        <IonImg src={HannahRosenfield} alt="hannah-rosenfield" />
                      </div>
                    </div>
                  </div>
                </section>

                <section class="peopleSection" id="people" ref={people}>

                  <IonGrid>
                    <IonRow>
                      <IonCol size="12" size-lg="6">
                        <div class="productSection-heading">
                          <h2>Features</h2>
                          <ul>
                            <li>
                              <span>
                                {/* <img src="assets/Images/check-icon.png" /> */}
                                <IonImg src={CheckIcon} alt="check-icon" />
                              </span>
                              <p>Web and mobile application. (iOS and Android)</p>
                            </li>
                            <li>
                              <span>
                                {/* <img src="assets/Images/check-icon.png" /> */}
                                <IonImg src={CheckIcon} alt="check-icon" />
                              </span>
                              <p>Easy sign up process.</p>
                            </li>
                            <li>
                              <span>
                                {/* <img src="assets/Images/check-icon.png" /> */}
                                <IonImg src={CheckIcon} alt="check-icon" />
                              </span>
                              <p>
                                Weekly check-ins, goal setting, and match
                                reflections.</p>
                            </li>
                            <li>
                              <span>
                                {/* <img src="assets/Images/check-icon.png" /> */}
                                <IonImg src={CheckIcon} alt="check-icon" />
                              </span>
                              <p><i>Sport-Talk</i> Communication System.</p>
                            </li>
                            <li>
                              <span>
                                {/* <img src="assets/Images/check-icon.png" /> */}
                                <IonImg src={CheckIcon} alt="check-icon" />
                              </span>
                              <p>Built-in Calendar.</p>
                            </li>
                            <li>
                              <span>
                                {/* <img src="assets/Images/check-icon.png" /> */}
                                <IonImg src={CheckIcon} alt="check-icon" />
                              </span>
                              <p>Alumni connections.</p>
                            </li>
                            <li>
                              <span>
                                {/* <img src="assets/Images/check-icon.png" /> */}
                                <IonImg src={CheckIcon} alt="check-icon" />
                              </span>
                              <p>Zoom and Playsight Integration.</p>
                            </li>
                            <li>
                              <span>
                                {/* <img src="assets/Images/check-icon.png" /> */}
                                <IonImg src={CheckIcon} alt="check-icon" />
                              </span>
                              <p>Easy to use and less than $7.00 per week .</p>
                            </li>
                          </ul>
                        </div>
                      </IonCol>
                      <IonCol size="12" size-lg="6">
                        <div class="productSection-img">
                          {/* <img src="assets/Images/feature.png" alt="" /> */}
                          <IonImg src={Feature} alt="feature" />
                        </div>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </section>

                <section class="productSection" id="product" ref={product}>
                  <IonGrid>
                    <IonRow class="row">
                      <IonCol size="12" size-lg="6">
                        <div class="productSection-heading">
                          <h2>Pricing</h2>
                          <h5>Our pricing is simple. $349 for 12 months.</h5>
                          <p>
                            If you’re not satisfied with the quality of Sportal Tennis, contact us for a full refund within thirty days.</p>
                          <a >
                            <button class="blue-btn" onClick={(e) => { props.history.push("/signup") }}>Get Started Now!</button>
                          </a>
                        </div>
                      </IonCol>
                      <IonCol size="12" size-lg="6">
                        <div class="productSection-img">
                          {/* <img src="assets/Images/price.png" alt="" /> */}
                          <IonImg src={Price} alt="price" />
                        </div>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </section>

                <section class="productSection" id="contact" ref={contact}>
                  <IonGrid>
                    <IonRow class="row">
                      <IonCol size="12" size-lg="4">
                        <div class="productSection-img">
                          {/* <img src="assets/Images/contact.png" alt="" /> */}
                          <IonImg src={Contact} alt="contact" />
                        </div>
                      </IonCol>
                      <IonCol size="12" size-lg="8">
                        <div class="productSection-heading">
                          <h2 class="heading-unique">Get in Touch</h2>
                          <p>We would love to hear from you!</p>
                          <div class="email-touch">
                            <div>
                              <p>Email</p>
                              <span>welcome@sportaltennis.com</span>
                            </div>
                            <div>
                              <p>Phone</p>
                              <span>+407-494-2332</span>
                            </div>
                          </div>
                        </div>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </section>

              </div>
            </div>

          </div>
        </div>
      </div>
    </IonContent>
  );
};

const mapStateToProps = (state) => {
  return {
    isRegistered: state.authReducer.isRegistered,
    isLoggedIn: state.authReducer.isLoggedIn,
    userToken: state.authReducer.userToken,
    errorMsg: state.commonReducer.errorMsg,
    errorData: state.commonReducer.errorData,
    authData: state.authReducer.authData,
    successMsg: state.commonReducer.successMsg,
    loading: state.commonReducer.loading,
    userFcmToken: state.authReducer.userFcmToken
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ loginAction, isUser, ClearData, googleLogin, getTeamDetail }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LandingPage));
