import axios from "axios";
import { handleError, handleSuccess } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export async function getStripeProducts() {
  const productRes = await axios({
    method: "get",
    url: `https://api.stripe.com/v1/products`,
    headers: {
      'Authorization': `Bearer ${process.env.REACT_APP_GET_STRIPE_CLIENT_KEY}`
    }
  });
  const priceRes = await axios({
    method: "get",
    url: `https://api.stripe.com/v1/prices`,
    headers: {
      'Authorization': `Bearer ${process.env.REACT_APP_GET_STRIPE_CLIENT_KEY}`
    }
  });
  const newObj = [];
  for (const product of productRes.data.data) {
    if (product.active) {
      const matchingPrice = priceRes.data.data.find((pr) => pr.product === product.id);
      newObj.push({
        ...product,
        ...matchingPrice
      });
    }
  }
  return (newObj);
}

export async function getStripeAction(priceId, email) {
  try {
    const userData = JSON.parse(localStorage.getItem("userData"));
    console.debug('making purchase...');
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/subscription/stripe`,
      headers: {
        authorization: localStorage.getItem("user"),
      },
      data: {
        customerId: userData.id,
        priceId: priceId,
        email: email
      },
    });
    console.debug('purchase successful')
    return res.data;
  } catch (error) {
    console.log('error: ', error)
  }
}

export function changeUserPaymentStatus(id) {
  return async function (dispatch) {
    try {
      // dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/subscription/changeUserPaymentStatus`,
        headers: {
          authorization: localStorage.getItem("user"),
        },
        data: { id: id },
      });

      return res.data;
      // dispatch({
      //   type: "REACT_APP_GET_STRIPE_CLIENT_KEY",
      //   payload: { data: res.data.client_secret },
      // });
    } catch (error) {
      dispatch(handleError(error));
    }
  };
}

export function savePaymentResponse(paymentIntent, amount) {
  return async function (dispatch) {
    try {
      // dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/subscription/savePayment`,
        headers: {
          authorization: localStorage.getItem("user"),
        },
        data: {
          paymentIntent: paymentIntent,
          response: JSON.stringify(paymentIntent),
          c_id: JSON.parse(localStorage.getItem("userData")).id,
          amount,
        },
      });
      return res.data;
      // dispatch({
      //   type: "REACT_APP_GET_STRIPE_CLIENT_KEY",
      //   payload: { data: res.data.client_secret },
      // });
    } catch (error) {
      dispatch(handleError(error));
    }
  };
}
