import axios from "axios";
import { handleError, handleSuccess } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export function getContactList(category,limitOffset) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });

      // if(clearState === true){
      //   // await clearContactList()
      // await  dispatch({
      //     type: "CONTACT_LIST",
      //     payload: { clear: true },
      //   });
      // }
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/contact?category=${category}&adminId=${
          JSON.parse(localStorage.getItem("userData")).id
        }&limit=${limitOffset.limit}&offset=${limitOffset.offset}`,
        headers: {
          authorization: localStorage.getItem("user"),
        },
      });
      dispatch({
        type: "CONTACT_LIST",
        payload: { data: []},
        clear: false
      });

      dispatch({
        type: "CONTACT_LIST",
        payload: { data: res?.data?.data?.data},
        clear: false
      });
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(error));
    }
  };
}

export const addContact = (values) => async (dispatch) => {
  try {
    values.adminId = await JSON.parse(localStorage.getItem("userData")).id;
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/contact`,
      headers: {
        authorization: localStorage.getItem("user"),
      },
      data: values,
    });
    if (res.data.data.isRegistered === true) {
      dispatch({ type: "ADD_CONTACT", payload: res.data.data });
      dispatch({ type: "LOADING_COMPLETED" });
    } else {
      dispatch({
        type: "ERROR",
        payload: { error: "Error!" }
      });
      dispatch({ type: "LOADING_FAILURE" });
    }
    // dispatch({ type: "LOADING_COMPLETED" });
    //  dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch(handleError(error));
    dispatch({ type: "LOADING_FAILURE" });
    // dispatch({ type: "LOADING_FAILURE" });
    if (error.response) {
      dispatch({
        type: "ERROR",
        payload: { error: error.response.data },
      });
      // toast.error(error.response.data.error);
    } else {
      dispatch({
        type: "ERROR",
        payload: { error: error.message },
      });
    }
  }
};




export function searchContactList(category,searchKeyword) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/contact/search?limit=5&start=0&search=${searchKeyword}&category=${category}&adminId=${
          JSON.parse(localStorage.getItem("userData")).id
        }`,
        headers: {
          authorization: localStorage.getItem("user"),
        },
      });
      dispatch({
        type: "CONTACT_LIST",
        payload: { data: res?.data?.data},
        clear: false,
        search: true
      });
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(error));
    }
  };
}




export function clearContactList() {
  return async function (dispatch) {
      dispatch({
        type: "CONTACT_LIST",
        payload: { clear: true },
      });
  
} 
}
