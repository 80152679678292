import React, { useState } from "react";
import {
  IonIcon,
  IonInput,
  IonButton,
  IonLabel,
  IonModal,
  IonDatetime,
  IonAlert,
  IonGrid,
  IonRow,
  IonCol,
  IonSpinner,
} from "@ionic/react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { IonContent } from "@ionic/react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar as calendarIonic } from "@ionic-native/calendar";
import {
  addEvent,
  getEventList,
  deleteEvent,
  editEvent,
} from "../../redux/actions/zoomAction";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { close } from "ionicons/icons";
import moment from "moment";
import { useDispatch } from "react-redux";
import { getPlatforms } from "@ionic/react";
import { Diagnostic } from '@ionic-native/diagnostic'
import "../calendar/calendar.css";

const CalendarClass = (props) => {
  const localizer = momentLocalizer(moment);
  const [list, setList] = useState([]);
  const [role, setRole] = useState();
  const [showModal, setShowModal] = useState(false);
  const [exportedCalender, setExportedCalender] = useState(false);
  const [message, setMessage]=useState("Calendar Exported")
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [title, setTitle] = useState("Add");
  const [isWeb, setWeb] = useState(false);
  const [formData, setFormValue] = useState({
    name: "",
    description: "",
    startDate: "",
    endDate: "",
    venue: "",
  });
  const [errorData, setErrorData] = useState({
    name: [],
    description: [],
    startDate: [],
    endDate: [],
    venue: [],
  });

  const [selectedEvent, setSelectedEvent] = useState(null);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (props?.location?.state?.event) {
      setSelectedEvent(props.location.state.event);
      setShowDetailModal(true);
    }
    getEvents();
    let webb = getPlatforms().some(
      (r) => ["desktop", "mobileweb"].indexOf(r) >= 0
    );
    if (webb) {
      setWeb(false);
    } else {
      setWeb(true);
    }
  }, []);

  React.useEffect(() => {
    if (props?.location?.state?.event) {
      setSelectedEvent(props.location.state.event);
      setShowDetailModal(true);
    }
  }, [props?.location?.state]);

  React.useEffect(() => {
    if (props?.location?.state?.event) {
      setSelectedEvent(props.location.state.event);
      setShowDetailModal(true);
    }
  }, [props?.location?.state]);
  React.useEffect(() => {
    let events = props.eventList;
    let dummyArray = [];
    for (let i = 0; i < events.length; i++) {
      dummyArray.push({
        id: events[i].id,
        title: events[i].name,
        start: new Date(moment.utc(events[i].startDate).local().format()),
        end: new Date(moment.utc(events[i].endDate).local().format()),
        // allDay: true,
        desc: events[i].description,
        venue: events[i].venue,
      });
    }
    setList(dummyArray);
  }, [props.eventList]);

  const eventStyleGetter = function () {
    var style = {
      background: "#762b87",
      borderRadius: "10px",
      opacity: 1,
      color: "#fff",
      border: "0px",
      display: "block",
      padding: "2px 10px",
      textAlign: "center",
      margin: "3px",
      outline: "none",
    };
    return {
      style: style,
    };
  };
  const handleChange = (formField, event) => {
    let test = formData;
    test[formField] = event.target.value;
    setFormValue(test);
  };

  const validateForm = () => {
    let isFormValid = true;
    let testError = {
      name: [],
      description: [],
      startDate: [],
      endDate: [],
      venue: [],
    };
    if (formData.name.trim().length === 0) {
      isFormValid = false;
      testError.name = ["This field is required."];
    }
    if (
      formData.name.trim().length > 0 &&
      (formData.name.trim().length < 1 || formData.name.trim().length > 50)
    ) {
      isFormValid = false;
      testError.name = ["name length must be between 1 to 50 characters"];
    }
    // if (formData.description.trim().length === 0) {
    //   isFormValid = false;
    //   testError.description = ["This field is required."];
    // }
    // if (
    //   formData.description.trim().length > 0 &&
    //   (formData.description.trim().length < 6 ||
    //     formData.description.trim().length > 255)
    // ) {
    //   isFormValid = false;
    //   testError.description = [
    //     "description length must be between 6 to 255 characters",
    //   ];
    // }
    if (formData.startDate.trim().length === 0) {
      isFormValid = false;
      testError.startDate = ["This field is required."];
    }
    if (formData.endDate.trim().length === 0) {
      isFormValid = false;
      testError.endDate = ["This field is required."];
    }
    // if (formData.venue.trim().length === 0) {
    //   isFormValid = false;
    //   testError.venue = ["This field is required."];
    // }
    // if (formData.venue.trim().length > 0 && formData.venue.trim().length > 50) {
    //   isFormValid = false;
    //   testError.venue = ["venue must be must be between 6 to 50 characters"];
    // }
    let m = formData.endDate;
    let b = moment.duration(moment(m).diff(moment(formData.startDate)));
    var hours = parseInt(b.asSeconds());
    if (moment(formData.endDate).diff(formData.startDate, "days") < 0) {
      isFormValid = false;
      testError.endDate = ["End date cannot be before the start date."];
    }
    if (hours <= 0) {
      isFormValid = false;
      testError.endDate = ["End date cannot be before the start date."];
    }

    setErrorData(testError);
    return isFormValid;
  };

  const changePassword = async (event) => {
    if (validateForm()) {
      formData["user_id"] = props.authData.id;
      formData["startDate"] = moment.utc(moment(formData.startDate)).format();
      formData["endDate"] = moment.utc(moment(formData.endDate)).format();
      await props.addEvent(formData).then((data) => {
        if (data.data.isRegistered) {
          setShowModal(false);
          setSuccessMessage(true);
          props.getEventList(moment.utc(moment(formData.startDate)).format());
          setTimeout(() => {
            setSuccessMessage(false);
          }, 3000);
        }
      });
    }
  };
  const createCalendar = async() => {
    await calendarIonic.hasReadWritePermission().then(
      async(data)=>{
        if(data === false){
          // exportEvent()
         await requestPermission().then(
            data=>{
              if(data === true){
                exportEvent()
              }else{
                setMessage("Event can't be exported as you didn't granted permisson")
                setExportedCalender(true)
                setTimeout(()=>{setExportedCalender(false)},3000)
              }
            },err=>{
            }
          )
          
        }else{
          exportEvent()
        }
      }
    )
  };

  const requestPermission =()=>{
   return new Promise((resolve,reject)=>{
      Diagnostic.requestCalendarAuthorization(data=>{
        if(data === "DENIED_ONCE"){
          resolve(false) 
        }else if (data === "GRANTED"){
          resolve(true) 
        }else{
          resolve(false) 
        }
      },err=>{
      })
    })
     
  }

  const exportEvent= async()=>{
    let isSuccess= false
    await calendarIonic.createCalendar("myCalendar").then(
      (message) => {
        isSuccess= true
     },
     (error) => {
       isSuccess= false
     }
   );
   
   for(let item of list){
     await calendarIonic.createEvent(item.title, item.venue, item.desc, new Date(item.start), new Date(item.end)).then(
        (message) => {
         //  message = "Export Successfully"
          
        },
        (error) => {
        }
      );
   }

   setTimeout(()=>{
     setExportedCalender(false)
   },3000)
   if(isSuccess){
    setMessage("Calendar Exported")
   }
   else{
     setMessage("Event can't be exported as you didn't granted permisson")
   }
   setExportedCalender(true);
  }

  const getEvents = async () => {
    let userData = localStorage.getItem("userData");
    userData = JSON.parse(userData);
    setRole(userData.role);
    await props.getEventList(new Date());
  };

  const selectEvent = (event) => {
    setSelectedEvent(event);
    setShowDetailModal(true);
  };

  const formatTime = (time) => {
    return moment(time).format("DD-MMM-YYYY hh:mm A");
  };

  const selectSlot = (slot) => {
    if (role === 1) {
      let diff = moment(slot?.start).diff(new Date(), "days");
      if (diff >= 0) {
        setTitle("Add");
        let test = {
          name: "",
          description: "",
          startDate: "",
          endDate: "",
          venue: "",
        };
        test.startDate = slot?.start.toISOString();
        setFormValue(test);
        setShowDetailModal(false);
        setShowModal(true);
      } else {
        dispatch({
          type: "ERROR",
          payload: { errorMsg: "You cannot add events to past date!" },
        });
        setShowDetailModal(false);
        setTimeout(() => {
          dispatch({
            type: "CLEARDATA",
            payload: {},
          });
        }, 3000);
      }
    }
  };

  const addPopup = () => {
    setFormValue({
      name: "",
      description: "",
      startDate: "",
      endDate: "",
      venue: "",
    });
    setTitle("Add");
    setShowModal(true);
  };

  const deleteSelected = async () => {
    await props.deleteEvent(selectedEvent.id);
    setShowDetailModal(false);
    let date = selectedEvent.start;
    await props.getEventList(moment.utc(moment(date)).format());
    setTimeout(() => {
      dispatch({
        type: "CLEARDATA",
        payload: {},
      });
    }, 3000);
  };

  const editSelected = async () => {
    setTitle("Edit");
    let diff = moment(selectedEvent.end).diff(new Date(), "days");

    if (diff >= 0) {
      setShowDetailModal(false);
      let test = formData;
      test = {
        name: selectedEvent.title,
        description: selectedEvent.desc,
        startDate: moment(selectedEvent.start).format(),
        endDate: moment(selectedEvent.end).format(),
        venue: selectedEvent.venue,
      };
      setFormValue(test);
      setShowModal(true);
    } else {
      dispatch({
        type: "ERROR",
        payload: { errorMsg: "You cannot edit past events!" },
      });
      setShowDetailModal(false);
      setTimeout(() => {
        dispatch({
          type: "CLEARDATA",
          payload: {},
        });
      }, 3000);
    }
  };

  const editOnClick = async () => {
    if (validateForm()) {
      formData["startDate"] = moment.utc(moment(formData.startDate)).format();
      formData["endDate"] = moment.utc(moment(formData.endDate)).format();
      await props.editEvent(selectedEvent.id, formData);
      await setShowModal(false);
      let date = selectedEvent.start;
      await props.getEventList(moment.utc(moment(date)).format());
      setTimeout(() => {
        dispatch({
          type: "CLEARDATA",
          payload: {},
        });
      }, 3000);
    }
  };

  const onMonth = (date, view) => {
    if (view === "month") {
      props.getEventList(moment.utc(moment(date)).format());
    }
  };
  return (
    <IonContent
    scrollEvents={true}
    >
    <div className="container">
      <div className="calendar-main">

      <div className="calendar-header">

      <IonGrid>
      <IonRow>
        <IonCol size="6">
          <div className="calendar-header-heading">
            {/* <h2>Calendar</h2> */}
          </div>
        </IonCol>
        <IonCol size="6">
          <div className="calendar-header-btn">
          {isWeb ? <button
        className="button-tab"
        onClick={(event) => {
          createCalendar();
        }}
      >
        Export
      </button> : null}
      
      {role == 1 ? <button
        className="button-tab"
        onClick={(event) => {
          addPopup()
        }}
      >
        Add Event
      </button>: null}


      {successMessage === true ? (
        <div className="success-msg">
          <p>Event Added.</p>
        </div>
      ) : null}
      {exportedCalender === true ? (
        <div className="success-msg">
          <p>{message}</p>
        </div>
      ) : null}

                    {props?.errorMsg ? (
                      <div className="errormsg">
                        <p>{props.errorMsg}</p>
                      </div>
                    ) : props?.successMsg ? (
                      <div className="success-msg">
                        <p>{props.successMsg}</p>
                      </div>
                    ) : null}
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>

          <Calendar
            events={list}
            localizer={localizer}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500 }}
            selectable
            onSelectSlot={(event) => {
              selectSlot(event);
            }}
            onSelectEvent={(event) => {
              selectEvent(event);
            }}
            onNavigate={(date, view) => {
              onMonth(date, view);
            }}
            eventPropGetter={eventStyleGetter}
          />
        </div>

        <IonModal
          isOpen={showModal}
          cssClass="calendar-addevent-modal"
          backdropDismiss={false}
          swipeToClose={true}
          onDidDismiss={() => setShowModal(false)}
        >
          <div class="change-password-popup">
            <div class="popup-header">
              <h5>{title} Event</h5>
              <div className="errormsg">
                <p>{props?.errorMsg}</p>
              </div>
              <IonButton onClick={() => setShowModal(false)}>
                <IonIcon icon={close} />
              </IonButton>
              {props?.errorMsg ? (
                <div className="errormsg">
                  <p>{props.errorMsg}</p>
                </div>
              ) : props?.successMsg ? (
                <div className="successmsg">
                  <p>{props.successMsg}</p>
                </div>
              ) : null}
            </div>
            <form>
              <div className="form-group">
                <IonLabel>
                  Name<sup>*</sup>
                </IonLabel>
                <div className="password-input">
                  <IonInput
                    type="text"
                    name="name"
                    value={formData.name}
                    onIonChange={(event) => {
                      handleChange("name", event);
                    }}
                  ></IonInput>
                  <p class="errormsg">
                    {" "}
                    {errorData?.name && errorData.name[0]}
                  </p>
                </div>
              </div>

              <div className="form-group">
                <IonLabel>Description</IonLabel>
                <div className="password-input">
                  <IonInput
                    type="text"
                    name="description"
                    value={formData.description}
                    onIonChange={(event) => {
                      handleChange("description", event);
                    }}
                  ></IonInput>
                  {errorData.description[0] ? (
                    <p class="errormsg">{errorData.description[0]}</p>
                  ) : null}
                </div>
              </div>

              <div className="form-group">
                <IonLabel>Venue</IonLabel>
                <div className="password-input">
                  <IonInput
                    type="text"
                    name="venue"
                    value={formData.venue}
                    onIonChange={(event) => {
                      handleChange("venue", event);
                    }}
                  ></IonInput>
                  {errorData.venue[0] ? (
                    <p class="errormsg">{errorData.venue[0]}</p>
                  ) : null}
                </div>
              </div>

              <div className="form-group">
                <IonLabel>
                  Start Date<sup>*</sup>
                </IonLabel>
                <div className="password-input">
                  <IonDatetime
                    displayFormat="MM-DD-YYYY hh:mm A"
                    pickerFormat="MMMM DD  YYYY hh:mm A"
                    placeholder="Select Start Date"
                    value={formData.startDate}
                    max={new Date().getFullYear() + 1}
                    onIonChange={(event) => {
                      handleChange("startDate", event);
                    }}
                  ></IonDatetime>
                  {errorData.startDate[0] ? (
                    <p class="errormsg">{errorData.startDate[0]}</p>
                  ) : null}
                </div>
              </div>

              <div className="form-group">
                <IonLabel>
                  End Date<sup>*</sup>
                </IonLabel>
                <div className="password-input">
                  <IonDatetime
                    displayFormat="MM-DD-YYYY hh:mm A"
                    pickerFormat="MMMM DD  YYYY hh:mm A"
                    placeholder="Select End Date"
                    value={formData.endDate}
                    max={new Date().getFullYear() + 1}
                    onIonChange={(event) => {
                      handleChange("endDate", event);
                    }}
                  ></IonDatetime>
                  {errorData.endDate[0] ? (
                    <p class="errormsg">{errorData.endDate[0]}</p>
                  ) : null}
                </div>
              </div>

              <div class="form-group-center">
                {title === "Add" ? (
                  <IonButton
                    onClick={(event) => {
                      changePassword(event);
                    }}
                  >
                    Add Event{" "}
                    {(() => {
                      if (props.loading) {
                        return (
                          <IonSpinner
                            name="crescent"
                            className="spinner-loader"
                          />
                        );
                      }
                    })()}
                  </IonButton>
                ) : (
                  <IonButton
                    onClick={(event) => {
                      editOnClick();
                    }}
                  >
                    Edit Event{" "}
                    {(() => {
                      if (props.loading) {
                        return (
                          <IonSpinner
                            name="crescent"
                            className="spinner-loader"
                          />
                        );
                      }
                    })()}
                  </IonButton>
                )}
              </div>
            </form>
          </div>
        </IonModal>
        <IonModal
          isOpen={showDetailModal}
          cssClass="calendar-viewevent-modal"
          backdropDismiss={false}
          swipeToClose={true}
          onDidDismiss={() => setShowDetailModal(false)}
        >
          <div class="change-password-popup">
            <div class="popup-header">
              <h5> Event</h5>
              <IonButton onClick={() => setShowDetailModal(false)}>
                <IonIcon icon={close} />
              </IonButton>
            </div>

            <div>
              <div className="container-spacing">
                <div class="calendar-viewevent-table">
                  <div class="dashtable-responsive">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th>
                            <p>Title:</p>
                          </th>
                          <td>
                            <p>{selectedEvent?.title ? selectedEvent?.title :selectedEvent?.name }</p>
                            {/* <p>{selectedEvent?.name}</p> */}
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <p>Description:</p>
                          </th>
                          <td>
                            <p>{selectedEvent?.desc ?  selectedEvent?.desc  : selectedEvent?.description}</p>
                            {/* <p>{selectedEvent?.description}</p> */}
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <p>Venue:</p>
                          </th>
                          <td>
                            <p>{selectedEvent?.venue}</p>
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <p>Start Date:</p>
                          </th>
                          <td>
                            <p> {formatTime(selectedEvent?.start)}</p>
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <p>End Date:</p>
                          </th>
                          <td>
                            <p> {formatTime(selectedEvent?.end)}</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* <p><b>Title :</b> {selectedEvent?.title}</p>
              <p><b>Description :</b> {selectedEvent?.desc}</p>
              <p><b>Venue :</b> {selectedEvent?.venue}</p>
              <p><b>Start date :</b> {formatTime(selectedEvent?.start)}</p>
              <p><b>End date :</b> {formatTime(selectedEvent?.end)}</p> */}
                {role === 1 ? (
                  <div className="button-event">
                    <IonButton
                      onClick={(event) => {
                        editSelected();
                      }}
                      class="button-sub-event"
                    >
                      Edit
                    </IonButton>
                    <IonButton
                      onClick={(event) => {
                        setShowAlert(true);
                      }}
                      class="button-sub-event"
                    >
                      Delete
                    </IonButton>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </IonModal>
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          cssClass="my-custom-class"
          header={"Alert"}
          message={"Do you really want to delete this event?"}
          buttons={[
            {
              text: "No",
              role: "cancel",
              cssClass: "secondary",
              handler: (blah) => {},
            },
            {
              text: "Yes",
              handler: () => {
                deleteSelected();
              },
            },
          ]}
        />
      </div>
    </IonContent>
  );
};

const mapStateToProps = (state) => {
  return {
    isRegistered: state.authReducer.isRegistered,
    isLoggedIn: state.authReducer.isLoggedIn,
    userToken: state.authReducer.userToken,
    errorMsg: state.commonReducer.errorMsg,
    errorData: state.commonReducer.errorData,
    authData: state.authReducer.authData,
    successMsg: state.commonReducer.successMsg,
    authData: state.authReducer.authData,
    isEventAdded: state.zoomReducer.isEventAdded,
    eventList: state.zoomReducer.eventList,
    loading: state.commonReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { addEvent, getEventList, deleteEvent, editEvent },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CalendarClass)
);
