export const initialState = {
  isRegistered: "",
  isLoggedIn: "",
  authData: {},
  userToken: null,
  userFcmToken:null,
  checkUserRole: localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData")).role
    : null,
  checkIsPayment: localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData")).is_payment
    : null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SIGNUP":
      return {
        ...state,
        isRegistered: action.payload.data.message,
      };
    // case "LOGIN":
    //   return {
    //     ...state,
    //     isLoggedIn: action.payload.data.message,
    //     authData: action.payload.data.user,
    //   };

    case "LOGIN":
      if (
        action.payload.data.user.token &&
        action.payload.data.user.token != ""
      ) {
        localStorage.setItem("user", action.payload.data.user.token);
        localStorage.setItem(
          "userData",
          JSON.stringify(action.payload.data.user)
        );
        // localStorage.setItem("darkMode",false)
        return {
          ...state,
          userToken: action.payload.data.user.token,
          authData: action.payload.data.user,
          isLoggedIn: true,
          checkUserRole: action.checkUserRole,
        };
      } else {
        return {
          ...state,
          isLoggedIn: false,
        };
      }
    case "CHECK_PAYMENT":
      return {
        ...state,
        checkIsPayment: action.checkIsPayment,
      };

    case "LOGOUT":
      return {
        ...state,
        isRegistered: false,
        isLoggedIn: false,
        authData: {},
        userToken: null,
      };

    case "USERINFO":
      return {
        ...state,
        authData: action.payload.data,
      };

      case "FCMTOKEN":
        return{
          ...state,
          userFcmToken:action.payload
        }

    default:
      return state;
  }
};

export default authReducer;
