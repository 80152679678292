import React from "react";

import {
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonBadge,
  IonPage,
  IonRouterOutlet,
  Tab1,
  Tab2,
  IonContent,
  IonImg,
} from "@ionic/react";
import { calendar, personCircle, map, informationCircle } from "ionicons/icons";
import { Redirect, Route, withRouter, Switch } from "react-router-dom";

import { IonReactRouter } from "@ionic/react-router";
import { apps, flash, send } from "ionicons/icons";
import LikeGrey from "../../images/like-grey.png";
import LikeBlue from "../../images/like-blue.png";
import GoalGrey from "../../images/goal-grey.png";
import GoalBlue from "../../images/goal-blue.png";
import QuestionGrey from "../../images/question-grey.png";
import QuestionBlue from "../../images/question-blue.png";

import "./playerReportingSystem.css";
import WeeklyReport from "./weeklyReport";
import MatchingReport from "./matchReporting";

const Questionnaire = (props) => {
  return (
    <IonContent>
      <div className="questionnaire-inner-body">
        <div className="reporting-heading">
          {/* <h2>Questionnaire</h2> */}
        </div>
        <IonTabs>
          <IonTabBar slot="top">
            <IonTabButton tab="weekly" href="/page/questionnaire/weekly">
              <div className="player-top-tab">
                <div className="tabimg">
                  {/* <IonImg className="question-icona" src={LikeGrey} />
                  <IonImg className="question-iconb" src={LikeBlue} /> */}
                  <IonImg className="question-icon-grey" src={LikeGrey} />
                  <IonImg className="question-icon-blue" src={LikeBlue} />
                </div>
                <div className="tablabel">
                  <IonLabel>Weekly Check-IN</IonLabel>
                </div>
              </div>
            </IonTabButton>

            <IonTabButton tab="match" href="/page/questionnaire/match">
              <div className="player-top-tab">
                <div className="tabimg">
                  {/* <IonImg className="question-icona" src={GoalGrey} />
                  <IonImg className="question-iconb" src={GoalBlue} /> */}
                  <IonImg className="question-icon-grey" src={GoalGrey} />
                  <IonImg className="question-icon-blue" src={GoalBlue} />
                </div>
                <div className="tablabel">
                  <IonLabel>Match report</IonLabel>
                </div>
              </div>
            </IonTabButton>
          </IonTabBar>

          <IonRouterOutlet id="main three">
            <Route
              path="/page/questionnaire/:tab(weekly)"
              component={WeeklyReport}
              exact={true}
            />
            <Route
              path="/page/questionnaire"
              component={WeeklyReport}
              exact={true}
            />
            <Route
              path="/page/questionnaire/:tab(match)"
              component={MatchingReport}
            />
          </IonRouterOutlet>
        </IonTabs>
      </div>
    </IonContent>
  );
};

export default withRouter(Questionnaire);
