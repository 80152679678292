import React, { useEffect, useState } from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
import "./playerReportingSystem";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonImg,
  IonTextarea,
  IonSpinner,
} from "@ionic/react";
import QuestionWhite from "../../images/question-white.png";
import QuestionBlue from "../../images/question-blue.png";
import {
  getPlayerQuestions,
  clearReportingSystem,
  addPlayerAnswers,
  checkPlayerAnswerSubmission,
} from "../../redux/actions/prsAction";
import { IonReactRouter } from "@ionic/react-router";
import { apps, flash, send } from "ionicons/icons";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import "./playerReportingSystem.css";

const MatchingReport = (props) => {
  const dispatch = useDispatch();
  const [formValue, setFormValue] = useState([]);
  const [activeId, setActiveId] = useState(4);
  const [errorMessage, setErrorMessage] = useState(false);
  async function getCurrentDate(separator = "-") {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${year}${separator}${
      month < 10 ? `0${month}` : `${month}`
    }${separator}${date < 10 ? `0${date}` : `${date}`}`;
  }

  async function getSevenDaysBefore() {
    let date = await new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    let sevenDaysBefore = await date.toISOString().split("T")[0];
    return sevenDaysBefore;
  }

  useEffect(() => {
    document.getElementById("Tennis_Match_Report").click();
    componentDidMount();
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearReportingSystem());
    };
  }, []);
  useEffect(() => {
    setFormValue(props.playersQuestions);
  }, [props.playersQuestions]);

  async function componentDidMount() {
    let todaysDate = await getCurrentDate();
    let sevenDaysBefore = await getSevenDaysBefore();
    await dispatch(
      await checkPlayerAnswerSubmission(todaysDate, sevenDaysBefore, activeId)
    );
    // await dispatch(
    //   await getPlayerQuestions(todaysDate, sevenDaysBefore, activeId)
    // );
  }

  const onSelectCategory = async (e, id, value) => {
    e.preventDefault();
    await dispatch(clearReportingSystem());
    setFormValue([]);
    setActiveId(value);
    let i, tablinks;
    tablinks = document.getElementsByClassName("nav-link");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("active", "");
    }
    document.getElementById(id).classList.add("active");

    let todaysDate = await getCurrentDate();
    let sevenDaysBefore = await getSevenDaysBefore();
    await dispatch(
      await checkPlayerAnswerSubmission(todaysDate, sevenDaysBefore, value)
    );
    await dispatch(
      await getPlayerQuestions(todaysDate, sevenDaysBefore, value)
    );
  };
  const handleChange = (e, question_id, couch_question_id, index, question) => {
    const player_id = JSON.parse(localStorage.getItem("userData")).id;

    let val = [...formValue];
    // val[index].answer = e.detail.value;
    // val[index].player_id = player_id;
    let questionIndex = null;
    let counter = 0;

    for (const i of val) {
      if (i.question_id === question_id) {
        questionIndex = counter;
      }
      counter++;
    }

    if (questionIndex !== null) {
      val[questionIndex] = {
        question_id: question_id,
        answer: e.detail.value,
        player_id,
        couch_question_id,
      };
    } else {
      val.push({
        question_id: question_id,
        answer: e.detail.value,
        player_id,
        couch_question_id,
      });
    }

    setFormValue(val);
  };
  const hasWhiteSpace = (s) => {
    return /\s/g.test(s);
  };
  const onSubmit = async () => {
    let finalArray = [];

    const arr = await formValue
      .filter((form) => {
        let elms = document.getElementById(form.question_id);
        elms.style.display = "none";
        if (form.answer !== undefined) {
          form.answer = form.answer.trim();
        }

        var str = form.answer;
        return (
          form.answer === null ||
          form.answer === undefined ||
          form.answer === "" ||
          !str.replace(/\s/g, '').length === true
        );
      })
      .map((data, i) => {
        let elms = document.getElementById(data.question_id);
        elms.style.display = "none";
        return data;
      });
    if (arr.length > 0) {
      arr.map((data, i) => {
          let elms = document.getElementById(data.question_id);
          elms.style.display = "block";
      });
    } else {
      await setErrorMessage(false);
      await formValue.map(async (data, index) => {
        let elms = document.getElementById(data.question_id);
        elms.style.display = "none";
        await finalArray.push([
          data.answer,
          data.question_id,
          data.player_id,
          data.couch_question_id,
        ]);
      });
      let todaysDate = await getCurrentDate();
      let sevenDaysBefore = await getSevenDaysBefore();
      await dispatch(await addPlayerAnswers(finalArray));
      await dispatch(
        await checkPlayerAnswerSubmission(todaysDate, sevenDaysBefore, activeId)
      );
    }
  };

  return (
    <div className="reporting-mid-tab">
      <IonGrid>
        <IonRow>
          <IonCol size="12" size-md="3">
            <div className="reporting-mid-left">
              <a
                id="Tennis_Match_Report"
                className="nav-link"
                onClick={(e) => onSelectCategory(e, "Tennis_Match_Report", 4)}
              >
                <IonImg className="question-icona" src={QuestionBlue} />
                <IonImg className="question-iconb" src={QuestionWhite} />
                Tennis
              </a>
              <a
                id="Health"
                className="nav-link"
                onClick={(e) => onSelectCategory(e, "Health", 5)}
              >
                <IonImg className="question-icona" src={QuestionBlue} />
                <IonImg className="question-iconb" src={QuestionWhite} />
                Health
              </a>
              <a
                id="Team"
                className="nav-link"
                onClick={(e) => onSelectCategory(e, "Team", 6)}
              >
                <IonImg className="question-icona" src={QuestionBlue} />
                <IonImg className="question-iconb" src={QuestionWhite} />
                Team
              </a>
            </div>
          </IonCol>
          <IonCol size="12" size-md="9">
            <div className="reporting-mid-right">
              <div className="tab-content">
                <div className="tab-pane active" id="wellness">
                  <div className="reporting-tabbox">
                    <div className="reporting-tabbox-head">
                      <h3>Match Report</h3>
                      <p>
                        (Report your match experience- SUBMIT 24 HOURS AFTER
                        MATCH)
                      </p>
                    </div>
                    <div className="reporting-tabbox-mid">
                      {props?.isAnswerSubmitted === false ? (
                        props?.playersQuestions.length > 0 ? (
                          props?.playersQuestions?.map((data, index) => {
                            return (
                              <div className="reporting-tabbox-item">
                                <div
                                  className="reporting-tabbox-question"
                                  key={index}
                                >
                                  <div className="reporting-tabbox-question-icon">
                                    <span>Q</span>
                                  </div>
                                  <div className="reporting-tabbox-question-text">
                                    <p>{data?.question}</p>
                                  </div>
                                </div>
                                <div className="reporting-tabbox-ans">
                                  <div className="reporting-tabbox-ans-icon">
                                    <span>A</span>
                                  </div>
                                  <div className="reporting-tabbox-ansinput">
                                    <IonTextarea
                                      onIonChange={(event) => {
                                        handleChange(
                                          event,
                                          data.question_id,
                                          data.coach_questions_id,
                                          index,
                                          data?.question
                                        );
                                      }}
                                      placeholder="Type answer here..."
                                    ></IonTextarea>
                                    <span
                                      className="reporting-tabbox-ans-error"
                                      id={data.question_id}
                                      style={{ display: "none" }}
                                    >
                                      {" "}
                                      Please provide answer for the following
                                      question.
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : ( <div className="loading-para">
                        {props.loading === true ? (
                          <p style={{color:"#404F8C"}}>Loading...</p>
                        ) : (
                          <p style={{color:"#404F8C"}}>No Question Added.</p>
                        )}
                      </div>)
                      ) : (
                        props?.playersAnswersAfterSubmit?.map((data, index) => {
                          return (
                            <div className="reporting-tabbox-item">
                              <div
                                className="reporting-tabbox-question"
                                key={index}
                              >
                                <div className="reporting-tabbox-question-icon">
                                  <span>Q</span>
                                </div>
                                <div className="reporting-tabbox-question-text">
                                  <p>{data?.question}</p>
                                </div>
                              </div>
                              <div className="reporting-tabbox-ans">
                                <div className="reporting-tabbox-ans-icon">
                                  <span>A</span>
                                </div>
                                <div className="reporting-tabbox-ansinput">
                                  <p>{data.answer}</p>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      )}

                      {props &&
                      props?.isAnswerSubmitted === false &&
                      props.playersQuestions &&
                      props.playersQuestions.length > 0 ? (
                        <div className="reporting-tabbox-btn">
                          <IonButton
                            type="button"
                            color="none"
                            onClick={onSubmit}
                          >
                            Submit
                            {(() => {
                              if (props.loading) {
                                return (
                                  <IonSpinner
                                    name="crescent"
                                    style={{ color: "black" }}
                                    className="spinner-loader"
                                  />
                                );
                              }
                            })()}
                          </IonButton>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    playersQuestions: state.prsReducer.playersQuestions,
    isAnswerSubmitted: state.prsReducer.isAnswerSubmitted,
    playersAnswersAfterSubmit: state.prsReducer.playersAnswersAfterSubmit,
    loading: state.commonReducer.loading,
  };
};

export default withRouter(connect(mapStateToProps)(MatchingReport));
