import React, { useState, useRef, useCallback } from "react";
import {
  IonContent,
  IonCardTitle,
  IonSlides,
  IonSlide,
  IonIcon,
  IonInput,
  IonButton,
  IonCard,
  IonCardHeader,
  IonImg,
  IonLabel,
  IonModal,
  IonSpinner,
  IonGrid,
  IonCol,
  IonRow,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/react";
import { close } from "ionicons/icons";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getContactList,
  addContact,
  clearContactList,
  searchContactList,
} from "../../redux/actions/contactAction";
import CoachImg from "../../images/Whistle.svg";
import TeamtImg from "../../images/team.svg";
import StrengthImg from "../../images/gym.svg";
import TrainersImg from "../../images/protector.svg";
import AlumniImg from "../../images/mortarboard.svg";
import CoachImgwhite from "../../images/whistle-white.png";
import TeamtImgwhite from "../../images/team-white.png";
import StrengthImgwhite from "../../images/gym-white.png";
import TrainersImgwhite from "../../images/protector-white.png";
import AlumniImgwhite from "../../images/mortarboard-white.png";
import { searchOutline } from "ionicons/icons";
import Coaches from "./Coaches";
import Teams from "./Teams";
import Strength from "./Strength";
import Trainers from "./Trainers";
import Alumni from "./Alumni";
import "./contact.css";

const Contact = (props) => {
  const ionInfiniteScrollRef = useRef();
  const [activeId, setActiveId] = useState(1);
  const [slidenum, setSlideNum] = useState(6);
  const [showModal, setShowModal] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [keyword, setSearchKeyword] = useState("");
  var clearTimeoutHolder = null;
  const [ref, setRef] = useState(null);
  const myInput = useRef(null);
  // const [limitOffset, setLimitOffset] = useState({limit:2,offset:0});
  const [formData, setFormValue] = useState({
    fullName: "",
    contactNo: "",
    email: "",
    hometown: "",
    category: "",
  });
  const [errorData, setErrorData] = useState({
    fullName: [],
    contactNo: [],
    email: [],
    hometown: [],
    category: [],
  });
  const limit = 5;
  const [limitOffset, setLimitOffset] = useState({ limit, offset: 0 });

  const slideOpts = {
    initialSlide: 0,
    slidesPerView: slidenum,
  };

  const onRefChange = useCallback((node) => {
    // ref value changed to node
    setRef(node); // e.g. change ref state to trigger re-render
    if (node === null) {
      // node is null, if DOM node of ref had been unmounted before
    } else {
      // if(document.getElementById("Coaches"))
      // document.getElementById("Coaches").click();
      myInput.current.click();
      // node.click()
      // ref value exists
    }
  }, []);

  React.useEffect(() => {
    //  props.getContactList(activeId, { limit, offset: limitOffset.offset });
    //  setLimitOffset({ limit, offset: limit });
    document.getElementById("Coaches").click();
    if (window.innerWidth <= 767) {
      setSlideNum(2);
    } else {
      setSlideNum(6);
    }
  }, []);

  async function fetchData() {
    props.getContactList(activeId, limitOffset);
    setLimitOffset({
      limit,
      offset: limitOffset.offset + limit,
    });

    if (props?.contactList?.data?.length > 0) {
      if (props?.contactList?.data.length >= limit) {
        // setDisableInfiniteScroll(false);
        ionInfiniteScrollRef.current.disabled = false;
      } else {
        // setDisableInfiniteScroll(true);
        ionInfiniteScrollRef.current.disabled = false;
      }
    } else {
      // setDisableInfiniteScroll(false);
      ionInfiniteScrollRef.current.disabled = true;
    }
  }

  const loadMoreItems = async () => {
    if (isSearch === true) {
      // setDisableInfiniteScroll(true);
      ionInfiniteScrollRef.current.disabled = true;
    } else {
      await fetchData();
      // setDisableInfiniteScroll(false);
      ionInfiniteScrollRef.current.disabled = false;
    }
  };

  async function onSubmitForm() {
    if (validateForm()) {
      await props.addContact(formData);
      await props.clearContactList();
      await props.getContactList(activeId, { limit, offset: 0 });
      setFormValue({
        fullName: "",
        contactNo: "",
        email: "",
        hometown: "",
        category: "",
      })
      await setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
      }, 3000);
      setShowModal(false);
      // await props.getContactList(activeId, limitOffset);
    }
  }
  const handleChange = (formField, event) => {
    let test = formData;
    test[formField] = event.target.value;
    setFormValue(test);
  };
  const validateForm = () => {
    let isFormValid = true;
    let testError = {
      fullName: [],
      email: [],
      category: [],
    };
    if (formData.email.trim().length === 0) {
      isFormValid = false;
      testError.email = ["This field is required."];
    }
    if (
      formData.email.trim().length > 0 &&
      /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(
        formData.email
      ) === false
    ) {
      isFormValid = false;
      testError.email = ["You entered an invalid email address."];
    }

    if (formData?.category?.length === 0) {
      isFormValid = false;
      testError.category = ["This field is required."];
    }

    if (formData.fullName.trim().length === 0) {
      isFormValid = false;
      testError.fullName = ["This field is required."];
    }
    if (
      formData.fullName.trim().length > 0 &&
      formData.fullName.trim().length > 50
    ) {
      isFormValid = false;
      testError.fullName = ["Name length cannot be gretar than 15"];
    }
    setErrorData(testError);
    return isFormValid;
  };
  const handleSearch = async (e) => {
    e.preventDefault();
    let searchKeyword = e.target.value;
    setSearchKeyword(searchKeyword);
    if (searchKeyword.length > 0) {
      // ionInfiniteScrollRef.current.disabled = false;
      setIsSearch(true);
      if (clearTimeoutHolder !== null) {
        clearTimeout(clearTimeoutHolder);
      }
      clearTimeoutHolder = setTimeout(async () => {
        // await props.clearContactList();
        await props.searchContactList(activeId, searchKeyword);
      }, 1000);
    } else {
      // ionInfiniteScrollRef.current.disabled = true;
      setIsSearch(false);
      if (clearTimeoutHolder !== null) {
        clearTimeout(clearTimeoutHolder);
      }
      clearTimeoutHolder = setTimeout(async () => {
        // debugger;
        await props.clearContactList();
        await setLimitOffset({
          limit,
          offset: limit,
        });
        await props.getContactList(activeId, { limit, offset: 0 });
      }, 2000);
    }
  };
  const onSelectCategory = async (e, id, value) => {
    setSearchKeyword("");
    setIsSearch(false);
    await props.clearContactList();
    setActiveId(value);
    let i, tablinks;
    tablinks = document.getElementsByClassName("commonClass");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("active", "");
    }
    await document.getElementById(id).classList.add("active");
    await props.getContactList(value, { limit, offset: 0 });
    await setLimitOffset({ limit, offset: limit });
  };

  return (
    <IonContent>
      <div className="container">
        <div className="contact-start">
          <div className="contact-start-header">
            <IonGrid>
              <IonRow>
                {/* <IonCol size="12" size-sm="3">
                  <div class="contact-start-heading">
                    <h2>Contacts</h2>
                  </div>
                </IonCol> */}
                <IonCol size="12" size-sm="12">
                  <div className="contact-header-right">
                    <div className="contact-searchinput">
                      <div className="contact-searchinput-inner">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          value={keyword}
                          onChange={(event) => {
                            handleSearch(event);
                          }}
                        ></input>
                        <IonIcon icon={searchOutline} />
                      </div>
                    </div>

                    {props.checkUserRole !== 3 ? (
                      <div class="dash-adduser">
                        <a onClick={() => setShowModal(true)}>
                          {" "}
                          Add New Contact
                        </a>
                      </div>
                    ) : null}
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
            {props.addContactData?.isRegistered === true &&
            showMessage === true ? (
              <div className="success-msg">
                <p>{props.addContactData.message}</p>
              </div>
            ) : null}
            {props?.errorMsg ? (
                <div className="profile-error-msg">
                  <p>{props.errorMsg}</p>
                </div>
              ) : props?.successMsg ? (
                <div className="profile-success-msg">
                  <p>{props.successMsg}</p>
                </div>
              ) : null}
          </div>
          <div className="contact-search-box">
            <IonCard>
              <IonSlides options={slideOpts} scrollbar="true">
                {/* <IonSlide>
                  <ion-button>
                    <IonIcon icon={searchOutline} />
                  </ion-button>
                </IonSlide> */}
                <IonSlide
                  id="Coaches"
                  ref={onRefChange}
                  ref={myInput}
                  className="commonClass default"
                  onClick={(e) => onSelectCategory(e, "Coaches", 1)}
                >
                  <a>
                    <IonImg className="coachImg-blue" src={CoachImg} />
                    <IonImg className="coachImg-white" src={CoachImgwhite} />
                    Coaches
                  </a>
                </IonSlide>
                <IonSlide
                  id="TEAM"
                  className="commonClass"
                  onClick={(e) => onSelectCategory(e, "TEAM", 2)}
                >
                  <a>
                    <IonImg className="coachImg-blue" src={TeamtImg} />
                    <IonImg className="coachImg-white" src={TeamtImgwhite} />
                    Athletes
                  </a>
                </IonSlide>
                <IonSlide
                  id="STRENGTH"
                  className="commonClass"
                  onClick={(e) => onSelectCategory(e, "STRENGTH", 3)}
                >
                  <a>
                    <IonImg className="coachImg-blue" src={StrengthImg} />
                    <IonImg className="coachImg-white" src={StrengthImgwhite} />
                    STRENGTH
                  </a>
                </IonSlide>
                <IonSlide
                  id="TRAINERS"
                  className="commonClass"
                  onClick={(e) => onSelectCategory(e, "TRAINERS", 4)}
                >
                  <a>
                    <IonImg className="coachImg-blue" src={TrainersImg} />
                    <IonImg className="coachImg-white" src={TrainersImgwhite} />
                    TRAINERS
                  </a>
                </IonSlide>
                <IonSlide
                  id="ALUMNI"
                  className="commonClass"
                  onClick={(e) => onSelectCategory(e, "ALUMNI", 5)}
                >
                  <a>
                    <IonImg className="coachImg-blue" src={AlumniImg} />
                    <IonImg className="coachImg-white" src={AlumniImgwhite} />
                    ALUMNI
                  </a>
                </IonSlide>
              </IonSlides>
            </IonCard>
          </div>
          <div class="dashboard-contact-list">
            {activeId === 1 ? (
              <Coaches
                isChat={props?.isChat ? props.isChat : null}
                payload={props.contactList}
                loading={props.loading}
                loadMoreItems={loadMoreItems}
                disableInfiniteScroll={disableInfiniteScroll}
                ionInfiniteScrollRef={ionInfiniteScrollRef}
                getContactList={props.getContactList}
                setLimitOffset={setLimitOffset}
              ></Coaches>
            ) : activeId === 2 ? (
              <Teams
                isChat={props?.isChat ? props.isChat : null}
                payload={props.contactList}
                loading={props.loading}
                loadMoreItems={loadMoreItems}
                disableInfiniteScroll={disableInfiniteScroll}
                ionInfiniteScrollRef={ionInfiniteScrollRef}
              ></Teams>
            ) : activeId === 3 ? (
              <Strength
                isChat={props?.isChat ? props.isChat : null}
                payload={props.contactList}
                loading={props.loading}
                loadMoreItems={loadMoreItems}
                disableInfiniteScroll={disableInfiniteScroll}
                ionInfiniteScrollRef={ionInfiniteScrollRef}
              ></Strength>
            ) : activeId === 4 ? (
              <Trainers
                isChat={props?.isChat ? props.isChat : null}
                payload={props.contactList}
                loading={props.loading}
                loadMoreItems={loadMoreItems}
                disableInfiniteScroll={disableInfiniteScroll}
                ionInfiniteScrollRef={ionInfiniteScrollRef}
              ></Trainers>
            ) : activeId === 5 ? (
              <Alumni
                isChat={props?.isChat ? props.isChat : null}
                payload={props.contactList}
                loading={props.loading}
                loadMoreItems={loadMoreItems}
                disableInfiniteScroll={disableInfiniteScroll}
                ionInfiniteScrollRef={ionInfiniteScrollRef}
              ></Alumni>
            ) : null}
            {/* {props.loading ===true ? <p>Loading...</p> : null} */}
          </div>
        </div>
      </div>

      <IonModal
        isOpen={showModal}
        cssClass="contact-add-modal"
        swipeToClose={true}
        backdropDismiss={false}
        onDidDismiss={() => {
          // props.ClearData();
          setShowModal(false);
          setErrorData({
            fullName: [],
            contactNo: [],
            email: [],
            hometown: [],
            category: [],
          });
        }}
        onWillPresent={() => {
          // getOption()
          // props.ClearData();
        }}
      >
        <div className="addcontact-popup">
          <div className="popup-header">
            <h5>Add New Contact</h5>
            <div className="errormsg">
              <p>{props?.errorMsg}</p>
            </div>
            <IonButton onClick={() => setShowModal(false)}>
              <IonIcon icon={close} />
            </IonButton>
          </div>
          <form>
            <IonGrid>
              <IonRow>
                <IonCol size="12" size-md="12">
                  <div className="form-group">
                    <IonLabel>
                      Full Name<sup>*</sup>
                    </IonLabel>
                    <IonInput
                      type="text"
                      name="fullName"
                      className="form-control"
                      onIonChange={(event) => {
                        handleChange("fullName", event);
                      }}
                    ></IonInput>
                    {errorData?.fullName[0] ? (
                      <p className="errormsg">{errorData?.fullName[0]}</p>
                    ) : null}
                  </div>
                </IonCol>
                <IonCol size="12" size-md="12">
                  <div className="form-group">
                    <IonLabel>
                      Email Address<sup>*</sup>
                    </IonLabel>
                    <IonInput
                      type="text"
                      name="email"
                      className="form-control"
                      onIonChange={(event) => {
                        handleChange("email", event);
                      }}
                    ></IonInput>
                    {errorData?.email[0] ? (
                      <p className="errormsg">{errorData?.email[0]}</p>
                    ) : null}
                  </div>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="12" size-md="12">
                  <div className="form-group">
                    <IonLabel>Mobile Number</IonLabel>
                    <IonInput
                      type="text"
                      name="contactNo"
                      className="form-control"
                      onIonChange={(event) => {
                        handleChange("contactNo", event);
                      }}
                    ></IonInput>
                    {/* {errorData?.contactNo[0] ? (
                      <p className="errormsg">{errorData?.contactNo[0]}</p>
                    ) : null} */}
                  </div>
                </IonCol>
                <IonCol size="12" size-md="12">
                  <div className="form-group">
                    <IonLabel>Hometown</IonLabel>
                    <IonInput
                      type="text"
                      name="hometown"
                      className="form-control"
                      onIonChange={(event) => {
                        handleChange("hometown", event);
                      }}
                    ></IonInput>
                    {/* {errorData?.hometown[0] ? (
                      <p className="errormsg">{errorData?.hometown[0]}</p>
                    ) : null} */}
                  </div>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="12" size-md="12">
                  <div class="form-group">
                    <IonLabel>
                      Category<sup>*</sup>
                    </IonLabel>
                    <IonSelect
                      type="text"
                      name="category"
                      className="form-control"
                      onIonChange={(event) => {
                        handleChange("category", event);
                      }}
                      interface="popover"
                      placeholder="Select One"
                      // value={selectedOption}
                    >
                      <IonSelectOption value={1}>Coaches</IonSelectOption>
                      <IonSelectOption value={2}>Teams</IonSelectOption>
                      <IonSelectOption value={3}>Strength</IonSelectOption>
                      <IonSelectOption value={4}>Trainers</IonSelectOption>
                      <IonSelectOption value={5}>Alumni</IonSelectOption>
                    </IonSelect>
                    {errorData?.category[0] ? (
                      <p className="errormsg">{errorData?.category[0]}</p>
                    ) : null}
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>

            <div class="form-group-center">
              <IonButton
                onClick={(event) => {
                  onSubmitForm(event);
                }}
              >
                Add Contact
                {(() => {
                  if (props.loading) {
                    return (
                      <IonSpinner name="crescent" className="spinner-loader" />
                    );
                  }
                })()}
              </IonButton>
            </div>
          </form>
        </div>
      </IonModal>
    </IonContent>
  );
};

const mapStateToProps = (state) => {
  return {
    contactList: state.contactReducer.contactList,
    addContactData: state.contactReducer.addContactData,
    loading: state.commonReducer.loading,
    checkUserRole: state.authReducer.checkUserRole,
    errorMsg: state.commonReducer.errorMsg,
    errorData: state.commonReducer.errorData,
    successMsg: state.commonReducer.successMsg,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getContactList, addContact, clearContactList, searchContactList },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);

// *******************************************
// import React, { useState, useRef } from "react";
// import {
//   IonContent,
//   IonCardTitle,
//   IonSlides,
//   IonSlide,
//   IonIcon,
//   IonInput,
//   IonButton,
//   IonCard,
//   IonCardHeader,
//   IonImg,
//   IonLabel,
//   IonModal,
//   IonSpinner,
//   IonGrid,
//   IonCol,
//   IonRow,
//   IonSelect,
//   IonSelectOption,
// } from "@ionic/react";
// import { IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/react";
// import { close } from "ionicons/icons";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import {
//   getContactList,
//   addContact,
//   clearContactList,
//   searchContactList,
// } from "../../redux/actions/contactAction";
// import CoachImg from "../../images/Whistle.svg";
// import TeamtImg from "../../images/team.svg";
// import StrengthImg from "../../images/gym.svg";
// import TrainersImg from "../../images/protector.svg";
// import AlumniImg from "../../images/mortarboard.svg";
// import CoachImgwhite from "../../images/whistle-white.png";
// import TeamtImgwhite from "../../images/team-white.png";
// import StrengthImgwhite from "../../images/gym-white.png";
// import TrainersImgwhite from "../../images/protector-white.png";
// import AlumniImgwhite from "../../images/mortarboard-white.png";
// import { searchOutline } from "ionicons/icons";
// import Coaches from "./Coaches";
// import Teams from "./Teams";
// import Strength from "./Strength";
// import Trainers from "./Trainers";
// import Alumni from "./Alumni";
// import "./contact.css";
// var clearTimeoutHolder = null;
// const Contact = (props) => {
//   const ionInfiniteScrollRef = useRef();
//   const [activeId, setActiveId] = useState(1);
//   const [slidenum, setSlideNum] = useState(6);
//   const [showModal, setShowModal] = useState(false);
//   const [showMessage, setShowMessage] = useState(false);
//   const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false);
//   const options = {
//     cssClass: 'custom-interface-popover'
//   };
//   // const [limitOffset, setLimitOffset] = useState({limit:2,offset:0});
//   const [isSearch, setIsSearch] = useState(false);
//   const [keyword, setSearchKeyword]=useState("")
//   const [formData, setFormValue] = useState({
//     fullName: "",
//     contactNo: "",
//     email: "",
//     hometown: "",
//     category: "",
//   });
//   const [errorData, setErrorData] = useState({
//     fullName: [],
//     // contactNo: [],
//     email: [],
//     hometown: [],
//     category: [],
//   });
//   const limit = 5;
//   const [limitOffset, setLimitOffset] = useState({ limit, offset: 0 });

//   const slideOpts = {
//     initialSlide: 0,
//     slidesPerView: slidenum,
//   };

//   React.useEffect(() => {
//     document.getElementById("Coaches").click();
//     if (window.innerWidth <= 767) {
//       setSlideNum(3);
//     } else {
//       setSlideNum(6);
//     }
//   }, []);

//   async function fetchData() {
//     await props.getContactList(activeId, limitOffset);
//     setLimitOffset({
//       limit,
//       offset: limitOffset.offset + limit,
//     })

//     if (props?.contactList?.data?.length > 0) {
//       if (props?.contactList?.data.length >= limit) {
//         setDisableInfiniteScroll(false);
//       } else {
//         setDisableInfiniteScroll(true);
//       }
//     } else {
//       setDisableInfiniteScroll(false);
//     }
//   }

//   const loadMoreItems = async () => {
//     await fetchData();
//     // ionInfiniteScrollRef.current.disabled = true;
//     console.log(isSearch,"search")
//     if(isSearch === true){
//       ionInfiniteScrollRef.current.disabled = false;
//     } else{
//       ionInfiniteScrollRef.current.disabled = true;
//     }

//   };

//   async function onSubmitForm() {
//     if (validateForm()) {
//       await props.addContact(formData);
//       await props.clearContactList();
//       await props.getContactList(activeId, { limit, offset: 0 });
//       await setShowMessage(true);
//       setTimeout(() => {
//         setShowMessage(false);
//       }, 3000);
//       setShowModal(false);
//       // await props.getContactList(activeId, limitOffset);
//     }
//   }
//   const handleChange = (formField, event) => {
//     let test = formData;
//     test[formField] = event.target.value;
//     setFormValue(test);
//   };
//   const validateForm = () => {
//     let isFormValid = true;
//     let testError = {
//       fullName: [],
//       // contactNo: [],
//       email: [],
//       hometown: [],
//       category: [],
//     };
//     if (formData.email.trim().length === 0) {
//       isFormValid = false;
//       testError.email = ["This field is required!"];
//     }
//     if (
//       formData.email.trim().length > 0 &&
//       /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(
//         formData.email
//       ) === false
//     ) {
//       isFormValid = false;
//       testError.email = ["Email address is not valid!"];
//     }
//     if (formData.hometown.trim().length === 0) {
//       isFormValid = false;
//       testError.hometown = ["This field is required!"];
//     }
//     if (formData?.category?.length === 0) {
//       isFormValid = false;
//       testError.category = ["This field is required!"];
//     }
//     // if (formData.contactNo.trim().length === 0) {
//     //   isFormValid = false;
//     //   testError.contactNo = ["This field is required!"];
//     // }
//     // if (
//     //   formData.contactNo.trim().length < 10 ||
//     //   formData.contactNo.trim().length > 15
//     // ) {
//     //   isFormValid = false;
//     //   testError.contactNo = ["Mobile Number must be between 10 to 15"];
//     // }
//     // if (formData.contactNo.match("^[a-zA-Z ]*$")) {
//     //   isFormValid = false;
//     //   testError.contactNo = ["Mobile Number must be valid"];
//     // }

//     if (formData.fullName.trim().length === 0) {
//       isFormValid = false;
//       testError.fullName = ["This field is required!"];
//     }
//     if (
//       formData.fullName.trim().length > 0 &&
//       formData.fullName.trim().length > 50
//     ) {
//       isFormValid = false;
//       testError.fullName = ["Name length cannot be gretar than 15"];
//     }

//     setErrorData(testError);
//     return isFormValid;
//   };

//   const onSelectCategory = async (e, id, value) => {
//     setSearchKeyword("")
//     setIsSearch(false)
//     await props.clearContactList();
//      setActiveId(value);
//     let i, tablinks;
//     tablinks = document.getElementsByClassName("commonClass");
//     for (i = 0; i < tablinks.length; i++) {
//       tablinks[i].className = tablinks[i].className.replace("active", "");
//     }
//     await document.getElementById(id).classList.add("active");
//     await props.getContactList(value, { limit, offset: 0 });
//     await setLimitOffset({ limit, offset: limit });

//     // await props.getContactList(value, { limit, offset: limitOffset.offset });
//     // await setLimitOffset({ limit, offset: limit });

//   };

//   const handleSearch = async (e) => {
//     e.preventDefault();
//     let searchKeyword = e.target.value;
//     setSearchKeyword(searchKeyword)
//       if (searchKeyword.length > 0) {
//         // ionInfiniteScrollRef.current.disabled = false;
//       setIsSearch(true)
//       if (clearTimeoutHolder !== null) {
//         clearTimeout(clearTimeoutHolder)
//       }
//       clearTimeoutHolder = setTimeout(async () => {
//         // await props.clearContactList();
//         await props.searchContactList(activeId,searchKeyword)
//       }, 1000)
//     } else {
//       // ionInfiniteScrollRef.current.disabled = true;
//       setIsSearch(false);
//       if (clearTimeoutHolder !== null) {
//         clearTimeout(clearTimeoutHolder)
//       }
//       clearTimeoutHolder = setTimeout(async () => {
//         // debugger;
//         await props.clearContactList();
//         await setLimitOffset({
//           limit,
//           offset:  limit
//         })
//         await props.getContactList(activeId, { limit, offset: 0 });
//       }, 2000)
//     }
//   }

//   return (<IonContent>
//       <div className="container">
//         <div className="contact-start">
//           <div className="contact-start-header">
//             <IonGrid>
//               <IonRow>
//                 <IonCol size="12" size-sm="3">
//                   <div class="contact-start-heading">
//                     <h2>Contacts</h2>
//                   </div>
//                 </IonCol>

//                 {/* <IonCol size="12" size-sm="6">
//                   <div >
//                     <IonInput  type="text"
//                       className="form-control"
//                       onIonChange={(event) => {
//                         handleSearch(event);
//                       }}></IonInput>
//                   </div>
//                 </IonCol> */}

//                 <IonCol size="12" size-sm="9">

//                   <div className="contact-header-right">
//                   <div className="contact-searchinput">
//                         <div className="contact-searchinput-inner">
//                           <input  type="text"
//                       className="form-control"
//                       placeholder="Search..."
//                       value={keyword}
//                       onChange={(event) => {
//                         handleSearch(event);
//                       }}></input>
//                           <IonIcon icon={searchOutline} />
//                         </div>
//                       </div>

//                   {props.checkUserRole !== 3 ? (
//                     <div class="dash-adduser">
//                       <a onClick={() => setShowModal(true)}> Add New Contact</a>
//                     </div>
//                   ) : null}
//                   </div>

//                 </IonCol>
//               </IonRow>
//             </IonGrid>
//             {props.addContactData?.isRegistered === true &&
//             showMessage === true ? (
//               <div className="success-msg">
//                 <p>{props.addContactData.message}</p>
//               </div>
//             ) : null}
//           </div>
//           <div className="contact-search-box">
//             <IonCard>
//               <IonSlides options={slideOpts} scrollbar="true">
//                 {/* <IonSlide>
//                   <ion-button>
//                     <IonIcon icon={searchOutline} />
//                   </ion-button>
//                 </IonSlide> */}
//                 <IonSlide
//                   id="Coaches"
//                   className="commonClass default"
//                   onClick={(e) => onSelectCategory(e, "Coaches", 1)}
//                 >
//                   <a>
//                     <IonImg className="coachImg-blue" src={CoachImg} />
//                     <IonImg className="coachImg-white" src={CoachImgwhite} />
//                     Coaches
//                   </a>
//                 </IonSlide>
//                 <IonSlide
//                   id="TEAM"
//                   className="commonClass"
//                   onClick={(e) => onSelectCategory(e, "TEAM", 2)}
//                 >
//                   <a>
//                     <IonImg className="coachImg-blue" src={TeamtImg} />
//                     <IonImg className="coachImg-white" src={TeamtImgwhite} />
//                     TEAM
//                   </a>
//                 </IonSlide>
//                 <IonSlide
//                   id="STRENGTH"
//                   className="commonClass"
//                   onClick={(e) => onSelectCategory(e, "STRENGTH", 3)}
//                 >
//                   <a>
//                     <IonImg className="coachImg-blue" src={StrengthImg} />
//                     <IonImg className="coachImg-white" src={StrengthImgwhite} />
//                     STRENGTH
//                   </a>
//                 </IonSlide>
//                 <IonSlide
//                   id="TRAINERS"
//                   className="commonClass"
//                   onClick={(e) => onSelectCategory(e, "TRAINERS", 4)}
//                 >
//                   <a>
//                     <IonImg className="coachImg-blue" src={TrainersImg} />
//                     <IonImg className="coachImg-white" src={TrainersImgwhite} />
//                     TRAINERS
//                   </a>
//                 </IonSlide>
//                 <IonSlide
//                   id="ALUMNI"
//                   className="commonClass"
//                   onClick={(e) => onSelectCategory(e, "ALUMNI", 5)}
//                 >
//                   <a>
//                     <IonImg className="coachImg-blue" src={AlumniImg} />
//                     <IonImg className="coachImg-white" src={AlumniImgwhite} />
//                     ALUMNI
//                   </a>
//                 </IonSlide>
//               </IonSlides>
//             </IonCard>
//           </div>
//           <div class="dashboard-contact-list">
//             {activeId === 1 ? (
//               <Coaches
//                 payload={props.contactList}
//                 loading={props.loading}
//                 loadMoreItems={loadMoreItems}
//                 disableInfiniteScroll={disableInfiniteScroll}
//                 ionInfiniteScrollRef={ionInfiniteScrollRef}
//                 getContactList={props.getContactList}
//                 setLimitOffset={setLimitOffset}
//               ></Coaches>
//             ) : activeId === 2 ? (
//               <Teams
//                 payload={props.contactList}
//                 loading={props.loading}
//                 loadMoreItems={loadMoreItems}
//                 disableInfiniteScroll={disableInfiniteScroll}
//                 ionInfiniteScrollRef={ionInfiniteScrollRef}
//               ></Teams>
//             ) : activeId === 3 ? (
//               <Strength
//                 payload={props.contactList}
//                 loading={props.loading}
//                 loadMoreItems={loadMoreItems}
//                 disableInfiniteScroll={disableInfiniteScroll}
//                 ionInfiniteScrollRef={ionInfiniteScrollRef}
//               ></Strength>
//             ) : activeId === 4 ? (
//               <Trainers
//                 payload={props.contactList}
//                 loading={props.loading}
//                 loadMoreItems={loadMoreItems}
//                 disableInfiniteScroll={disableInfiniteScroll}
//                 ionInfiniteScrollRef={ionInfiniteScrollRef}
//               ></Trainers>
//             ) : activeId === 5 ? (
//               <Alumni
//                 payload={props.contactList}
//                 loading={props.loading}
//                 loadMoreItems={loadMoreItems}
//                 disableInfiniteScroll={disableInfiniteScroll}
//                 ionInfiniteScrollRef={ionInfiniteScrollRef}
//               ></Alumni>
//             ) : null}
//             {/* {props.loading ===true ? <p>Loading...</p> : null} */}
//           </div>
//         </div>
//       </div>

//       <IonModal
//         isOpen={showModal}
//         cssClass="contact-add-modal"
//         swipeToClose={true}
//         backdropDismiss={false}
//         onDidDismiss={() => {
//           // props.ClearData();
//           setShowModal(false);
//           setErrorData({
//             fullName: [],
//             // contactNo: [],
//             email: [],
//             hometown: [],
//             category: [],
//           });
//         }}
//         onWillPresent={() => {
//           // getOption()
//           // props.ClearData();
//         }}
//       >
//         <div className="addcontact-popup">
//           <div className="popup-header">
//             <h5>Add New Contact</h5>
//             <div className="errormsg">
//               <p>{props?.errorMsg}</p>
//             </div>
//             <IonButton onClick={() => setShowModal(false)}>
//               <IonIcon icon={close} />
//             </IonButton>
//           </div>
//           <form>
//             <IonGrid>
//               <IonRow>
//                 <IonCol size="12" size-md="12">
//                   <div className="form-group">
//                     <IonLabel>Full Name<sup>*</sup></IonLabel>
//                     <IonInput
//                       type="text"
//                       name="fullName"
//                       className="form-control"
//                       onIonChange={(event) => {
//                         handleChange("fullName", event);
//                       }}
//                     ></IonInput>
//                     {errorData?.fullName[0] ? (
//                       <p className="errormsg">{errorData?.fullName[0]}</p>
//                     ) : null}
//                   </div>
//                 </IonCol>
//                 <IonCol size="12" size-md="12">
//                   <div className="form-group">
//                     <IonLabel>Email address<sup>*</sup></IonLabel>
//                     <IonInput
//                       type="text"
//                       name="email"
//                       className="form-control"
//                       onIonChange={(event) => {
//                         handleChange("email", event);
//                       }}
//                     ></IonInput>
//                     {errorData?.email[0] ? (
//                       <p className="errormsg">{errorData?.email[0]}</p>
//                     ) : null}
//                   </div>
//                 </IonCol>
//               </IonRow>
//               <IonRow>
//                 <IonCol size="12" size-md="12">
//                   <div className="form-group">
//                     <IonLabel>Mobile Number</IonLabel>
//                     <IonInput
//                       type="text"
//                       name="contactNo"
//                       className="form-control"
//                       onIonChange={(event) => {
//                         handleChange("contactNo", event);
//                       }}
//                     ></IonInput>
//                     {/* {errorData?.contactNo[0] ? (
//                       <p className="errormsg">{errorData?.contactNo[0]}</p>
//                     ) : null} */}
//                   </div>
//                 </IonCol>
//                 <IonCol size="12" size-md="12">
//                   <div className="form-group">
//                     <IonLabel>Hometown<sup>*</sup></IonLabel>
//                     <IonInput
//                       type="text"
//                       name="hometown"
//                       className="form-control"
//                       onIonChange={(event) => {
//                         handleChange("hometown", event);
//                       }}
//                     ></IonInput>
//                     {errorData?.hometown[0] ? (
//                       <p className="errormsg">{errorData?.hometown[0]}</p>
//                     ) : null}
//                   </div>
//                 </IonCol>
//               </IonRow>
//               <IonRow>
//                 <IonCol size="12" size-md="12">
//                   <div class="form-group">
//                     <IonLabel>Category<sup>*</sup></IonLabel>
//                     <IonSelect
//                       type="text"
//                       name="category"
//                       className="form-control"
//                       onIonChange={(event) => {
//                         handleChange("category", event);
//                       }}
//                       interface="popover"
//                       interfaceOptions={options}
//                       placeholder="Select One"
//                       // value={selectedOption}
//                     >
//                       <IonSelectOption value={1}>Coaches</IonSelectOption>
//                       <IonSelectOption value={2}>Teams</IonSelectOption>
//                       <IonSelectOption value={3}>Strength</IonSelectOption>
//                       <IonSelectOption value={4}>Trainers</IonSelectOption>
//                       <IonSelectOption value={5}>Alumni</IonSelectOption>
//                     </IonSelect>
//                     {errorData?.category[0] ? (
//                       <p className="errormsg">{errorData?.category[0]}</p>
//                     ) : null}
//                   </div>
//                 </IonCol>
//               </IonRow>
//             </IonGrid>

//             <div class="form-group-center">
//               <IonButton
//                 onClick={(event) => {
//                   onSubmitForm(event);
//                 }}
//               >
//                 Add Contact
//                 {(() => {
//                   if (props.loading) {
//                     return (
//                       <IonSpinner name="crescent" className="spinner-loader" />
//                     );
//                   }
//                 })()}
//               </IonButton>
//             </div>
//           </form>
//         </div>
//       </IonModal>
//     </IonContent>
//   );
// };

// const mapStateToProps = (state) => {
//   return {
//     contactList: state.contactReducer.contactList,
//     addContactData: state.contactReducer.addContactData,
//     loading: state.commonReducer.loading,
//     checkUserRole: state.authReducer.checkUserRole,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return bindActionCreators(
//     { getContactList, addContact, clearContactList,searchContactList },
//     dispatch
//   );
// };

// export default connect(mapStateToProps, mapDispatchToProps)(Contact);
