import axios from "axios";
import { handleError, handleSuccess } from "./commonAction";

import { Plugins } from "@capacitor/core";
const { Storage } = Plugins;
const URL = process.env.REACT_APP_API_URL;

export function signAction(values) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/users/signup`,
        data: values,
      });
      dispatch({
        type: "SIGNUP",
        payload: { data: res.data },
      });
      dispatch({ type: "LOADING_COMPLETED" });
      return {
        success: res.data.success,
        message: res.data.message,
      };
    } catch (error) {
      dispatch(handleError(error));
      dispatch({ type: "LOADING_COMPLETED" });
    }
  };
}

export function loginAction(values) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/users/login`,
        data: values,
      });
      console.log(res)
      if (
        res &&
        res.data &&
        (res.data.user.role === 1 ||
          res.data.user.role === 2 ||
          res.data.user.role === 3)
      ) {
        dispatch({
          type: "CHECK_PAYMENT",
          checkIsPayment: res.data.user.is_payment,
        });
        dispatch({
          type: "LOGIN",
          payload: { data: res.data },
          checkUserRole: res.data.user.role,
        });
        dispatch({ type: "LOADING_COMPLETED" });
      } else if (res && res.data && res.data.user.role === 4) {
        dispatch({ type: "LOADING_FAILURE" });
        dispatch(handleError({ "response": { "data": "You are not a valid user!" } }))
      }

    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(error));
    }
  };
}

export function loginAfterVerify(values, props) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/users/loginAfterVerify`,
        data: values,
      });
      if (
        res &&
        res.data &&
        (res.data.user.role === 1 ||
          res.data.user.role === 2 ||
          res.data.user.role === 3)
      ) {
        dispatch({
          type: "CHECK_PAYMENT",
          checkIsPayment: res.data.user.is_payment,
        });
        dispatch({
          type: "LOGIN",
          payload: { data: res.data },
          checkUserRole: res.data.user.role,
        });
        dispatch({ type: "LOADING_COMPLETED" });
        props.history.push("/page/payment")
      } else if (res && res.data && res.data.user.role === 4) {
        dispatch({ type: "LOADING_FAILURE" });
        dispatch(handleError({ "response": { "data": "You are not a valid user!" } }))
      }

    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(error));
    }
  };
}
export function getTeamDetail(id) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/users/getTeamDetails?id=${id}`
      });

      dispatch({
        type: "Team_Details",
        payload: { data: res.data },
      });
      dispatch({ type: "LOADING_COMPLETED" });


    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(error));
    }
  };
}

export function verifyEmail(values) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "patch",
        url: `${URL}/api/v1/users/verifyCode`,
        data: { code: values.code, email: values.email },
      });

      // dispatch({
      //   type: "CHECK_PAYMENT",
      //   checkIsPayment: res.data.user.is_payment
      // });
      // dispatch({
      //   type: "LOGIN",
      //   payload: { data: res.data },
      //   checkUserRole:  res.data.user.role,
      //   // checkIsPayment: res.data.user.is_payment
      // });
      dispatch({ type: "LOADING_COMPLETED" });
      return res;
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(error));
    }
  };
}

export function checkPayment(values) {
  return async function (dispatch) {
    try {
      dispatch({
        type: "CHECK_PAYMENT",
        checkIsPayment: values.isPayment,
      });
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch(handleError(error));
      dispatch({ type: "LOADING_COMPLETED" });
    }
  };
}

export const logoutUser = () => async (dispatch) => {
  try {
    // await Storage.remove({ key: "user" });
    localStorage.removeItem("user")
    localStorage.removeItem("userData")
    dispatch({ type: "CLEAN_DATA" });
    var auth2 = window.gapi.auth2.getAuthInstance();
    auth2.signOut().then(function () {
    });
    dispatch({ type: "LOGOUT" });

  } catch (error) { }
};

export const matchtoken = () => async (dispatch) => {
  try {

    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/users/matchtoken?email=${JSON.parse(localStorage.getItem("userData")).email
        }&token=${localStorage.getItem("user")}&id=${JSON.parse(localStorage.getItem("userData")).id
        }`,
      headers: {
        authorization: localStorage.getItem("user"),
      },
    });
    // dispatch({ type: "LOADING_COMPLETED" });
    dispatch({
      type: "CHECK_PAYMENT",
      checkIsPayment: res.data.user.is_payment,
    });
    dispatch({
      type: "LOGIN",
      payload: { data: res.data },
      checkUserRole: res.data.user.role,
      // checkIsPayment: res.data.user.is_payment
    });
    // dispatch({
    //   type: "LOGIN",
    //   payload: { data: res.data },
    // });
  } catch (error) {
    // dispatch({ type: "LOADING_FAILURE" });
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.removeItem("user")
        localStorage.removeItem("userData")
        // window.location.reload();
      } else {
        dispatch({
          type: "ERROR",
          payload: { error: error.response.data },
        });
      }
      // toast.error(error.response.data.error);
    } else {
      dispatch({
        type: "ERROR",
        payload: { error: error.message },
      });
    }
  }
};

export const isUser = () => async (dispatch) => {
  try {
    let token = await localStorage.getItem("userData");
    if (token) {
      token = JSON.parse(token);
      dispatch({
        type: "USERINFO",
        payload: { data: token },
      });
    }
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const forgotPassword = (data, setFormValue) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/users/forgotPassword`,
      data: data,
    });
    dispatch({ type: "LOADING_COMPLETED" });
    if (res.data.success === false) {
      dispatch(handleError({ message: res.data.message }));
    } else {
      dispatch(handleSuccess({ message: res.data.message }));
      setFormValue({ email: "" })
      return res;
    }
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.removeItem("user")
        localStorage.removeItem("userData")
        // window.location.reload();
      } else {
        dispatch(handleError(error));
        // dispatch({
        //   type: "ERROR",
        //   payload: { error: error.response.data },
        // });
      }
      // toast.error(error.response.data.error);
    } else dispatch(handleError(error));
  }
};

export const changePasswordAction = (data) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/users/resetPassword`,
      data: data,
    });
    dispatch({ type: "LOADING_COMPLETED" });

    if (res.data.success === false) {
      dispatch(handleError({ message: res.data.message }));
    } else {
      dispatch(handleSuccess({ message: res.data.message }));
      return res;
    }
    return res;
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    if (error.response) {
      if (error.response.status === 401) {
        // localStorage.clear();
        // window.location.reload();
      } else {
        dispatch({
          type: "ERROR",
          payload: { error: error.response.data },
        });
      }
      // toast.error(error.response.data.error);
    } else dispatch(handleError(error));
  }
};
export const changePasswordd = (data) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/users/changePassword`,
      data: data,
    });
    dispatch({ type: "LOADING_COMPLETED" });

    if (res.data.success === false) {
      dispatch(handleError({ message: res.data.message }));
    } else {
      dispatch(handleSuccess({ message: res.data.message }));
      return res;
    }
    return res;
  } catch (error) {
    dispatch(handleError(error));
    dispatch({ type: "LOADING_COMPLETED" });
  }
};

export const googleLogin = (data) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/users/googlelogin`,
      data: data,
    });
    dispatch({
      type: "CHECK_PAYMENT",
      checkIsPayment: res.data.user.is_payment,
    });
    dispatch({
      type: "LOGIN",
      payload: { data: res.data },
      checkUserRole: res.data.user.role,
      // checkIsPayment: res.data.user.is_payment
    });
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch(handleError(error));
    dispatch({ type: "LOADING_COMPLETED" });
  }
};

export const facebookLogin = (data) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/users/fblogin`,
      data: data,
    });
    dispatch({
      type: "CHECK_PAYMENT",
      checkIsPayment: res.data.user.is_payment,
    });
    dispatch({
      type: "LOGIN",
      payload: { data: res.data },
      checkUserRole: res.data.user.role,
    });
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch(handleError(error));
    dispatch({ type: "LOADING_COMPLETED" });
  }
};
