import axios from "axios";
import { handleError, handleSuccess } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export function addUserAction(values) {
  console.log('in action')
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/user`,
        data: values,
      });
      console.log('RES')
      console.log(res)
      dispatch(handleSuccess({ message: res.data.message }));

      dispatch({
        type: "ADD_USER",
        payload: { data: res.data },
      });

      dispatch({ type: "LOADING_COMPLETED" });

      return res.data;

    } catch (error) {
      dispatch(handleError(error));
      dispatch({ type: "LOADING_FAILURE" });
    }
  };
}

export function getUserDetails(values) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      let userData = localStorage.getItem("userData");
      let userDataParse = JSON.parse(userData);
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/user/details`,
        data: { user_id: userDataParse.id, limit: values.limit, offset: values.offset, role: values.role, userRole: values.userRole },
        headers: {
          Authorization: localStorage.getItem("user"),
        },
      });
      dispatch({ type: "LOADING_COMPLETED" });

      dispatch({ type: "GET_USER_DETAILS", payload: { data: res.data.data.data } });
      dispatch({ type: "ERROR", payload: { errorMsg: "", errorData: {} } });
      return res;
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(error));

    }
  };
}

export function deleteUser(id) {
  return async function (dispatch) {
    try {
      const res = await axios({
        method: "delete",
        url: `${URL}/api/v1/user`,
        data: id,
        headers: {
          authorization: localStorage.getItem("user"),
        },
      });
      // dispatch({ type: "LOADING_COMPLETED" });

      // dispatch({ type: "GET_USER_DETAILS", payload: { data: res.data.data } });
      dispatch({ type: "ERROR", payload: { errorMsg: "", errorData: {} } });
    } catch (error) {
      dispatch(handleError(error));
      dispatch({ type: "LOADING_FAILURE" });
    }
  };



}

export function cleanUpUserDetails() {
  return async function (dispatch) {
    dispatch({ type: "CLEAN_USERS_DETAILS", payload: [] });
  };
}

export function cleanData() {
  return async function (dispatch) {
    // dispatch({ type: "CLEAN_DATA", payload: [] });
  };
}

export function setLimitOffset(values) {
  return async function (dispatch) {
    dispatch({ type: "SET_LIMIT_OFFSET", payload: values });
  };
}

export function editUser(data) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      let userData = localStorage.getItem("userData");
      let userDataParse = JSON.parse(userData);
      const res = await axios({
        method: "put",
        url: `${URL}/api/v1/user/edit/${userDataParse.id}`,
        data: data,
      });
      dispatch({ type: "LOADING_COMPLETED" });
      if (res.isUpdated === false) {
        dispatch(handleError({ message: res.data.message }));
      } else {
        getUser()
        dispatch(handleSuccess({ message: res.data.message }));
        return res;
      }
      return res;

    } catch (error) {
      dispatch(handleError(error));
      dispatch({ type: "LOADING_COMPLETED" });
    }
  }
}

export function fcmToken(data) {
  return async function (dispatch) {
    try {
      const res = await axios({
        method: "put",
        url: `${URL}/api/v1/user/fcmToken`,
        data: data,
      });
      return res;
    } catch (error) {
      dispatch(handleError(error));
    }
  }
}

export function notificationSetting(data) {
  return async function (dispatch) {
    try {
      const res = await axios({
        method: "put",
        url: `${URL}/api/v1/user/notificationSetting`,
        data: data,
      });
      return res;
    } catch (error) {
      dispatch(handleError(error));
    }
  }
}


export function getUser(id) {
  return async function (dispatch) {
    try {
      // dispatch({ type: "LOADING_STARTED" });
      // let userData =  localStorage.getItem("userData");

      // let userDataParse = JSON.parse(userData);
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/user/${id}`,
        headers: {
          authorization: localStorage.getItem("user"),
        }
      });
      // dispatch({ type: "LOADING_COMPLETED" });
      if (res.data.isFound === true) {
        // localStorage.setItem("userData",JSON.stringify(res.data.data.data) )
        dispatch({ type: "USER_PROFILE_DATA", payload: { data: res.data.data.data } })
        return res.data?.data?.data;
        // dispatch(handleError({ message: res.data.message }));
      } else {
        // localStorage.setItem("userData",res.d)
        // dispatch(handleSuccess({ message: res.data.message }));
        return res;
      }

    } catch (error) {
      dispatch(handleError(error));
    }
  }
}


export function getUserListingProfileDetails() {
  return async function (dispatch) {
    try {
      // dispatch({ type: "LOADING_STARTED" });
      let userData = localStorage.getItem("userData");

      let userDataParse = JSON.parse(userData);
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/user/${userDataParse.id}`,
        headers: {
          authorization: localStorage.getItem("user"),
        }
      });
      // dispatch({ type: "LOADING_COMPLETED" });
      if (res.data.isFound === true) {
        // localStorage.setItem("userData",JSON.stringify(res.data.data.data) )
        dispatch({ type: "USER_PROFILE_INFO", payload: { data: res.data.data.data } })
        // dispatch(handleError({ message: res.data.message }));
      } else {
        // localStorage.setItem("userData",res.d)
        // dispatch(handleSuccess({ message: res.data.message }));
        return res;
      }
      return res;

    } catch (error) {
      dispatch(handleError(error));
    }
  }
}

export function sendNotification() {
  return async function (dispatch) {
    try {
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/address/sendNoti`,

      });

    } catch (error) {
      dispatch(handleError(error));
    }
  }
}


export function getPlayerListing(values) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      let userData = localStorage.getItem("userData");
      let userDataParse = JSON.parse(userData);
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/user/details`,
        data: { user_id: userDataParse.id, limit: values.limit, offset: values.offset, role: values.role, userRole: values.userRole },
        headers: {
          Authorization: localStorage.getItem("user"),
        },
      });
      dispatch({ type: "LOADING_COMPLETED" });

      dispatch({ type: "PLAYER_DETAILS", payload: { data: res.data.data.data } });
      dispatch({ type: "ERROR", payload: { errorMsg: "", errorData: {} } });

    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(error));

    }
  };
}
export function getAssistantListing(values) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      let userData = localStorage.getItem("userData");
      let userDataParse = JSON.parse(userData);
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/user/details`,
        data: { user_id: userDataParse.id, limit: values.limit, offset: values.offset, role: values.role, userRole: values.userRole },
        headers: {
          Authorization: localStorage.getItem("user"),
        },
      });
      dispatch({ type: "LOADING_COMPLETED" });

      dispatch({ type: "ASSISTANT_DETAILS", payload: { data: res.data.data.data } });
      dispatch({ type: "ERROR", payload: { errorMsg: "", errorData: {} } });

    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(error));

    }
  };
}

export function getNotifications(values) {
  return async function (dispatch) {
    try {
      let userData = localStorage.getItem("userData");
      let userDataParse = JSON.parse(userData);
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/zoom/getUserNotifications`,
        data: { user: userDataParse.id, page: values.page },
        headers: {
          Authorization: localStorage.getItem("user"),
        },
      });
      dispatch({ type: "LOADING_COMPLETED" });

      dispatch({ type: "USER_NOTIFICATIONS", payload: { data: res.data.data.data, dataFound: res.data.isFound, count: res.data.count.data[0].count } });
      dispatch({ type: "ERROR", payload: { errorMsg: "", errorData: {} } });

    } catch (error) {

      dispatch(handleError(error));

    }
  };
}

export function addUserDocument(values) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/user/addUserDocument`,
        data: values,
        headers: {
          authorization: localStorage.getItem("user"),
        }
      });
      dispatch(handleSuccess({ message: res.data.message }));
      dispatch({ type: "LOADING_COMPLETED" });
      return res.data;

    } catch (error) {
      dispatch(handleError(error));
      dispatch({ type: "LOADING_FAILURE" });
    }
  };
}

export function getUserDocument() {
  return async function (dispatch) {
    try {
      let userData = localStorage.getItem("userData");
      let userDataParse = JSON.parse(userData);
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/user/getUserDocument/${userDataParse.id}`,
        headers: {
          authorization: localStorage.getItem("user"),
        }
      });
      if (res.data.data.isFound) {
        dispatch({ type: "USER_DOCUMENTS", payload: { data: res.data.data.data } });
      } else {
        dispatch(dispatch({
          type: "CLEAR_USER_DOCUMENTS",
        }))
      }
      dispatch({ type: "LOADING_COMPLETED" });
      return res.data;

    } catch (error) {
      dispatch(handleError(error));
      dispatch({ type: "LOADING_FAILURE" });
    }
  }
}

export function deleteUserDocument(id) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "delete",
        url: `${URL}/api/v1/user/deleteuserDocument/${id}`,
        headers: {
          authorization: localStorage.getItem("user"),
        }
      });
      dispatch(handleSuccess({ message: res.data.message }));
      dispatch({ type: "LOADING_COMPLETED" });
      return res.data;

    } catch (error) {
      dispatch(handleError(error));
      dispatch({ type: "LOADING_FAILURE" });
    }
  }
}

export function getCountry() {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/user/getCountry`,
        headers: {
          authorization: localStorage.getItem("user"),
        }
      });
      dispatch({ type: "COUNTRY", payload: { data: res.data.data.data } });
      dispatch({ type: "LOADING_COMPLETED" });
      return res.data;

    } catch (error) {
      dispatch(handleError(error));
      dispatch({ type: "LOADING_FAILURE" });
    }
  }
}

export function getState(id) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/user/getState/${id}`,
        headers: {
          authorization: localStorage.getItem("user"),
        }
      });
      dispatch({ type: "STATE", payload: { data: res.data.data.data } });
      dispatch({ type: "LOADING_COMPLETED" });
      return res.data;

    } catch (error) {
      dispatch(handleError(error));
      dispatch({ type: "LOADING_FAILURE" });
    }
  }
}

export function getCity(id) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/user/getCity/${id}`,
        headers: {
          authorization: localStorage.getItem("user"),
        }
      });
      dispatch({ type: "CITY", payload: { data: res.data.data.data } });
      dispatch({ type: "LOADING_COMPLETED" });
      return res.data;

    } catch (error) {
      dispatch(handleError(error));
      dispatch({ type: "LOADING_FAILURE" });
    }
  }
}



