import React, { useState } from "react";
import { close } from "ionicons/icons";
import {
  IonIcon,
  IonInput,
  IonButton,
  IonLabel,
  IonModal,
  IonSelect,
  IonSpinner,
  IonSelectOption,
} from "@ionic/react";
import "./privatePolicy.css";
import { getPageContent } from "../redux/actions/pageAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const TNC = (props) => {
    const [title, setTitle] = useState("");
  const [content, setContent] = useState(null);
  const getData = async () => {
    const result = await props.getPageContent("terms-and-conditions");
    if (result?.data?.data) {
      setTitle(result.data.data.title);
      setContent(result.data.data.content);
      // this.setState({
      //     content: result.data.data.content,
      //     title: result.data.data.title
      // })
    }
  };

  return (
    <IonModal
      isOpen={props.showPrivatePolicy}
      cssClass="private-policy-modal"
      swipeToClose={true}
      backdropDismiss={false}
      onDidDismiss={() => {
        //   props.ClearData();
        //   props.setShowModal(false);
      }}
      onWillPresent={() => {
        //   getOption();
        //   props.ClearData();
        getData()
      }}
    >
      <div className="adduser-popup">
        <div className="popup-header">
          <h5 dangerouslySetInnerHTML={{
            __html: title ? title : `Loading...`,
          }}></h5>
          <IonButton onClick={() => props.setPrivatePolicy()}>
            <IonIcon icon={close} />
          </IonButton>
        </div>
        <div
          // className="popup-body"
          className="private-policy-detail"
          dangerouslySetInnerHTML={{
            __html: content ? content : ``,
          }}
        >
          {/* <div className="private-policy-detail">
            <h2>Sportal LLC Privacy Policy</h2>
            <p>
              <i>Last Updated 21th September 2020 </i>
            </p>
            <div>
              <h3>Introductory Statement</h3>
              <p>
                Sportal Tennis’s products and services are provided by Sportal
                LLC. This Privacy Policy governs your access to and use of
                Sportal Tennis’s websites and applications. Please read our
                policies carefully. If they are not acceptable to you, you
                should discontinue using our services.
              </p>
            </div>
            <div>
              <h3>What Information Do We Collect? </h3>
              <p>
                We collect information when you register on our site. When
                registering or entering payment information on our site you may
                be asked to enter your name, email address, phone number, or
                credit card information. Coaches and departments, not their
                athletes, are the entities who will be providing payment
                information if they choose to register. This is to further
                protect the security of the athletes and to prevent any
                miscommunications that can occur when athletes are not
                financially independent. You may also visit our site without
                registering and entering any information at all.{" "}
              </p>
            </div>
            <div>
              <h3>What Do We Use Your Information For? </h3>
              <p>
                Any of the information we collect from you may be used to
                process your registration to Sportal Tennis. Your information
                will not be sold, exchanged, or transferred to other parties.
                The email address you provide may be used to send you
                information, respond to inquiries, or other requests or
                questions that you may have regarding our services. We will use
                information in both our and our customers’ legitimate interests.{" "}
              </p>
            </div>
            <div>
              <h3>How Secure is Your Personal Information? </h3>
              <p>
                Your security is very important to us. We use a variety of
                measures to maintain the safety of your personal information
                when you interact with our services. We offer the use of a
                secure server, and all sensitive information that you provide is
                transmitted via SSL technology and then encrypted into our
                payment gateway provider’s database. Furthermore, this
                information will only be accessible to those authorized to
                interact with such systems and who are required to keep the
                information confidential. User transaction information is
                secured with your User ID and password; your password is never
                shared. Our website may, from time to time, contain links to
                external sites. We are not responsible for the privacy policies
                or the content of such sites.
              </p>
            </div>
            <div>
              <h3>Cookies </h3>
              <p>We do not use any cookies.</p>
            </div>
            <div>
              <h3>Changes to Our Privacy Policy </h3>
              <p>
                Any changes we make to our Privacy Policy in the future will be
                updated on our website www.sportaltennis.com and you will also
                be notified by email.{" "}
              </p>
            </div>
            <div>
              <h3>Your Consent</h3>
              <p>By using our services, you consent to our Privacy Policy. </p>
            </div>
            <div>
              <h3>Contact Us </h3>
              <p>
                For information or questions regarding our Privacy Policy please
                contact us.{" "}
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </IonModal>
  );
}
 
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getPageContent }, dispatch);
  };
  
  export default connect(null, mapDispatchToProps)(TNC);