import axios from "axios";
import { handleError, handleSuccess } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export function getQuestionsForCoach(values) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        headers: {
          authorization: localStorage.getItem("user"),
        },
        url: `${URL}/api/v1/projectReportingSystem/coach?type=${values.type}&category=${values.category}`,
      });
      dispatch({
        type: "Questionnaire",
        payload: { data: res.data.data },
      });
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch(handleError(error));
      dispatch({ type: "LOADING_COMPLETED" });
    }
  };
}

export function addQuestionsForCoach(values) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/projectReportingSystem/coach`,
        data: { questionsArray: values },
        headers: {
          authorization: localStorage.getItem("user"),
        },
      });
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch(handleError(error));
      dispatch({ type: "LOADING_COMPLETED" });
    }
  };
}

export function checkQuestionSubmission(todaysDate, endDate, category) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/projectReportingSystem/check?todaysDate=${todaysDate}&endDate=${endDate}&category=${category}&userId=${
          JSON.parse(localStorage.getItem("userData")).id
        }`,
        headers: {
          authorization: localStorage.getItem("user"),
        },
      });

      dispatch({
        type: "Questionnaire_DaysLeft",
        payload: { results: res.data.results, data: res.data.data },
      });
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch(handleError(error));
      dispatch({ type: "LOADING_COMPLETED" });
    }
  };
}

export function getPlayerQuestions(todaysDate, endDate, category) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/projectReportingSystem/player?todaysDate=${todaysDate}&endDate=${endDate}&category=${category}&userId=${
          JSON.parse(localStorage.getItem("userData")).id
        }`,
        headers: {
          authorization: localStorage.getItem("user"),
        },
      });

      dispatch({
        type: "PLAYERS_QUESTIONS",
        payload: { data: res?.data?.data?.data },
        playerAnsStatus: res?.data?.data?.isFound,
      });
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch(handleError(error));
      dispatch({ type: "LOADING_COMPLETED" });
    }
  };
}

export function addPlayerAnswers(values) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/projectReportingSystem/player`,
        data: { questionsArray: values },
      });

      dispatch({
        type: "PLAYERS_ANS_STATUS",
        payload: { data: res?.data?.data?.isRegistered },
      });
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch(handleError(error));
      dispatch({ type: "LOADING_COMPLETED" });
    }
  };
}
export function checkPlayerAnswerSubmission(todaysDate, endDate, category) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/projectReportingSystem/playerCheck?todaysDate=${todaysDate}&endDate=${endDate}&category=${category}&userId=${
          JSON.parse(localStorage.getItem("userData")).id
        }`,
      });
      dispatch({
        type: "PLAYERS_ANSWERS_AFTER_SUBMIT",
        payload: {
          results: res?.data?.data?.isFound,
          data: res.data?.data?.data,
        },
      });
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch({ type: "LOADING_COMPLETED" });
      dispatch(handleError(error));
    }
  };
}

export function clearReportingSystem() {
  return async function (dispatch) {
    dispatch({
      type: "Clear_Reporting_System",
    });
  };
}

export function checkPlayerReporting(todaysDate, type, player_id) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/projectReportingSystem/checkPlayersReport?todaysDate=${todaysDate}&type=${type}&player_id=${player_id}`,
      });

      dispatch({
        type: "CHECK_PLAYER_REPORTS",
        payload: { data: res?.data?.data?.data },
      });
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch(handleError(error));
      dispatch({ type: "LOADING_COMPLETED" });
    }
  };
}

export function getPlayerCurrentGoals(todaysDate, endDate) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/projectReportingSystem/currentGoals?todaysDate=${todaysDate}&endDate=${endDate}&userId=${
          JSON.parse(localStorage.getItem("userData")).id
        }`,
      });

      dispatch({
        type: "GET_PLAYERS_CURRENT_GOALS",
        payload: { data: res?.data?.data?.data },
      });
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch(handleError(error));
      dispatch({ type: "LOADING_COMPLETED" });
    }
  };
}

export function getAllWeeklyUsers(todaysDate, type, limitOffset) {

  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/projectReportingSystem/getAllUsers?todaysDate=${todaysDate}&type=${type}&userId=${
          JSON.parse(localStorage.getItem("userData")).id
        }&limit=${limitOffset.limit}&offset=${limitOffset.offset}`,
      });
      dispatch({
        type: "GET_ALL_WEEKLY_REPORT",
        payload: { data: res?.data?.data?.data },
      });
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(error));
    }
  };
}

export function getAllMatchUsers(todaysDate, type, limitOffset) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/projectReportingSystem/getAllUsers?todaysDate=${todaysDate}&type=${type}&userId=${
          JSON.parse(localStorage.getItem("userData")).id
        }&limit=${limitOffset.limit}&offset=${limitOffset.offset}`,
      });
      
      dispatch({
        type: "GET_ALL_MONTHLY_REPORT",
        payload: { data: res?.data?.data?.data },
      });
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(error));
    }
  };
}

export function cleanUpData(todaysDate, category, type) {
  return async function (dispatch) {
    dispatch({
      type: "CHECK_PLAYER_REPORTS",
      payload: { data: [] },
    });
  };
}

export function getQuestionById(id) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/projectReportingSystem/getquestionbyid/${id}`,
      });
      dispatch({
        type: "SET_NOTIFICATION",
        payload: { data: res.data.data.data},
      });
      dispatch({ type: "LOADING_COMPLETED" });
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      dispatch(handleError(error));
    }
  };
}
