import { act } from "react-dom/test-utils";

export const initialState = {
  loading: null,
  errorData: {},
  errorMsg: "",
  successMsg: "",
  checkUser: false,
  darkMode:JSON.parse(localStorage.getItem("darkMode"))  };

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ERROR":
      return {
        ...state,
        errorData: action.payload?.errorMsg?.errorData,
        errorMsg:
          action.payload?.errorMsg?.error ||
          action.payload?.errorMsg?.errorData ||
          action.payload?.errorMsg || action.payload?.error
      };
    case "SUCCESS":
      return {
        ...state,
        // errorData: action.payload?.error?.errorData,
        successMsg: action.payload?.successMsg,
      };
    case "CLEARDATA":
      return {
        ...state,
        loading: false,
        errorData: {},
        errorMsg: "",
        successMsg: "",
      };
    case "LOADING_STARTED":
      return {
        ...state,
        loading: true,
      };
    case "LOADING_COMPLETED":
      return {
        ...state,
        loading: false,
      };
    case "LOADING_FAILURE":
      return {
        ...state,
        loading: false,
      };

    case "DARK MODE":
      return {
        ...state,
        darkMode: action.payload,
      };

    default:
      return state;
  }
};

export default commonReducer;
