import React, { useEffect, useState,useRef } from "react";
import {
  IonInput,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonImg,
  IonModal,
  IonButton,
  IonSpinner,
  IonToast
} from "@ionic/react";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import PubNub from "pubnub";
import moment from "moment";
import { addOutline, flag, searchOutline } from "ionicons/icons";
import {getGroupmembers, setGroupArray} from "../../../redux/actions/chatAction"
import Client from "../../../images/client-dummy.png";
import ContactModal from "../contactModal";
import { close } from "ionicons/icons";
import "../../../pages/Page.css";

const ChatList = (props) => {
  const [chatlist, setChatList] = useState("");
  const [channelArray, setChannelArray] = useState([]);
  const [isChat, setIsChat] = useState(null);
  const [username, setUser] = useState(props.authData?.email);
  const [showModal, setShowModal] = useState(false);
  const [channel, setCurrentChannel] = useState("");
  const[type,setType]= useState("")
  const [searchValue, setSearch]=useState("")
  const dispatch = useDispatch();
  let listener
  var cleartimeout;
  let chatArrayLength = 0;
  let groupLength = 0;
  let user
  let recursionIndex= 0
  const refContainer = useRef();
  const pubnub_config = {
    publishKey: "pub-c-bcbfc2d8-736e-4a83-9ef1-daf51ae2203e",
    subscribeKey: "sub-c-758c3636-4438-11eb-9ec5-221b84410db5",
    ssl: true,
    uuid: username ? username : props.authData?.email,
    authKey: username ? username : props.authData?.email,
  };

  const pubnub = new PubNub(pubnub_config);
  useEffect(() => {
    if (
      props.allChatRoom.length !== chatArrayLength ||
      props.allGroups.length !== groupLength
    ) {
      chatArrayLength = props.allChatRoom.length;
      groupLength = props.allGroups.length;
       user= props.authData.email
      setUser(props.authData.email);
      createCommonArray();
    }
    if(props.allChatRoom.length ===0 && props.allGroups.length === 0){
      setChatList("");
                // setChannelArray([]);
    }

    
  }, [props.allChatRoom, props.allGroups]);

  useEffect(()=>{
    
    if(props?.location?.state?.event){
      dispatch({ type: "CHANGE_CHAT_ROOM", payload: props?.location?.state?.event });
    }
    return ()=>{
      pubnub.unsubscribeAll();
      pubnub.removeListener(listener);
    }
  },[])

  useEffect(()=>{
    setCurrentChannel(props.currentChatRoom?.channel)
  },[props.currentChatRoom])

  const setChannel = async (item) => {
    dispatch({ type: "CHANGE_CHAT_ROOM", payload: null });
    dispatch({ type: "CHANGE_CHAT_ROOM", payload: item });
    if(item.isBold === true){
      let chatArrydummy= JSON.parse(chatlist)
        let index=chatArrydummy.findIndex(elem => (elem.channel === item.channel));
        if(index > -1){
          let chatArr= chatArrydummy[index]
          chatArr.isBold= false
          chatArrydummy[index]= chatArr
          setChatList(JSON.stringify(chatArrydummy))
        }
    }
    
    if(item.type === "group"){
      await props.getGroupmembers(item.groupId)
    }
    
  };

  const getInitials = (name) => {
    if (name) {
      let myStr = name;
      let matches = myStr.match(/\b(\w)/g);
      return matches.join("");
    }
  };

  const createCommonArray = () => {
    let groupArray = props.allGroups;
    let chatArray = props.allChatRoom;
    let finalArray = [];
    let channelArr = [];
    for (let item of chatArray) {
      channelArr.push(item.channel);
      let lastSeen=username === item.sender_email
      ? item.sender_last_seen
      : item.receiver_last_seen
      let time=lastSeen ? new Date(moment.utc(lastSeen).local().format()) : new Date()
      let m =time;
      let obj = {
        channel: item.channel,
        receiver:
        user === item.sender_email
            ? item.receiver_name
            : item.sender_name,
            email:user === item.sender_email
            ? item.receiver_email
            : item.sender_email,
        image: {
          path:
          user === item.sender_email
              ? item.receiver_image
              : item.sender_image,
          type: "private",
        },
        type: "private",
        message: null,
        timetoken: null,
        isBold:false,
        last_seen: m
      };
      finalArray.push(obj);
    }
    for (let item of groupArray) {
      channelArr.push(item.channel);
      let serverTime=item.last_seen
      let time= serverTime ? new Date(moment.utc(serverTime).local().format()) : new Date()
      let m =time;
      let obj = {
        channel: item.channel,
        receiver: item.group_name,
        image: { path: getInitials(item.group_name), type: "group" },
        type: "group",
        message: null,
        timetoken: null,
        groupId: item.group_id,
        isBold:false,
        last_seen:m
      };
      finalArray.push(obj);
    }
    // setChatList(finalArray)
    getChannels(channelArr, finalArray);
  };

  const closeModal = () => {
    setShowModal(false);
    props.getAll()
  };

  const isPast=(time,item)=>{
    let unixTimeStamp = time / 10000000;
    let message = new Date(Math.ceil(unixTimeStamp * 1000));
    let b=moment.duration(moment(message).diff(moment(item.last_seen)));
    var hours = parseInt(b.asSeconds());
    // let diff= moment(item.last_seen).diff(time,"days")
    if(hours>0){
      return true
    }else{
      return false
    }
  }

  const getChannels = async (channelList, finalArray) => {
      if (channelList.length > 0) {
        const pubnub = new PubNub(pubnub_config);
       await pubnub.subscribe({ channels: channelList },function(status){
       });
        await pubnub.fetchMessages(
          {
            channels: channelList,
            count: 1, // default/max is 25
          },
          async function (status, response) {
            if (status.error === false) {
              let msg = response.channels;
              // setChannelMsg(response.channels);
              for (let item of finalArray) {
                await Object.entries(msg).map(([key, value]) => {
                  if (item.channel === key) {
                    item.message = value[0].message.file
                      ? value[0].message.file.name
                      : value[0].message.type === "msg"
                      ? value[0].message.text
                      : value[0].message.type === "gif"
                      ? "GIF"
                      : null;
                    item.timetoken = value[0].timetoken;
                    item.isBold= value[0].message.uuid=== username ? false: isPast(value[0].timetoken, item)
                  }
                });
  
                await finalArray.sort(
                  (a, b) => b.timetoken === null || b.timetoken=== null ? (a.timetoken === null) - (b.timetoken === null) : parseInt(b.timetoken) - parseInt(a.timetoken)
                );
                setChatList(JSON.stringify(finalArray));
                setChannelArray(finalArray);
              }
            }
            // else{
            //     if(recursionIndex < 4){
            //       recursionIndex= recursionIndex+1
            //       getChannels(channelList,finalArray)
            //     }

            // }
          }
        );
        listener= {
          status: function (statusEvent) {
            if (statusEvent.category === "PNConnectedCategory") {
            }
          },
          message: async function (msg) {
            if(msg.channel !== channel){
              // let newArr= JSON.parse(dummyArray)
              
              for(let item of finalArray){
                if(item.channel === msg.channel){
                  item.message = msg.message.file
                      ? msg.message.file.name
                      : msg.message.type === "msg"
                      ? msg.message.text
                      : msg.message.type === "gif"
                      ? "GIF"
                      : null
                item.timetoken= msg.timetoken
                item.isBold= msg.message.uuid === username ? false : true
                }
                
              }
              await finalArray.sort(
                (a, b) => b.timetoken === null || b.timetoken=== null ? (a.timetoken === null) - (b.timetoken === null) : parseInt(b.timetoken) - parseInt(a.timetoken)
              );
              setChatList(JSON.stringify(finalArray))
            }
          },
          file: async function (msg) {
            if(msg.channel !== channel){
              // let newArr= JSON.parse(dummyArray)
              
              for(let item of finalArray){
                if(item.channel === msg.channel){
                  item.message = msg.file.name
                item.timetoken= msg.timetoken
                item.isBold= msg.uuid === username ? false : true
                }
                
              }
              await finalArray.sort(
                (a, b) => b.timetoken === null || b.timetoken=== null ? (a.timetoken === null) - (b.timetoken === null) : parseInt(b.timetoken) - parseInt(a.timetoken)
              );
              setChatList(JSON.stringify(finalArray))
            }
          }
        }

        pubnub.addListener(listener);
      }
    
    
  };

  const convertTime = (timestamp) => {
    let unixTimeStamp = timestamp / 10000000;
    let message = new Date(Math.ceil(unixTimeStamp * 1000));
    let finalDate;
    let today = new Date();
    let diff = moment(today, "DD.MM.YYYY")
      .startOf("day")
      .diff(moment(message, "DD.MM.YYYY").startOf("day"), "days");
    if (diff === 0) {
      finalDate = moment(message).format("hh:mm A");
    } else if (diff <= 7) {
      finalDate = moment(message).format("dddd");
    } else {
      finalDate = moment(message).format("DD/MM/YYYY");
    }
    return finalDate;
  };

  const search = (search, keys = ["receiver"]) => {
    search.preventDefault()
    if (cleartimeout) {
      
      clearTimeout(cleartimeout);
      cleartimeout = setTimeout(async () => {
        
        if (search.detail?.value?.trim() === "") {
          setSearch(search.detail.value)
          let finalArray = await channelArray.filter(function (wine) {
            return keys.some((key) => String(wine["type"]).toLowerCase().includes(type)
            );
          });
          setChatList(JSON.stringify(finalArray));
        } else {
          setSearch(search.detail.value)
          var lowSearch = search.detail.value.toLowerCase();
          let finalArray = await channelArray.filter(function (wine) {
            return keys.some((key) =>
              String(wine[key]).toLowerCase().includes(lowSearch) && String(wine["type"]).toLowerCase().includes(type)
            );
          });
          setChatList(JSON.stringify(finalArray));
        }
      }, 500);
    } else {
      cleartimeout = setTimeout(async () => {
        
        if (search.detail?.value?.trim() === "") {
          setSearch(search.detail.value)
          let finalArray = await channelArray.filter(function (wine) {
            return keys.some((key) => String(wine["type"]).toLowerCase().includes(type)
            );
          });
          setChatList(JSON.stringify(finalArray));
        } else {
          setSearch(search.detail.value)
          var lowSearch = search.detail.value.toLowerCase();
          let finalArray = await channelArray.filter(function (wine) {
            return keys.some((key) =>
              String(wine[key]).toLowerCase().includes(lowSearch) && String(wine["type"]).toLowerCase().includes(type)
            );
          });
          setChatList(JSON.stringify(finalArray));
        }
      }, 500);
    }
  };

  const openContact = (e) => {
    e.preventDefault();
    document.getElementById("Coaches").click();
  };

  const filterMessage= async(search, keys = ["type"])=>{
    setSearch("")
    var lowSearch = search.detail.value.toLowerCase();
    setType(lowSearch)
          let finalArray = await channelArray.filter(function (wine) {
            return keys.some((key) =>
              String(wine[key]).toLowerCase().includes(lowSearch)
            );
          });
          setChatList(JSON.stringify(finalArray));
  }

  return (
    
    <div className="container">
      {/* ChatList
        <div><IonInput onIonChange={(e)=>search(e)} placeholder="Search"></IonInput></div>
        {chatlist.length > 0 ? <div>
          {chatlist.map((item,key)=>
          <div onClick={(e)=>{setChannel(item)}}>
            {props.authData?.email === item.sender_email ? <div>{item.receiver_name}</div>: <div>{item.sender_name}</div>}
           {
             Object.entries(channelMsg).map(([key, value]) => {
             return <div> {key === item.channel ? <div>{channelMsg[key][0].message.text} {convertTime(channelMsg[key][0].timetoken)}</div> : null}  </div>
          })
           } */}
      {/* <div> */}
      {/* <div className="container">
         ChatList
         {chatlist.length > 0 ? <div>
           {chatlist.map((item,key)=>
           <div onClick={(e)=>{setChannel(item)}}>
             {props.authData?.email === item.sender_email ? <div>{item.receiver_name}</div>: <div>{item.sender_name}</div>}
           </div>
         )}
         </div>: <div>No chats found</div>} 
     </div> */}
      
      <div className="sportTalks-left">
        <div className="sportTalks-header">
          <div class="sportTalks-heading">
            {/* <h2>Sportalk</h2> */}
          </div>
          
          <div className="newChat-btn">

          <a
              onClick={(e) => {
                e.preventDefault();
                setIsChat(true);
                setShowModal(true);
                props.setGroupArray([])
              }}
            >
              {/* <IonIcon icon={addOutline} />  */}
              New Group
            </a>

            <a
              onClick={(e) => {
                e.preventDefault();
                setIsChat(false);
                setShowModal(true);
                props.setGroupArray([])
              }}
            >
              {/* <IonIcon icon={addOutline} />  */}
              New Chat
            </a>
          </div>
        </div>

        <div className="sportTalks-filter">
          <div className="sportTalks-search">
            <IonIcon icon={searchOutline} />
            <IonInput
              type="text"
              onIonChange={(e) => search(e)}
              value={searchValue}
              placeholder="Search..."
            ></IonInput>
          </div>
          <div className="sportTalks-select ">
            <IonSelect
              placeholder="All Messages"
              interface="popover"
              onIonChange={filterMessage}
              interfaceOptions={{
                cssClass: 'custom-interface-popover'
              }}
            >
              <IonSelectOption value="">All Messages</IonSelectOption>
              <IonSelectOption value="group">Group Messages</IonSelectOption>
              <IonSelectOption value="private" >
                Individual Messages
              </IonSelectOption>
            </IonSelect>
          </div>
        </div>
        
        <div className="sportTalks-users">
        {(() => {
                  if (props.loading) {
                    return (
                      <IonSpinner name="crescent" className="spinner-loaderss" />
                    );
                  }
                })()}
          <div className="sportTalks-list">
            {chatlist &&
              JSON.parse(chatlist).map(function (item, key) {
                
                return (
                  <a
                  ref={refContainer}
                    className="sportTalks-list-item"
                    key={key}
                    onClick={(e) => {
                      setChannel(item);
                    }}
                  >
                    <div className="sportTalks-list-inner">
                      <div className="sportTalks-list-left">
                        <div class= {item.isBold === true ? "f-bold sportTalks-userimg" : "sportTalks-userimg"}>
                          {item.image.type === "private" ? (
                            <IonImg
                              src={item.image.path ? item.image.path : Client}
                              alt=""
                            />
                          ) : (
                            <p>{item.image.path}</p>
                          )}
                        </div>
                        <div className="sportTalks-userdetail">
                          <h3 className={item.isBold === true ? "f-bold" : ""}>{item.receiver}</h3>
                          <p className={item.isBold === true ? "f-bold" : ""}>{item.message ? item.message : null}</p>
                        </div>
                      </div>

                      <div className="sportTalks-list-right">
                        <div className="sportTalks-time">
                          <p className={item.isBold === true ? "f-bold" : ""}>
                            {item.timetoken
                              ? convertTime(parseInt(item.timetoken))
                              : null}
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                );
              })}
          {!props.loading && chatlist.length === 0 ? <div className="sportTalks-list-item">No Recent Chats Found</div>:null}
          
          </div>
        </div>
      </div>
      <IonModal
        isOpen={showModal}
        cssClass="contact-page-modal"
        onWillPresent={(e) => openContact(e)}
      >
            <div className="modal-header">
              <div className="modal-closer">
                <IonButton onClick={() => setShowModal(false)}> <IonIcon icon={close} /></IonButton>
              </div>
            </div>
            <ContactModal isClose={closeModal} isChat={isChat} isClick={false} />
      </IonModal>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    errorMsg: state.commonReducer.errorMsg,
    errorData: state.commonReducer.errorData,
    successMsg: state.commonReducer.successMsg,
    loading: state.commonReducer.loading,
    authData: state.authReducer.authData,
    allChatRoom: state.chatReducer.allChatRoom,
    allGroups: state.chatReducer.allGroups,
    allGroupMembers:state.chatReducer.allGroupMembers,
    currentChatRoom: state.chatReducer.currentChatRoom,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({getGroupmembers, setGroupArray}, dispatch);
};

export default React.memo(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ChatList))
);
