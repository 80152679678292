import React, { useState } from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
import {
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonList,
  IonItem,
  IonCheckbox,
  IonSpinner,
} from "@ionic/react";
import { useDispatch } from "react-redux";
// import {
//   eyeOutline,
//   notificationsOutline,
//   trashOutline,
//   eyeOffOutline,
//   close,
// } from "ionicons/icons";
import QuestionWhite from "../../images/question-white.png";
import QuestionBlue from "../../images/question-blue.png";
import {
  getQuestionsForCoach,
  addQuestionsForCoach,
  checkQuestionSubmission,
  clearReportingSystem,
} from "../../redux/actions/prsAction";
import { connect } from "react-redux";

const WeeklyReport = (props) => {
  const [activeId, setActiveId] = useState(1);
  const [selected, setSelected] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  // checkedItems: new Map()
  const dispatch = useDispatch();
  async function getCurrentDate(separator = "-") {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${year}${separator}${
      month < 10 ? `0${month}` : `${month}`
    }${separator}${date < 10 ? `0${date}` : `${date}`}`;
  }
  async function getSevenDaysBefore() {
    let date = await new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    let sevenDaysBefore = await date.toISOString().split("T")[0];
    return sevenDaysBefore;
  }

  React.useEffect(() => {
    componentDidMount();
  }, []);
  async function componentDidMount() {
    let todaysDate = await getCurrentDate();
    let sevenDaysBefore = await getSevenDaysBefore();
    document.getElementById("Wellness").click();
    await dispatch(
      await checkQuestionSubmission(todaysDate, sevenDaysBefore, activeId)
      // await checkQuestionSubmission('2020-09-20', '2020-09-13', activeId)
    );
    await dispatch(
      await getQuestionsForCoach({ type: "weekly_checkin", category: 1 })
    );
  }

  const onSelectCategory = async (e, id, value) => {
    e.preventDefault();
    await dispatch(clearReportingSystem());
    setErrorMessage(false);
    setActiveId(value);
    let i, tablinks;
    tablinks = document.getElementsByClassName("nav-link");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("active", "");
    }
    document.getElementById(id).classList.add("active");

    let todaysDate = await getCurrentDate();
    let sevenDaysBefore = await getSevenDaysBefore();
    await dispatch(
      await checkQuestionSubmission(todaysDate, sevenDaysBefore, value)
    );
    await dispatch(
      await getQuestionsForCoach({ type: "weekly_checkin", category: value })
    );
  };

  const OnClickSubmit = async () => {
    if (selected.length < 1) {
      setErrorMessage(true);
    } else {

      let todaysDate = await getCurrentDate();
      let sevenDaysBefore = await getSevenDaysBefore();
      setErrorMessage(false);
      await dispatch(await addQuestionsForCoach(selected));
      await dispatch(
        await checkQuestionSubmission(todaysDate, sevenDaysBefore, activeId)
      );
      setSelected([]);
    }
  };
  const handleChange = async (event, question, category) => {
    const target = event.target;
    var value = target.value;

    if (target.checked) {
      setSelected((oldArray) => [
        ...oldArray,
        [
          value,
          question,
          category,
          JSON.parse(localStorage.getItem("userData")).id,
        ],
      ]);
    } else {
      let filteredArray = await selected.filter((val) => val[0] !== value);
      await setSelected(filteredArray);
    }
  };

  return (
    <div className="reporting-mid-tab">
      <IonGrid>
        <IonRow>
          <IonCol size="12" size-md="3">
            <div className="reporting-mid-left">
              <a
                id="Wellness"
                className="nav-link"
                onClick={(e) => onSelectCategory(e, "Wellness", 1)}
              >
                <IonImg className="question-icona" src={QuestionBlue} />
                <IonImg className="question-iconb" src={QuestionWhite} />
                Wellness
              </a>
              <a
                id="Tennis"
                className="nav-link"
                onClick={(e) => onSelectCategory(e, "Tennis", 2)}
              >
                <IonImg className="question-icona" src={QuestionBlue} />
                <IonImg className="question-iconb" src={QuestionWhite} />
                Tennis
              </a>
              <a
                id="Goals"
                className="nav-link"
                onClick={(e) => onSelectCategory(e, "Goals", 3)}
              >
                <IonImg className="question-icona" src={QuestionBlue} />
                <IonImg className="question-iconb" src={QuestionWhite} />
                Goals
              </a>
            </div>
          </IonCol>
          <IonCol size="12" size-md="9">
            <div className="reporting-mid-right">
              <div className="tab-content">
                <div className="tab-pane active" id="wellness">
                  <div className="reporting-tabbox">
                    <div className="reporting-tabbox-head">
                      {/* <h3>Goals</h3> */}
                      <h3>
                        {activeId === 1
                          ? "Wellness"
                          : activeId === 2
                          ? "Tennis"
                          : "Goals"}
                      </h3>
                      <p>(Pick your preferred questions)</p>
                    </div>
                    <div className="reporting-tabbox-mid">
                      <div className="add-questionairre">
                        <IonList lines="none">
                          <div>
                            {props.isQuestionnaire === false ? (
                              props?.Questionnaire?.data.length > 0 ? (
                                props?.Questionnaire?.data?.map(
                                  (data, index) => {
                                    return (
                                      <div key={index}>
                                        <IonItem key={index}>
                                          <IonCheckbox
                                            color="tertiary"
                                            slot="start"
                                            value={data.id}
                                            onIonChange={(e) => {
                                              handleChange(
                                                e,
                                                data.question,
                                                data.category
                                              );
                                            }}
                                          />
                                          <IonLabel>{data.question}</IonLabel>
                                        </IonItem>
                                      </div>
                                    );
                                  }
                                )
                              ) :  (
                                <div className="questionnaire-detal">
                                  <div className="questionnaire-detal-heading">
                                    {props?.loading === true ? (
                                       <div className="loading-pera">
                                       <p>Loading...</p>
                                    </div>
                                    ) : (
                                      <h5>No Question Added.</h5>
                                    )}
                                  </div>
                                </div>
                              )
                            ) : props.isQuestionnaire === true ? (
                              <div className="questionnaire-detal">
                                <div className="questionnaire-detal-heading">
                                  <h5>
                                  You have added this week's questions!
                                  </h5>
                                </div>
                                <div className="questionnaire-detal-mid">
                                  {props?.coachQuestions?.data?.map(
                                    (data, index) => {
                                      return (
                                        <p>
                                          {index + 1}: {data.question}
                                        </p>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className="loading-pera">
                                <p>Loading...</p>
                              </div>
                            )}

                            {errorMessage === true ? (
                              <div className="errorMessage">
                              <p >Please select at least one question</p>
                              </div>
                            ) : null}

                            {props.isQuestionnaire === false &&
                            props?.Questionnaire?.data?.length > 0 ? (
                              <div className="questionairre-submit-btn">
                                <a onClick={OnClickSubmit}>
                                  Submit{" "}
                                  {(() => {
                                    if (props.loading) {
                                      return (
                                        <IonSpinner
                                          name="crescent"
                                          className="spinner-loader"
                                        />
                                      );
                                    }
                                  })()}
                                </a>
                              </div>
                            ) : null}
                          </div>
                        </IonList>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    Questionnaire: state.prsReducer.Questionnaire,
    isQuestionnaire: state.prsReducer.isQuestionnaire,
    coachQuestions: state.prsReducer.coachQuestions,
    loading: state.commonReducer.loading,
  };
};

export default withRouter(connect(mapStateToProps)(WeeklyReport));
