import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonImg,
  IonTextarea,
  IonSpinner,
} from "@ionic/react";
import {
  getPlayerQuestions,
  clearReportingSystem,
  addPlayerAnswers,
  checkPlayerAnswerSubmission,
} from "../../redux/actions/prsAction";
import QuestionWhite from "../../images/question-white.png";
import QuestionBlue from "../../images/question-blue.png";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import moment from "moment";
import "./playerReportingSystem.css";

const WeeklyReport = (props) => {
  const dispatch = useDispatch();
  const [activeId, setActiveId] = useState(1);
  const [formValue, setFormValue] = useState([]);
  const [dates, setDates] = useState({});
  const [errorMessage, setErrorMessage] = useState(false);
  const [validationError, setValidationError] = useState('');

  async function getCurrentDate(separator = "-") {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`
      }${separator}${date < 10 ? `0${date}` : `${date}`}`;
  }
  async function getSevenDaysBefore() {
    let date = await new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    let sevenDaysBefore = await date.toISOString().split("T")[0];
    return sevenDaysBefore;
  }

  useEffect(() => {
    document.getElementById("Wellness").click();
    componentDidMount();
  }, []);
  useEffect(() => {
    return () => {
      dispatch(clearReportingSystem());
    };
  }, []);

  useEffect(() => {
    setFormValue(props.playersQuestions);
  }, [props.playersQuestions]);

  async function componentDidMount() {
    let todaysDate = await getCurrentDate();
    let sevenDaysBefore = await getSevenDaysBefore();

    setDates({ today: todaysDate, sevendays: sevenDaysBefore });

    await dispatch(
      await checkPlayerAnswerSubmission(todaysDate, sevenDaysBefore, activeId)
    );
  }
  const onSelectCategory = async (e, id, value) => {
    e.preventDefault();
    await dispatch(clearReportingSystem());
    setFormValue([]);
    setActiveId(value);
    let i, tablinks;
    tablinks = document.getElementsByClassName("nav-link");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("active", "");
    }
    if (document.getElementById(id) && document.getElementById(id).classList) {
      document.getElementById(id).classList.add("active");
    }

    let todaysDate = await getCurrentDate();
    let sevenDaysBefore = await getSevenDaysBefore();
    await dispatch(
      await checkPlayerAnswerSubmission(todaysDate, sevenDaysBefore, value)
    );
    await dispatch(
      await getPlayerQuestions(todaysDate, sevenDaysBefore, value)
    );
  };
  const handleChange = async (
    e,
    question_id,
    couch_question_id,
    index,
    question
  ) => {
    const player_id = JSON.parse(localStorage.getItem("userData")).id;
    let val = [...formValue];
    // val[index].answer = e.detail.value;
    // val[index].player_id = player_id;
    let questionIndex = null;
    let counter = 0;

    for (const i of val) {
      if (i.question_id === question_id) {
        questionIndex = counter;
      }
      counter++;
    }
    if (questionIndex !== null) {
      if(e.detail.value.length > 50){
        setValidationError('Max. answer length is 50 characters')
        return null
      }else{
        setValidationError('')
      }
      val[questionIndex] = {
        question_id: question_id,
        answer: e.detail.value,
        player_id,
        couch_question_id,
      };
    } else {
      val.push({
        question_id: question_id,
        answer: e.detail.value,
        player_id,
        couch_question_id,
      });
    }

    await setFormValue(val);
  };
  const onSubmit = async () => {
    if(validationError.length !==0){
      return null
    }
    let finalArray = [];

    const arr = await formValue
      .filter((form) => {
        if (form.answer !== undefined) {
          form.answer = form.answer.trim();
        }
        let elms = document.getElementById(form.question_id);
        elms.style.display = "none";
        var str = form.answer;
        return (
          form.answer === null ||
          form.answer === undefined ||
          form.answer === "" ||
          !str.replace(/\s/g, "").length === true
        );
      })
      .map((data, i) => {
        let elms = document.getElementById(data.question_id);
        elms.style.display = "none";
        return data;
      });
    if (arr.length > 0) {
      arr.map((data, i) => {
        if (!data.answer) {
          let elms = document.getElementById(data.question_id);
          elms.style.display = "block";
        }
      });
    } else {
      await setErrorMessage(false);
      await formValue.map(async (data, index) => {
        let elms = document.getElementById(data.question_id);
        elms.style.display = "none";
        await finalArray.push([
          data.answer,
          data.question_id,
          data.player_id,
          data.couch_question_id,
        ]);
      });
      let todaysDate = await getCurrentDate();
      let sevenDaysBefore = await getSevenDaysBefore();

      await dispatch(await addPlayerAnswers(finalArray));
      await dispatch(
        await checkPlayerAnswerSubmission(todaysDate, sevenDaysBefore, activeId)
      );
    }
  };

  return (
    <div className="reporting-mid-tab">
      <IonGrid>
        <IonRow>
          <IonCol size="12" size-md="3">
            <div className="reporting-mid-left">
              <a
                id="Wellness"
                className="nav-link"
                onClick={(e) => onSelectCategory(e, "Wellness", 1)}
              >
                <IonImg className="question-icona" src={QuestionBlue} />
                <IonImg className="question-iconb" src={QuestionWhite} />
                Wellness
              </a>
              <a
                id="Tennis"
                className="nav-link"
                onClick={(e) => onSelectCategory(e, "Tennis", 2)}
              >
                <IonImg className="question-icona" src={QuestionBlue} />
                <IonImg className="question-iconb" src={QuestionWhite} />
                Tennis
              </a>
              <a
                id="Goals"
                className="nav-link"
                onClick={(e) => onSelectCategory(e, "Goals", 3)}
              >
                <IonImg className="question-icona" src={QuestionBlue} />
                <IonImg className="question-iconb" src={QuestionWhite} />
                Goals
              </a>
            </div>
          </IonCol>
          <IonCol size="12" size-md="9">
            <div className="reporting-mid-right">
              <div className="tab-content">
                <div className="tab-pane active" id="wellness">
                  <div className="reporting-tabbox">
                    <div className="reporting-tabbox-head">
                      {/* <h3>
                        {props.authData?.fullname}'s Weekly Check-In:{" "}
                        {moment(dates.today).format("MMM")}{" "}
                        {moment(dates.today).format("DD")} -{" "}
                        {moment(dates.sevendays).format("MMM")}{" "}
                        {moment(dates.sevendays).format("DD")}{" "}
                      </h3> */}
                      <h3>
                        {props.authData?.fullname}'s Weekly Check-In:{" "}
                        {moment(dates.sevendays).format("MMM")}{" "}
                        {moment(dates.sevendays).format("DD")} -{" "}
                        {moment(dates.today).format("MMM")}{" "}
                        {moment(dates.today).format("DD")}{" "}
                      </h3>
                      <p>
                        (Reflect on your past week and set goals- Submit by
                        Sunday)
                      </p>
                    </div>
                    <div className="reporting-tabbox-mid">
                      {props?.isAnswerSubmitted === false ? (
                        props?.playersQuestions.length > 0 ? (
                          props?.playersQuestions?.map((data, index) => {
                            return (
                              <div className="reporting-tabbox-item">
                                <div
                                  className="reporting-tabbox-question"
                                  key={index}
                                >
                                  <div className="reporting-tabbox-question-icon">
                                    <span>Q</span>
                                  </div>
                                  <div className="reporting-tabbox-question-text">
                                    <p>{data?.question}</p>
                                  </div>
                                </div>
                                <div className="reporting-tabbox-ans">
                                  <div className="reporting-tabbox-ans-icon">
                                    <span>A</span>
                                  </div>
                                  <div className="reporting-tabbox-ansinput">
                                    <IonTextarea
                                      onIonChange={(event) => {
                                        handleChange(
                                          event,
                                          data.question_id,
                                          data.coach_questions_id,
                                          index,
                                          data?.question
                                        );
                                      }}
                                      placeholder="Type answer here...  12321123"
                                    ></IonTextarea>
                                    <span
                                      className="reporting-tabbox-ans-error"
                                      id={data.question_id}
                                      style={{ display: "none" }}
                                    >
                                      {" "}
                                      Please provide answer for the following
                                      question.
                                    </span>
                                  </div>
                                </div>
                                {
                                  validationError.length !==0 && <p className="errormsg">{validationError}</p>
                                }
                              </div>
                            );
                          })
                        ) : (
                            <div className="loading-para">
                              {props.loading === true ? (
                                <p style={{ color: "#404F8C" }}>Loading...</p>
                              ) : (
                                  <p style={{ color: "#404F8C" }}>
                                    No Question Added.
                                  </p>
                                )}
                            </div>
                          )
                      ) : (
                          props?.playersAnswersAfterSubmit?.map((data, index) => {
                            return (
                              <div className="reporting-tabbox-item">
                                <div
                                  className="reporting-tabbox-question"
                                  key={index}
                                >
                                  <div className="reporting-tabbox-question-icon">
                                    <span>Q</span>
                                  </div>
                                  <div className="reporting-tabbox-question-text">
                                    <p>{data?.question}</p>
                                  </div>
                                </div>
                                <div className="reporting-tabbox-ans">
                                  <div className="reporting-tabbox-ans-icon">
                                    <span>A</span>
                                  </div>
                                  <div className="reporting-tabbox-ansinput">
                                    <p>{data.answer}</p>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        )}
                      {errorMessage === true ? (
                        <p>Please provide answers for all the questions</p>
                      ) : null}
                      {props &&
                        props?.isAnswerSubmitted === false &&
                        props.playersQuestions &&
                        props.playersQuestions.length > 0 ? (
                          <div className="reporting-tabbox-btn">
                            <IonButton
                              type="button"
                              color="none"
                              onClick={onSubmit}
                            >
                              Submit
                            {(() => {
                                if (props.loading) {
                                  return (
                                    <IonSpinner
                                      name="crescent"
                                      style={{ color: "black" }}
                                      className="spinner-loader"
                                    />
                                  );
                                }
                              })()}
                            </IonButton>
                          </div>
                        ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    playersQuestions: state.prsReducer.playersQuestions,
    isAnswerSubmitted: state.prsReducer.isAnswerSubmitted,
    playersAnswersAfterSubmit: state.prsReducer.playersAnswersAfterSubmit,
    loading: state.commonReducer.loading,
    authData:state.authReducer.authData
  };
};

export default withRouter(connect(mapStateToProps)(WeeklyReport));
